import styled from 'styled-components';

export const Footer = styled.div`
   background: #383838;
   padding: 150px 0 50px;
   p, strong{
    color: ${({ theme }) => theme.colors.white};
    font-size: 14px;
   }
   .link{
    text-decoration-line: underline;
    color: #FFFFFF;
    font-weight: 700;
    cursor: pointer;
    &:hover{
      text-decoration-line: none;
    }
  }
  a:hover{
    color: #fff;
  }
`;

export const Footer2 = styled.div`
  background: #2F53E4;
  height: 51px;
`;

export const Whatsapp = styled.div`
   position: fixed;
    right: 50px;
    bottom: 40px;
    z-index: 6;
    width: 100%;
    .whatsapplink{
      position: absolute;
      right: 0px;
      bottom: 0px;
      z-index: 5;
      cursor: pointer;
      img{
        width: 60px;
      }
  }
  .box-whatsapp{
    box-shadow: -1px 2px 4px 1px #00000047;
      .header{
        background-color: #265c54;
        padding: 15px;
        color: #fff;
        border-radius: 11px 11px 0 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        button{
          cursor: pointer;
          font-size: 20px;
          font-weight: bold;
          background-color: #fff;
          color: #265c54;
          border-radius: 11px;
          width: 30px;
          height: 30px;
          box-sizing: unset !important;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .content{
        padding: 15px;
        form{
          display: flex;
          justify-content: center;
          flex-direction: column;
          p{
            margin-bottom: 0;
          }
          input{
            color: #343638;
            padding-left: 10px;
            font-size: 16px;
            width: 96%;
            border: 0;
            height: 45px;
            border-radius: 5px;
            margin-top: 5px;
            margin-bottom: 15px;
            box-sizing: unset !important;
            &::placeholder {
              color: #b6b6b7;
            }
          }
          button{
            display: flex;
            justify-content: center;
            margin: 15px auto;
            width: 100%;
            padding: 13px;
            color: #fff;
            background-color: #265c54;
            border-radius: 5px;
            border: 0;
            font-size: 19px;
            cursor: pointer;
          }

        }
      }
      position: absolute;
      right: 30px;
      bottom: 40px;
      width: 400px;
      border-radius: 3%;
      background-color: #ece5dd;
      box-shadow: -1px 2px 4px 1px #00000047;
    }

    @media (max-width: 700px) {
      right: 0;
      .box-whatsapp{
        width: 100%;
        right: 0;
      }
      .whatsapplink{
        right: 20px;
      }
    }
`;
