import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components';
import backgroundImage from '../../../assets/images/2-video/video.svg';

export const Section3 = styled.div`
  text-align: center;
  background-color: #F7F7F7;
  @media (max-width: 700px) {
    padding-bottom: 20px;
  }
  h3{
    margin: 65px 0 73px 0;
  }
  hr{
    border: 0px;
    height: 1px;
    background: #E7E7E7;
    }
`;

export const Plans = styled.div`
  text-align: left;
  padding: 35px 13px;
  background-color:  ${({ theme }) => theme.colors.white};
  border-radius: 20px;
  span{
    display: block;
  }
  .first-section{
    padding: 0 20px;
  }
  .second-section{
    padding: 30px 25px;
    border-radius: 30px;
    background: #F1F1F1;
    z-index: 4;
    position: relative;
    margin-bottom: -150px;
    margin-top: 20px;
  }
  a.linkPDF{
    text-decoration: underline;
    cursor: pointer;
    &:hover{
      text-decoration: none;
      color: ${({ theme }) => theme.colors.blue};
    }
  }
`;

export const Values = styled.div`
  text-align: center;
  background-color: #F7F7F7;
  margin-top: 120px;
`;

export const Payment = styled.div`
  text-align: center;
  background-color: #EAEAEA;
  margin-top: 250px;
  padding: 35px 45px;
  border-top-right-radius: 57px;
  border-bottom-right-radius: 57px;
  position: relative;
  .text-transform{
    text-transform: uppercase;
  }
  > img{
    position: absolute;
    left: 25px;
    top: -42px;
    z-index: 4;
  }
  &:after{
    content: "";
    height: 100%;
    width: 5000px;
    position: absolute;
    right: 664px;
    top: 0;
    background-color: #EAEAEA;
  }
`;

export const Button = styled.button`
  font-size: ${({ theme }) => theme.fonts[5]};
  border-radius: 12px;
  padding: 12.5px 70px;
  background-color: ${({ theme }) => theme.colors.orange};
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  border: 2px solid ${({ theme }) => theme.colors.orange};
  /* display: flex;
  align-items: center;
  justify-content: center; */
  &:hover{
    text-decoration: none;
    color: ${({ theme }) => theme.colors.orange};
    background-color: ${({ theme }) => theme.colors.white};
    transition: 0.3s;
  }
  &.outline{
    color: ${({ theme }) => theme.colors.orange};
    background: #383838 !important;
    border: 2px solid #FF9700;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover{
      color: ${({ theme }) => theme.colors.white}};
      transition: 0.4s;
      background: ${({ theme }) => theme.colors.orange}};
    }
  }
  @media (max-width: 700px) {
    padding: 12.5px 0px;
    width: 100%;
  }
`;
