import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Montserrat', sans-serif;
        font-size: ${({ theme }) => theme.fonts[4]};
        color: ${({ theme }) => theme.colors.black};
    }
    html {
      scroll-behavior: smooth;
    }
    button{
        cursor: pointer;
    }
    .font-antonio{
      font-family: 'Antonio', sans-serif;
    }
    h1,h2,h3,h4,h5,h6{
      margin-bottom: 30px;
      font-weight: bold;
      line-height: 120%;
    }
    p{
      margin-bottom: 35px;
      font-weight: 400;
      line-height: 150%;
    }
    body{
      overflow-x: hidden;
      background: #fff;
    }
    .color-blue{
      color: ${({ theme }) => theme.colors.blue};
      font-weight: bold;
    }
    .color-orange{
      color: ${({ theme }) => theme.colors.orange};
      font-weight: bold;
    }
    .color-green{
      color: ${({ theme }) => theme.colors.green};
      font-weight: bold;
    }
    .color-gray{
      color: ${({ theme }) => theme.colors.gray};
      font-weight: bold;
    }
    .color-white{
      color: ${({ theme }) => theme.colors.white};
      font-weight: bold;
    }
    .color-blue-light{
      color: ${({ theme }) => theme.colors.blue};
    }
    .color-orange-light{
      color: ${({ theme }) => theme.colors.orange};
    }
    .color-green-light{
      color: ${({ theme }) => theme.colors.green};
    }
    .color-gray-light{
      color: ${({ theme }) => theme.colors.gray};
    }
    .color-white-light{
      color: ${({ theme }) => theme.colors.white};
    }
    .modal-dialog{
      min-width: 400px;
      max-width: 539px;
      width: 100%;
      .modal-header{
        height: 100%;
        padding: 1.5rem 32px;
      }
      .modal-body{
        padding: 15px 32px 32px 32px;
      }
      .modal-content{
        border-radius: 37px;
      }
      hr{
        margin: 20px 10%;
      }
      .close{
        border: none;
        background: none;
        padding: 1rem 2rem;
        span:first-child{
          font-size: 0 !important;
          position: relative;
          &:after{
            content: '';
            background: #1CC9AE;
            width: 2px !important;
            height: 18px !important;
            border-radius: 14px;
            transform: rotate(45deg);
            position: absolute;
            top: -15px;
            right: 0px;
          }
          &:before{
            content: '';
            background: #1CC9AE;
            width: 2px !important;
            height: 18px !important;
            border-radius: 14px;
            transform: rotate(-45deg);
            position: absolute;
            top: -15px;
            right: 00px;
          }
        }
      }
    }
    .modals-text{
      .modal-dialog {
        max-width: 900px;
        height: 100%;
        width: 100%;
        padding-bottom: 100px;
      }
      .modal-content{
        max-height: 70vh;
        margin-top: calc(50% - 35vh);
        border-bottom-right-radius: 0px;
      }
      .modal-body{
        overflow-y: scroll;
      }
    }
    @media (max-width: 700px) {
      .modal-dialog{
        margin-top: 400px;
      }
      .modal-content{
        border-bottom-right-radius: 1.5rem !important;
      }
    }
`;
