import {
  Col, Container, Row,
} from 'react-bootstrap';
import LazyLoad from 'react-lazyload';
import Facebook from '../../../assets/images/12-ads/facebook.png';
import Google from '../../../assets/images/12-ads/google-ads.png';
import Arrow from '../../../assets/images/12-ads/arrow.svg';
import BackgroundImage from '../../../assets/images/12-ads/image.svg';
import * as S from './styles';

export default function Ads() {
  return (
    <S.Section12>
      <Container>
        <Row>
          <Col lg={12} className="">
            <div className="d-flex align-items-start align-items-lg-center justify-content-start flex-column flex-lg-row mb-5">
              <LazyLoad offset={500} once>
                <img src={Facebook} alt="facebook" className="mr-3 mb-3 mb-lg-0" />
                <img src={Google} alt="Google" />
              </LazyLoad>
            </div>
            <h4 className="f-8 font-weight-light">
              Qual a principal diferença com método
              {' '}
              <span className="color-orange f-8">Low Cac - Azutomatize</span>
              ,
              <br />
              quando você só usa
              {' '}
              <strong className="f-8 font-weight-bold">facebook ads</strong>
              {' '}
              e
              {' '}
              <strong className="f-8 font-weight-bold">google ads</strong>
              ?
            </h4>
            <p className="f-7 font-weight-bold">O jeito mais usado hoje de fazer campanhas, recuperar vendas e vender mais, são:</p>
            <p>
              <span className="color-blue">•</span>
              {' '}
              Fazer campanhas e esperar que os clientes entrem em contato;
              <br />
              <span className="color-blue">•</span>
              {' '}
              Fazer Anúncios em redes sociais, que além de exigirem muito tempo e conhecimento, custa muito caro;
              <br />
              <span className="color-blue">•</span>
              {' '}
              Ligar para o cliente de forma manual, um a um gastando muito tempo e pouca produtividade;
              <br />
              <span className="color-blue">•</span>
              {' '}
              Conhecimento limitado das plataformas de anúncios como facebook e google;
              <br />
              <span className="color-blue">•</span>
              {' '}
              Leads Limitadas, muita concorrência e muito cara.
              <br />
              <span className="color-blue">•</span>
              {' '}
              Rezar para o cliente entrar em contato, esperando o resultado; • Muito tempo para metrificar o processo.
              <br />
            </p>
            <hr />
            <div className="box-blue color-white mt-6">
              <span className="title">
                Método:
                {' '}
                <strong className="color-white">Low Cac</strong>
                {' '}
                -
                {' '}
                <span className="color-green">Azutomatize</span>
              </span>
              <p className="color-white f-7 mb-3 mt-2">Criar de imediato campanhas:</p>
              <p className="color-white font-weight-light">
                <span className="color-orange">•</span>
                {' '}
                Comunicação personalizada em cada etapa da jornada do cliente; - Você faz aquisição de uma
                <br />
                {' '}
                simples lista de contatos fria, através das redes sociais pagando menos e utiliza o método Low Cac;
                <br />
                <span className="color-orange">•</span>
                {' '}
                Dispara para os canais com mais assertividade e com acompanhamento das métricas;
                <br />
                <span className="color-orange">•</span>
                {' '}
                Não necessita de muito esforço, você agenda previamente e dispara de forma automática;
                <br />
                <span className="color-orange">•</span>
                {' '}
                Você aquece seu cliente em cada etapa da jornada de compra, com canais de envios,
                <br />
                {' '}
                infinitamente mais assertivos e mais baratos;
                <br />
                <span className="color-orange">•</span>
                {' '}
                Dispara a campanha e ainda consegue falar com seu cliente em tempo real.
              </p>
              <div className="tags">
                <span>SMS</span>
                <span>E-mails</span>
                <span>Discador Automático de Ligações</span>
                <span>WhatsApp</span>
                <span>Telegram</span>
              </div>
              <div className="image-effect d-block d-lg-none">
                <img
                  src={BackgroundImage}
                  alt="Qual a principal diferença com método"
                />
              </div>
              <p className="color-green f-7 mt-5 mb-3">
                RESULTADO DA AÇÃO
              </p>
              <p className="color-white-light font-weight-light mb-3">
                O método é
                {' '}
                <span className="color-green">
                  realizado a partir da integração de diferentes canais de comunicação, vendas ou
                  <br />
                  {' '}
                  suporte de uma empresa.
                </span>
                {' '}
                Esse processo envolve o ambiente digital e o físico, gerando uma jornada de
                <br />
                {' '}
                compra eficiente e com maiores chances de satisfação e retenção do prospect ou cliente.
              </p>
              <p className="mb-0 font-weight-light color-white">
                <img src={Arrow} alt="arrow" className="mr-2" />
                Escale sem esforço;
              </p>
              <p className="mb-0 font-weight-light color-white">
                <img src={Arrow} alt="arrow" className="mr-2" />
                Ganho de produtividade;
              </p>
              <p className="mb-0 font-weight-light color-white">
                <img src={Arrow} alt="arrow" className="mr-2" />
                Ferramenta fácil de usar;
              </p>
              <p className="mb-0 font-weight-light color-white">
                <img src={Arrow} alt="arrow" className="mr-2" />
                Relatórios de performance na primeira campanha.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </S.Section12>
  );
}
