import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components';

export const Section1 = styled.div`
  text-align: left;
  margin-top: 80px;
  padding-bottom: 215px;
  h1{
    margin-bottom: 25px;
  }
  @media (max-width: 700px) {
    padding-bottom: 0px;
  }
`;

export const Image = styled.div`
  padding: 25px 0;
  text-align: left;
  position: relative;
  img{
    position: absolute;
    top: -35px;
    right: -100px;
  }
  @media (max-width: 700px) {
    img{
      position: relative;
      width: 90%;
      right: 0;
      top: 0;
      display: block;
      margin: 0 auto;
    }
  }
`;
