/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import InputMask from 'react-input-mask';
import * as S from './styles';

export default function boxWhatsapp() {
  const [exibirDiv, setExibirDiv] = useState(false);

  const handleClick = () => {
    setExibirDiv(!exibirDiv);
  };

  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');

  const sendEmail = async () => {
    const templateParams = {
      nome,
      email,
      telefone,
    };

    try {
      const response = await emailjs.send(
        'service_14wivhr',
        'template_ot6anuo',
        templateParams,
        'xy0Iv8S1qnqSFtW5L',
      );

      setNome('');
      setEmail('');
      setTelefone('');
      handleClick();
      window.open('https://api.whatsapp.com/send?phone=551142001232', '_blank');
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    sendEmail();
  };

  return (
    <S.Whatsapp>
      <a onClick={handleClick} target="_blank" className="whatsapplink" rel="noreferrer">
        <img src="https://d1nc450dx9gaoz.cloudfront.net/widgets/whatsapp/wapp.svg" />
      </a>
      {exibirDiv ? (
        <div className="box-whatsapp">
          <div className="header">
            Contato por Whatsapp
            <button className="close" onClick={handleClick}>x</button>
          </div>
          <div className="content">
            <form onSubmit={handleSubmit}>
              <label>
                <p>Nome</p>
                <input name="nome" type="text" placeholder="Nome" value={nome} onChange={(event) => setNome(event.target.value)} />
              </label>
              <label>
                <p>Email</p>
                <input name="email" type="email" placeholder="E-mail" value={email} onChange={(event) => setEmail(event.target.value)} />
              </label>
              <label>
                <p>Telefone</p>
                <InputMask
                  placeholder="Telefone"
                  mask="(99) 99999-9999"
                  type="tel"
                  name="phone"
                  value={telefone}
                  onChange={(event) => setTelefone(event.target.value)}
                />
              </label>
              <button type="submit">Iniciar a conversa</button>
            </form>
          </div>
        </div>
      ) : null}

    </S.Whatsapp>
  );
}
