import {
  Col, Container, Row,
} from 'react-bootstrap';
import Button from 'components/common/Button';
import * as S from './styles';

export default function Video() {
  return (
    <S.Section2>
      <Container>
        <Row>
          <Col className="d-flex align-items-center justify-content-center flex-column">
            <div className="box">
              <div>
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/BvBgB2gnAc4"
                  title="Como Melhorar Sua Comunicação ao seu Cliente"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                />
              </div>
              <p className="text-left">
                Este vídeo mostra, como o medo de fracassar pode ser falta de um método ou ferramenta. Convido você
                <br className="d-none d-lg-block" />
                a assistir o vídeo e verificar que não se trata só de uma ferramenta, e sim validar seus
                <br className="d-none d-lg-block" />
                processos, produtos e serviços.
                <br className="d-none d-lg-block" />
                <strong>Vamos ajudar você a sair do teste! Tudo na vida tem um método.</strong>
              </p>
              <Button placeholder="ACESSO AZUTOMATIZE" href="/#Planos" className="w-100 d-block" />
            </div>
          </Col>
        </Row>
      </Container>

    </S.Section2>
  );
}
