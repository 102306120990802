import {
  Col, Container, Row,
} from 'react-bootstrap';
import Image from '../../../assets/images/6-quem-se-destina/image.svg';

import * as S from './styles';

export default function QuemSeDestina() {
  return (
    <S.Section6>
      <Container>
        <Row>
          <Col lg="7" className="text-left">
            <h3>A QUEM SE DESTINA?</h3>
            <p className="mb-4">
              É destinado a quem deseja proporcionar uma experiência de forma rápida e
              {' '}
              <br className="d-none d-lg-block" />
              personalizada ao seu cliente. Fazendo um “follow up” na sua comunicação!
            </p>
            <p>
              <span className="list mb-0">
                <span className="color-blue mr-1">•</span>
                Indústria
              </span>
              <br />
              <span className="list mb-0">
                <span className="color-blue mr-1">•</span>
                Setor Financeiro
              </span>
              <br />
              <span className="list mb-0">
                <span className="color-blue mr-1">•</span>
                Saúde Varejo
              </span>
              <br />
              <span className="list mb-0">
                <span className="color-blue mr-1">•</span>
                Educação
              </span>
              <br />
              <span className="list mb-0">
                <span className="color-blue mr-1">•</span>
                Produtores e info-produtores digitais
              </span>
              <br />
              <span className="list mb-0 ">
                <span className="color-blue mr-1">•</span>
                Ferramenta para apoio no lançamento de produtos e serviços
              </span>
              <br />
              <span className="list mb-0">
                <span className="color-blue mr-1">•</span>
                Agências de publicidade e marketing
              </span>
              <br />
              <span className="list mb-0">
                <span className="color-blue mr-1">•</span>
                Afiliados
              </span>
              <br />
              <span className="list mb-0">
                <span className="color-blue mr-1">•</span>
                Pessoa que deseja empreender e encontrar seu lugar no mundo dos negócios, possibilitando uma vida mais confortável.
              </span>
            </p>
            <h4>
              E PARA QUEM,
              <br className="d-block d-lg-none" />
              {' '}
              <span className="color-orange">NÃO</span>
              {' '}
              É?
            </h4>
            <span>
              Para quem não quer construir um relacionamento personalizado com seu
              {' '}
              <br />
              cliente e quem não quer vender todos os dias.
            </span>
          </Col>
          <Col lg="5" className="d-flex align-items-center justify-content-center">
            <img src={Image} alt="A quem se destina?" className="mt-7 image" />
          </Col>
        </Row>
      </Container>
    </S.Section6>
  );
}
