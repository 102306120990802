import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components';
import backgroundImage from '../../../assets/images/2-video/video.svg';

export const Section2 = styled.div`
  text-align: center;
  background-color:  ${({ theme }) => theme.colors.blue};
  padding-bottom: 85px;
  .box{
    position: relative;
    width: 836px;
    > div {
      height: 470px;
      iframe{
        border-radius: 33px;
      }
    }
    margin-top: -150px;
    &:after{
      content: "";
      background-image: url(${backgroundImage});
      width: 446.08px;
      height: 418.93px;
      position: absolute;
      left: -480px;
      top: 167px;
    }
  }
  @media (max-width: 700px) {
    margin-top: -50px;
    padding-bottom: 35px;

    .box{
        width: 320px;
        > div{
          height: 179.9px;

        }
        margin-top: -50px;
    }
  }
  p{
    margin: 25px 0;
  }
  p, strong{
    color:  ${({ theme }) => theme.colors.white};
  }

`;
