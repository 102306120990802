/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-irregular-whitespace */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Col, Container, Modal, Row,
} from 'react-bootstrap';
import { useState } from 'react';
import Logo from '../../assets/images/footer/logo.svg';
import Twitter from '../../assets/images/icons/twitter.svg';
import Facebook from '../../assets/images/icons/facebook.svg';
import Instagram from '../../assets/images/icons/instagram.svg';
import Modal1 from '../../assets/images/modal/image1.jpg';
import Modal2 from '../../assets/images/modal/image2.jpg';
import Modal3 from '../../assets/images/modal/image3.jpg';
import BoxWhatsapp from './boxWhatsapp';
import Button from '../common/Button';
import * as S from './styles';

export default function Footer() {
  const [showModalLGPD, setShowModalLGPD] = useState(false);
  const [showModalSaaS, setShowModalSaaS] = useState(false);
  const [showModalSMS, setShowModalSMS] = useState(false);

  return (
    <>
      <S.Footer>
        <Container>
          <Row>
            <Col lg={5} className="d-flex d-lg-none align-items-end justify-content-end mb-6">
              <Button href="#header" placeholder="VOLTAR PARA O TOPO" className="outline" />
            </Col>
            <Col lg={7}>
              <div className="d-flex align-items-center align-items-lg-end flex-column flex-lg-row">
                <img src={Logo} alt="logo" className="mr-5" />
                <div className="d-block d-lg-none mt-4 mb-4">
                  <a target="_blank" href="https://twitter.com/AzutonA" rel="noreferrer">
                    <img src={Twitter} alt="AzutoMatize" className="mr-4" />
                  </a>
                  <a target="_blank" href="https://www.facebook.com/azutonsolucoes/" rel="noreferrer">
                    <img src={Facebook} alt="AzutoMatize" className="mr-4 ml-1" />
                  </a>
                  <a target="_blank" href="https://www.instagram.com/crieva_oficial/" rel="noreferrer">
                    <img src={Instagram} alt="AzutoMatize" className="mr-4 ml-1" />
                  </a>
                </div>
                <div className="mr-7">
                  <p className="mb-2 text-center text-lg-left">
                    contato@azutomatize.com.br
                  </p>
                  <p className="mb-3 mb-lg-0 text-center text-lg-left">
                    Atendimento de segunda a sexta,
                    <br className="d-block d-lg-none" />
                    {' '}
                    das 08h ás 18h.
                    <br />
                    <strong>
                      Telefone e WhatsApp
                      {' '}
                    </strong>
                    <span className="color-green-light">:</span>
                    {' '}
                    <strong><a href="tel:1142001232" className="color-white">11 4200 1232</a></strong>
                  </p>
                </div>
                <div>
                  <p className="mb-0">
                    <span className="color-green">Azuton LTDA.</span>
                    <br />
                    34.398.474/0001-55
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={5} className="d-none d-lg-flex align-items-end justify-content-end">
              <Button placeholder="VOLTAR PARA O TOPO" href="#header" className="outline" />
            </Col>
            <Col lg={12} className="mt-4 d-flex flex-column flex-lg-row text-center text-lg-left align-items-center">
              <a className="link mr-0 mr-lg-4 mb-4 mb-lg-0" onClick={() => setShowModalLGPD(true)}>Políticas de proteção de dados</a>
              <a className="link mr-0 mr-lg-4 mb-4 mb-lg-0" onClick={() => setShowModalSaaS(true)}>Termos SaaS</a>
              <a className="link mr-0 mr-lg-6" onClick={() => setShowModalSMS(true)}>Termos SMS</a>

              <a target="_blank" href="https://twitter.com/AzutonA" rel="noreferrer">
                <img src={Twitter} alt="AzutoMatize" className="mr-2 d-none d-lg-block" />
              </a>
              <a target="_blank" href="https://www.youtube.com/@crieva-makeandgocrieva-mak8006?app=desktop" rel="noreferrer">
                <img src={Facebook} alt="AzutoMatize" className="mr-2 ml-1 d-none d-lg-block" />
              </a>
              <a target="_blank" href="https://www.instagram.com/crieva_oficial/" rel="noreferrer">
                <img src={Instagram} alt="AzutoMatize" className="mr-2 ml-1 d-none d-lg-block" />
              </a>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="color-white-light mb-0 mt-6 f-2">
                <strong className="f-2">Aviso Legal</strong>
                :
                {' '}
                Nenhuma informação contida neste produto deve ser interpretada como uma afirmação da obtenção de resultados. Qualquer referência ao desempenho passado ou potencial de uma estratégia abordada no conteúdo não é, e não deve ser interpretada como uma recomendação ou como garantia de qualquer resultado específico.
              </p>
            </Col>
          </Row>
        </Container>
      </S.Footer>
      <S.Footer2>
        <Container className="h-100">
          <div className="d-flex align-items-center justify-content-center justify-content-lg-between h-100 flex-column flex-lg-row">
            <p className="color-white-light f-2 mb-0 text-center text-lg-left">
              Copyright © 2023 Inc. Todos os direitos reservados.
              {' '}
              <strong className="color-white f-2">Azutomatize</strong>
              . CNPJ: 34.398.474/0001-55
            </p>
            <p className="color-white-light mb-0 f-2">
              By
              {' '}
              <a className="color-white f-2" target="_blank" href="https://somocollab.com" rel="noreferrer noopener">Somo Collab</a>
            </p>
          </div>
        </Container>
        <BoxWhatsapp />
      </S.Footer2>

      <Modal show={showModalLGPD} onHide={() => setShowModalLGPD(false)} className="d-flex align-items-center justify-content-center modals-text">
        <Modal.Header closeButton>
          <Modal.Title><span className="f-5 font-weight-bold color-black">LGPD e acordo de Proteção de Dados (APD)</span></Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <p className="f-4 font-weight-light mt-2 text-left">
            <span className="color-green">Versão 1 - Data de atualização: 31 de janeiro de 2023.</span>
            <br />
            <br />
            O Cliente deverá disponibilizar à AZUTON DONOMINADA AZUTOMATIZE e o Cliente autoriza a AZUTON DONOMINADA AZUTOMATIZE a tratar informações, incluindo dados pessoais para a prestação dos Serviços nos
            termos do Contrato (doravante denominado ”
            <span className="font-weight-bold">Contrato</span>
            ”). As partes concordaram em celebrar este Acordo de Proteção de Dados, doravante denominado simplesmente como “
            <span className="font-weight-bold">APD</span>
            ”, para confirmar as regras de
            proteção de dados relativas ao seu relacionamento, bem como para cumprir os requisitos da Legislação de Proteção de Dados aplicável.
          </p>
          <p>
            <span className="color-blue f-5">1. Definições</span>
            <br />
            <br />
            <span className="font-weight-bold">1.1 </span>
            Para os fins deste APD:
            <br />
            <br />
            <span className="color-orange">•</span>
            {' '}
            <span className="font-weight-bold">&quot;Legislação de Proteção de Dados&quot; </span>
            significa a legislação que protege os direitos e liberdades fundamentais dos indivíduos e, em particular, o seu direito à privacidade no que diz respeito ao tratamento de dados pessoais pelo Cliente como controlador de dados, incluindo, sem limitação, todas as leis (inter)nacionais vinculativas e outras diretivas vinculativas de proteção de dados ou segurança de dados, leis, regulamentos e decisões válidas em determinado momento, incluindo quaisquer orientações e códigos de práticas emitidos pela autoridade supervisora aplicável;
            <br />
            <br />
            <span className="color-orange">•</span>
            {' '}
            <span className="font-weight-bold">&quot;Dados Pessoais&quot; </span>
            significa qualquer informação relativa a uma pessoa física identificada ou identificável (&quot;titular dos dados&quot;); uma pessoa identificável é aquela que poderá ser identificada, direta ou indiretamente, em particular por referência a um identificador, como um nome, um número de identificação, dados de localização, identificador online ou a um ou mais fatores específicos de ordem física, fisiológica, genética, identidade mental, econômica, cultural ou social dessa pessoa;
            <br />
            <br />
            <span className="color-orange">•</span>
            {' '}
            <span className="font-weight-bold">&quot;Tratamento de (Dados)&quot;</span>
            {' '}
            significa qualquer operação ou conjunto de operações realizadas sobre Dados Pessoais ou conjuntos de Dados Pessoais, seja ou não por meios automatizados, tais como coleta, registro, organização, estruturação, armazenamento, adaptação ou alteração, extração, consulta, uso, divulgação por transmissão, disseminação ou disponibilização, alinhamento ou combinação, restrição, eliminação ou destruição;
            <br />
            <br />
            <span className="color-orange">•</span>
            {' '}
            <span className="font-weight-bold">&quot;Dados pessoais sensíveis&quot;</span>
            {' '}
            significa informações sobre origem racial ou étnica, opiniões políticas, convicção religiosas ou filosóficas, filiação sindical, dados genéticos, dados biométricos, dados relativos à saúde, dados relativos à vida sexual ou orientação sexual de uma pessoa física ou qualquer outro categoria especial de dados conforme indicado no Anexo 2 Alterações específicas com base na legislação nacional aplicável ou na Ordem de Serviço ou Especificação de Serviço;
            <br />
            <br />
            <span className="color-orange">•</span>
            {' '}
            <span className="font-weight-bold">&quot;Medidas Técnicas e Organizacionais&quot; </span>
            ou TOMs significa medidas destinadas a proteger Dados Pessoais contra destruição acidental ou ilícita ou perda acidental, alteração, divulgação ou acesso não autorizado. Isso inclui os requisitos de segurança aplicáveis acordados, as instruções de segurança e suas atualizações aplicáveis a cada momento, descritas no Anexo 1 Medidas Técnicas e Organizacionais deste APD ou na Ordem de Serviço ou Especificação do Serviço;
            <br />
            <br />
            <span className="color-orange">•</span>
            {' '}
            Os termos &quot;controlador de dados&quot; e &quot;operador de dados&quot; terão os significados que lhes são atribuídos na GDPR.
          </p>
          <p>
            <span className="font-weight-bold">1.2 </span>
            Termos em letras maiúsculas utilizados e não definidos neste APD têm os significados atribuídos no Contrato.
          </p>
          <p>
            <span className="color-blue f-5">1. Definições</span>
            {' '}
            <br />
            <br />
            As partes entendem que para a prestação dos Serviços é feita uma distinção entre dois tipos de tratamento de Dados Pessoais: (i) a prestação dos serviços (ou seja, o banco de dados de registros de dados de chamadas e os logs criados e gerenciados pela AZUTON DONOMINADA AZUTOMATIZE em nome e sob a supervisão do Cliente) para o qual a AZUTON DONOMINADA AZUTOMATIZE atuará como um operador de dados e concorda em cumprir com as respectivas obrigações estabelecidas neste APD, e (ii) a transmissão de mensagens (ex.  A2P, SMS) pela Azuton donominada Azutomatize e outros Provedores de Serviços para os quais a AZUTON DONOMINADA AZUTOMATIZE atuará como controlador de dados, conforme seção 14 deste APD.
          </p>
          <p>
            <span className="color-blue f-5">
              3. Objeto, natureza e finalidade do tratamento de dados pessoais da Azuton donominada Azutomatize
            </span>
            {' '}
            <br />
            <br />
            <span className="font-weight-bold">3.1</span>
            {' '}
            O objeto, a natureza e a finalidade do tratamento de Dados Pessoais nos termos deste APD são a execução dos Serviços por parte da AZUTON DONOMINADA AZUTOMATIZE nos termos do Contrato e conforme instruído posteriormente pelo Cliente em seu uso dos Serviços (&quot;Instruções&quot;), a menos que seja necessário fazê-lo de forma diversa conforme estabelecido e na medida permitida pela Legislação de Proteção de Dados e/ou Leis Relevantes.
            <br />
            <br />
            <span className="font-weight-bold">3.2</span>
            {' '}
            As instruções do Cliente deverão ser por escrito (incluindo, mas não se limitando a, e-mail) ou poderão ser fornecidas por meio de configurações e uso do(s) portal(ais) e/ou software da AZUTON DONOMINADA AZUTOMATIZE. Em casos excepcionais, as Instruções poderão ser fornecidas oralmente pelo Cliente. Essas Instruções orais serão confirmadas pela pessoa autorizada do Cliente por escrito ou por e-mail (em formato de texto).
          </p>
          <p>
            <span className="color-blue f-5">
              4. Duração
            </span>
            <br />
            <br />
            <span className="font-weight-bold">4.1</span>
            {' '}
            A AZUTON DONOMINADA AZUTOMATIZE deverá apenas coletar ou tratar Dados Pessoais durante a vigência do Contrato na medida e conforme necessário para a prestação dos Serviços e de acordo com o Contrato e a Legislação de Proteção de Dados aplicável à AZUTON DONOMINADA AZUTOMATIZE conforme sua função no tratamento de Dados Pessoais.
            <br />
            {' '}
            <br />
            <span className="font-weight-bold">4.2</span>
            {' '}
            O tratamento de Dados Pessoais será realizado pela AZUTON DONOMINADA AZUTOMATIZE após o término do Contrato desde que necessário para cumprir com as obrigações deste APD ou quando necessário devido à obrigação legal, salvo acordo expresso das partes de forma diversa.
          </p>
          <p>
            <span className="color-blue f-5">5. Tipo de dados pessoais tratados  </span>
            {' '}
            <br />
            <br />
            As seguintes categorias de Dados Pessoais poderão ser tratadas para fornecer os Serviços, cuja extensão é determinada e controlada pelo Cliente a seu exclusivo critério e poderá incluir, mas não está limitada às seguintes categorias de Dados Pessoais:
            <br />
            • Informações de contato (empresa, e-mail, telefone, endereço físico)
            <br />
            • Primeiro e último nome • Dados de identificação
            <br />
            • Cargo
            <br />
            • Função
            <br />
            • Empregador
            <br />
            • Dados de conexão
            <br />
            • Dados de localização
            <br />
            Outros dados, conforme definido no Contrato, conforme acordado entre as partes.
          </p>
          <p>
            <span className="color-blue f-5">6. Tipo de titularidades de dados</span>
            <br />
            <br />
            O Cliente poderá enviar Dados Pessoais através da utilização dos Serviços, cuja extensão é determinada e controlada pelo Cliente a seu exclusivo critério, e que poderão incluir, mas não estão limitados a Dados Pessoais relacionados às seguintes categorias de titulares de dados:
            <br />
            • Clientes, parceiros de negócios e fornecedores do Cliente (que são pessoas físicas)
            <br />
            • Funcionários de pessoas de contato dos clientes, parceiros de negócios e fornecedores do Cliente
            <br />
            • Funcionários, agentes, consultores, autônomos do Cliente (que sejam pessoas físicas)
            <br />
            • Usuário dos Serviços do Cliente, incluindo qualquer usuário dos Serviços, que o Cliente permita usar os Serviços
          </p>
          <p />
          <p>
            <span className="color-blue f-5">7. Sub operadores</span>
            <br />
            <br />
            <span className="font-weight-bold">7.1</span>
            {' '}
            O Cliente concorda que a AZUTON DONOMINADA AZUTOMATIZE poderá envolver uma Afiliada da Azuton donominada Azutomatize ou terceiros para tratar dados pessoais com a finalidade de viabilizar o fornecimento de Serviços da AZUTON DONOMINADA AZUTOMATIZE em nome do Cliente (&quot;Sub operadores&quot;). A AZUTON DONOMINADA AZUTOMATIZE tem ou irá celebrar um acordo por escrito com cada Sub operador contendo obrigações de proteção de dados que não sejam menos estritas do que aquelas que constam deste APD, na medida aplicável à natureza dos Serviços fornecidos por tal Sub operador.
            <br />
            <br />
            <span className="font-weight-bold">7.2</span>
            {' '}
            A AZUTON DONOMINADA AZUTOMATIZE poderá substituir um Sub operador sem aviso prévio quando o motivo da alteração estiver fora do controle razoável da AZUTON DONOMINADA AZUTOMATIZE e a substituição imediata se fizer necessária por motivos de segurança ou outros motivos urgentes, incluindo mas sem se limitar a não conformidade de um Sub operador com a Legislação de Proteção de Dados ou APD entre AZUTON DONOMINADA AZUTOMATIZE e o Sub operador. Neste caso, a AZUTON DONOMINADA AZUTOMATIZE informará o controlador de Dados sobre a substituição do Sub operador assim que possível após sua nomeação. A Seção 7.4 se aplica, por conseguinte.
            <br />
            <br />
            <span className="font-weight-bold">7.3</span>
            {' '}
            Para evitar dúvidas, quando qualquer Sub operador deixar de cumprir suas obrigações sob qualquer contrato de sub tratamento ou sob a lei aplicável, a AZUTON DONOMINADA AZUTOMATIZE permanecerá totalmente responsável perante o Cliente pelo cumprimento de suas obrigações nos termos deste APD.
          </p>
          <p>
            <span className="color-blue f-5">8. Transferência internacional</span>
            {' '}
            <br />
            <br />
            <span className="font-weight-bold">8.1</span>
            {' '}
            Sempre que a AZUTON DONOMINADA AZUTOMATIZE (ou seus Sub operadores) tratar Dados Pessoais em outros países que não o país em que a AZUTON DONOMINADA AZUTOMATIZE está estabelecida, a AZUTON DONOMINADA AZUTOMATIZE garantirá um nível adequado de proteção de Dados Pessoais por meio de medidas organizacionais, técnicas e contratuais conforme exigido pela Legislação de Proteção de Dados aplicável e este APD.
            <br />
            <br />
            <span className="font-weight-bold">8.2</span>
            {' '}
            Quando (i) Dados Pessoais de outro controlador de Dados são tratados internacionalmente e tal tratamento internacional requeira um meio de adequação de acordo com as leis do país do controlador de Dados, incluindo mas não limitado à adequação e ao cumprimento das Cláusulas Contratuais Padrão Europeias aprovadas pela Comissão Europeia para a transferência de dados pessoais. O Cliente fornece uma procuração para que a AZUTON DONOMINADA AZUTOMATIZE possa celebrar quaisquer Cláusulas Contratuais Padrão Europeias aprovadas pela Comissão Europeia com um Suboperador aprovado conforme estabelecido na cláusula 7 em nome do Cliente, ou quando (ii) os Dados Pessoais de um controlador de Dados baseado no Espaço Econômico Europeu (EEE) ou na Suíça são tratados em um país fora do EEE, Suíça e qualquer país, organização ou território reconhecido pela União Europeia como país seguro com um nível adequado de proteção de dados nos termos do art. 45 GDPR e nenhum outro mecanismo de transferência legal, como Regras Corporativas Vinculativas (art. 47 GDPR) ou Código de Conduta (art. 40 GDPR).
            <br />
            <br />
            <span className="font-weight-bold">8.3</span>
            {' '}
            No caso de a Comissão Europeia aprovar Cláusulas Contratuais Padrão Europeias celebradas entre a AZUTON DONOMINADA AZUTOMATIZE e o Cliente, respectivas cláusulas se aplicam até que uma autoridade supervisora competente de um Estado-Membro, ou de um tribunal da União Europeia ou de um Estado-Membro competente aprove um novo mecanismo de transferência legal aplicável às transferências de dados cobertas pelas Cláusulas Contratuais Padrão Europeias (no caso de tal mecanismo se aplicar apenas a algumas das transferências de dados, as Cláusulas Contratuais Padrão Europeias permanecerão aplicáveis para as transferências que não poderão ser cobertas pelo novo mecanismo de transferência legal):
            <br />
            <br />
            <span className="font-weight-bold">(i)</span>
            {' '}
            Os direitos concedidos aos titulares dos dados ao abrigo deste APD e das Cláusulas Contratuais Padrão Europeias poderão ser aplicados pelo titular dos dados contra a AZUTON DONOMINADA AZUTOMATIZE, independentemente de qualquer restrição nas Cláusulas 3 ou 6 das Cláusulas Contratuais Padrão Europeias. Respectivos direitos são pessoais e não poderão ser atribuídos a terceiros. O titular dos dados só poderá apresentar uma reclamação ao abrigo deste APD e das Cláusulas Contratuais Padrão Europeias individualmente, e não como parte de uma ação coletiva, de grupo ou representativa.
            <br />
            <br />
            <span className="font-weight-bold">(ii)</span>
            {' '}
            Além da Cláusula 5 (b) das Cláusulas Contratuais Padrão Europeias, a AZUTON DONOMINADA AZUTOMATIZE concorda que, no momento da celebração deste Contrato, não tem motivos para acreditar que a legislação aplicável a AZUTON DONOMINADA AZUTOMATIZE ou a seus Suboperador, incluindo em qualquer país em que os Dados Pessoais são transferidos pela AZUTON DONOMINADA AZUTOMATIZE ou por meio de um Suboperador, a impede de cumprir as instruções recebidas do Cliente e suas obrigações nos termos das Cláusulas Contratuais Padrão Europeias e que, no caso de uma alteração nesta legislação, que provavelmente terá um efeito adverso nas garantias e obrigações previstas nas Cláusulas Contratuais Padrão Europeias, notificará a alteração ao Cliente assim que tiver conhecimento, caso em que o Cliente tem o direito de suspender a transferência de dados e/ou rescindir o Contrato.
            <br />
            <br />
            <span className="font-weight-bold">(iii)</span>
            {' '}
            Para os fins desta seção, os esforços legais não incluem ações que resultariam em penalidades civis ou criminais, como desacato ao tribunal sob as leis da jurisdição relevante:
            Caso a AZUTON DONOMINADA AZUTOMATIZE receba um pedido de terceiros para divulgação forçada de quaisquer Dados Pessoais que tenham sido transferidos de acordo com as Cláusulas Contratuais Padrão Europeias, a AZUTON DONOMINADA AZUTOMATIZE deverá, sempre que possível, redirecionar o terceiro para solicitar dados diretamente do Cliente.
            Caso a AZUTON DONOMINADA AZUTOMATIZE receba um pedido de qualquer terceiro para a divulgação forçada de quaisquer Dados Pessoais que tenham sido transferidos de acordo com as Cláusulas Contratuais Padrão Europeias, a AZUTON DONOMINADA AZUTOMATIZE envidará todos os esforços legais para contestar o pedido de divulgação com base em quaisquer deficiências legais sob as leis da parte requerente ou quaisquer conflitos relevantes com a legislação da União Europeia ou com a legislação aplicável dos EstadosMembros.
          </p>
          <p>
            <span className="color-blue f-5">9. Medidas técnicas e organizacionais</span>
            <br />
            <br />
            A AZUTON DONOMINADA AZUTOMATIZE implementou e mantém medidas técnicas e organizacionais adequadas para proteger Dados Pessoais tratados contra tratamentos não autorizado ou ilegal e contra perda acidental, destruição, dano, alteração ou divulgação. Estas medidas são descritas no Anexo 1 Medidas Técnicas e Organizacionais.
          </p>
          <p>
            <span className="color-blue f-5">10. Garantias de qualidade e outras funções da Azuton donominada Azutomatize</span>
            {' '}
            {' '}
            <br />
            <br />
            <span className="font-weight-bold">10.1</span>
            {' '}
            A AZUTON DONOMINADA AZUTOMATIZE deverá cumprir os seguintes requisitos, sendo:
            não realizar nenhum tratamento de Dados Pessoais, exceto por instruções do controlador de Dados e/ou quando exigido por uma autoridade nos termos da lei;
            implementar um registro de tratamento de dados
            implementar medidas técnicas e organizacionais para garantir um nível de segurança de dados adequado ao nível de risco apresentado pelo tratamento de Dados Pessoais;
            cooperar com a autoridade supervisora de proteção de dados no desempenho de suas funções
            notificar a violação de Dados Pessoais à autoridade supervisora e ao titular dos dados;
            realizar uma avaliação de impacto à proteção de dados quando necessário de acordo com a lei e consultar a autoridade supervisora antes do tratamento de dados, quando a avaliação de impacto à proteção de dados indicar que o tratamento resultaria em um alto risco na ausência de medidas tomadas pelo controlador de Dados para mitigar o risco.
            e garante, em particular, o cumprimento dos seguintes requisitos:
            <br />
            <br />
            <span className="font-weight-bold">(a)</span>
            {' '}
            Nomear um encarregado pelo tratamento de dados pessoais, que desempenhará suas  funções em conformidade com a Legislação de Proteção de Dados. Os detalhes de contato do encarregado oficial de proteção de dados (DPO) estão disponíveis na página oficial da AZUTON DONOMINADA AZUTOMATIZE na web. Caso a parte contratante da AZUTON DONOMINADA AZUTOMATIZE não estiver estabelecida na União Europeia, a AZUTON DONOMINADA AZUTOMATIZE nomeará uma pessoa de contato responsável na União Europeia e/ou um encarregado oficial de proteção de dados de acordo com a Legislação de Proteção de Dados.
            <br />
            <br />
            <span className="font-weight-bold">(b)</span>
            {' '}
            Confidencialidade de acordo com a Legislação de Proteção de Dados. A AZUTON DONOMINADA AZUTOMATIZE confia o tratamento de dados descrito neste Contrato apenas aos funcionários que estão sujeitos à confidencialidade e que foram previamente familiarizados com as disposições de proteção de dados relevantes para seu trabalho. A AZUTON DONOMINADA AZUTOMATIZE e qualquer pessoa agindo sob sua autoridade que tenha acesso aos Dados Pessoais, não deverá tratar esses dados, a menos que sob instruções do Cliente (o que inclui os poderes concedidos neste APD), e/ou, a menos que exigido pela Legislação de Proteção de Dados.
            <br />
            <br />
            <span className="font-weight-bold">(c)</span>
            {' '}
            Às custas e despesas do Cliente e levando em consideração a natureza do tratamento e as informações disponíveis para a AZUTON DONOMINADA AZUTOMATIZE, fornecer as informações e assistência que o Cliente possa razoavelmente exigir e dentro dos prazos razoavelmente especificados pelo Cliente para auxiliar o Cliente a cumprir suas obrigações sob a Legislação de Proteção de Dados aplicável, que poderá incluir ajudar o Cliente a:
            <br />
            <br />
            <span className="font-weight-bold">(i)</span>
            {' '}
            notificar o Cliente de qualquer solicitação que a AZUTON DONOMINADA AZUTOMATIZE receba para um titular de dados relativos a dados pessoais tratados e notificar o titular dos dados para entrar em contato com o Cliente se quiser usar seus direitos;
            <br />
            <br />
            <span className="font-weight-bold">(ii)</span>
            {' '}
            cumprir suas obrigações de segurança;
            <br />
            <br />
            <span className="font-weight-bold">(iii)</span>
            {' '}
            cumprir suas obrigações de responder às solicitações relacionadas ao exercício dos direitos do titular dos dados, incluindo direito de acesso, direito de retificação, direito de apagamento (&quot;direito de ser esquecido&quot;) direito de restrição de tratamento (na medida em que os dados pessoais não estejam acessíveis ao Cliente por meio dos Serviços); realizar a Avaliação do Impacto à Proteção de Dados e auditar a conformidade da Avaliação do Impacto à Proteção de Dados e consultar a autoridade supervisora;
            <br />
            <br />
            <span className="font-weight-bold">(iv)</span>
            {' '}
            seguir a Avaliação de Impacto à Proteção de Dados.
            <br />
            <br />
            <span className="font-weight-bold">(d)</span>
            {' '}
            Para os fins desta seção, os esforços legais não incluem ações que resultariam em penalidades civis ou criminais, como desacato ao tribunal sob as leis da jurisdição relevante:
            <br />
            <br />
            <span className="font-weight-bold">(i)</span>
            {' '}
            A menos que proibido pela lei aplicável ou por um pedido legalmente vinculativo de aplicação da lei, a AZUTON DONOMINADA AZUTOMATIZE notificará imediatamente o Cliente de qualquer pedido por, qualquer funcionário do governo, autoridade supervisora de proteção de dados ou autoridade policial em relação a quaisquer dados pessoais e, se proibido de notificar o Cliente, a AZUTON DONOMINADA AZUTOMATIZE envidará todos os esforços legais para obter o direito de renunciar à proibição a fim de comunicar o máximo de informações ao Cliente o mais rápido possível;
            <br />
            <br />
            <span className="font-weight-bold">(e)</span>
            {' '}
            A AZUTON DONOMINADA AZUTOMATIZE deverá monitorar periodicamente os processos internos e os TOMs para garantir que o tratamento dentro da área de responsabilidade da AZUTON DONOMINADA AZUTOMATIZE esteja de acordo com os requisitos da Legislação de Proteção de Dados e a proteção dos direitos do titular dos dados.
          </p>
          <p>
            <span className="color-blue f-5">11 Auditorias e Inspeções</span>
            {' '}
            <br />
            <br />
            {' '}
            <span className="font-weight-bold">11.1</span>
            {' '}
            No caso de o Cliente, um Regulador ou autoridade de proteção de dados exigir informações adicionais ou uma auditoria relacionada aos Serviços, então, a AZUTON DONOMINADA AZUTOMATIZE concorda em conceder acesso às suas instalações de tratamento de dados, arquivos de dados e documentação necessária para o tratamento de Dados Pessoais. A AZUTON DONOMINADA AZUTOMATIZE concorda em fornecer cooperação razoável durante tais operações, incluindo o fornecimento de todas as informações relevantes e acesso a todos os equipamentos, software, dados, arquivos, sistemas de informação etc., usados para a execução dos Serviços, incluindo o tratamento de Dados Pessoais.
            <br />
            <br />
            <span className="font-weight-bold">11.2</span>
            {' '}
            O direito de auditoria, conforme descrito na cláusula 11.1, será aplicável para o Cliente, caso a AZUTON DONOMINADA AZUTOMATIZE não tenha fornecido evidências suficientes de seu cumprimento das medidas técnicas e organizacionais. Evidências suficientes incluem o fornecimento de: (i) uma certificação quanto à conformidade com a ISO 27001 ou outras normas implementadas pela AZUTON DONOMINADA AZUTOMATIZE (escopo conforme definido no certificado); ou (ii) um relatório de auditoria ou certificação de um terceiro independente. Uma auditoria conforme descrito na cláusula 11.1 deverá ser realizada por conta e custo do Cliente. Uma auditoria poderá ser feita pelo Cliente ou qualquer terceiro razoavelmente aceitável para a AZUTON DONOMINADA AZUTOMATIZE (que não deverá incluir quaisquer auditores terceiros que sejam concorrentes da AZUTON DONOMINADA AZUTOMATIZE ou não devidamente qualificados ou independentes) para verificar a conformidade com este APD, bem como o cumprimento das medidas técnicas e organizacionais da AZUTON DONOMINADA AZUTOMATIZE, desde que mediante aviso prévio razoável de no mínimo trinta (30) dias e celebração de um acordo de não divulgação diretamente entre AZUTON DONOMINADA AZUTOMATIZE e o auditor.
          </p>
          <p>
            <span className="color-blue f-5">12 Notificação de violação de dados</span>
            {' '}
            <br />
            <br />
            <span className="font-weight-bold">12.1</span>
            {' '}
            Caso a AZUTON DONOMINADA AZUTOMATIZE fique ciente de qualquer violação de segurança que resulte na destruição acidental, não autorizada ou ilícita ou na divulgação não autorizada ou no acesso a Dados Pessoais, a AZUTON DONOMINADA AZUTOMATIZE deverá, entre outras coisas:
            {' '}
            <br />
            a) Notificar o Cliente por escrito imediatamente, mas não depois de 72 horas após tomar conhecimento da violação de Dados Pessoais;
            {' '}
            <br />
            b) Auxiliar o Cliente no que diz respeito à obrigação do Cliente de fornecer informações ao titular dos dados e de fornecer ao Cliente informações relevantes a esse respeito;
            {' '}
            <br />
            c) Apoiar o Cliente em consultas com autoridade de proteção de dados.
            <br />
            <br />
            <span className="font-weight-bold">12.2</span>
            {' '}
            Na medida do legalmente possível, a AZUTON DONOMINADA AZUTOMATIZE poderá reclamar uma compensação pelos serviços de apoio nos termos desta cláusula 12 que não sejam atribuíveis a violações de Dados Pessoais causadas pela AZUTON DONOMINADA AZUTOMATIZE.
          </p>
          <p>
            <span className="color-blue f-5">13. Eliminação de dados pessoais</span>
            {' '}
            <br />
            <br />
            <span className="font-weight-bold">13.1 </span>
            {' '}
            A AZUTON DONOMINADA AZUTOMATIZE é obrigada a apagar os Dados Pessoais conforme estipulado no Contrato e de acordo com a Legislação de Proteção de Dados e/ou Leis Relevantes.
            <br />
            <br />
            <span className="font-weight-bold"> 13.2</span>
            {' '}
            {' '}
            O Cliente tem o direito de solicitar a execução dos direitos e obrigações descritos na cláusula 13.1 durante a duração de todo o APD.
            <br />
            <br />
            <span className="font-weight-bold">13.3</span>
            {' '}
            {' '}
            As obrigações legais de retenção ou obrigações contratuais para com os Prestadores de Serviços da AZUTON DONOMINADA AZUTOMATIZE (por exemplo, mas não limitado aos operadores) permanecem inalteradas pelas disposições acima. A documentação que serve como evidência para um tratamento de dados ordenado de acordo com as disposições do APD deverá ser retida pela AZUTON DONOMINADA AZUTOMATIZE após o encerramento do APD de acordo com a Legislação de Proteção de Dados e/ou Leis Relevantes.
          </p>
          <p>
            <span className="color-blue f-5">14. Obrigações da Azuton donominada Azutomatize como controlador de dados </span>
            {' '}
            <br />
            <br />
            Em situações em que a AZUTON DONOMINADA AZUTOMATIZE atuará como controlador de Dados, ela se compromete a cumprir com suas obrigações nos termos da Legislação de Proteção de Dados aplicável em relação a quaisquer dados pessoais tratados em relação ao Contrato e aos Serviços. A AZUTON DONOMINADA AZUTOMATIZE deverá tratar esses dados pessoais em conexão com a transmissão de mensagens e para cumprir suas obrigações associadas nos termos do Contrato ou conforme exigido por lei, ordem judicial ou qualquer autoridade governamental ou reguladora e de acordo com sua política de privacidade, que está disponível em https://www.Azuton donominada Azutomatize.com/privacy-policy/ conforme alterado de tempos em tempos, se necessário.
          </p>
          <p>
            <span className="color-blue f-5">15. Obrigações do cliente</span>
            {' '}
            <br />
            <br />
            O Cliente deverá cumprir em todos os momentos a Legislação de Proteção de Dados em relação ao tratamento de dados pessoais em conexão com o Contrato e os Serviços. O Cliente deverá informar a AZUTON DONOMINADA AZUTOMATIZE por escrito caso seja aplicável legislação adicional ao Tratamento de Dados Pessoais que não a legislação do país onde o Cliente está estabelecido.
          </p>
          <p>
            <span className="color-blue f-5">16. Limitação de responsabilidade</span>
            {' '}
            <br />
            <br />
            <span className="font-weight-bold">16.1</span>
            {' '}
            A responsabilidade de cada parte e de todas as suas Afiliadas, em conjunto no agregado, decorrente de ou relacionada a este APD seja em contrato, ato ilícito ou sob qualquer outra teoria de responsabilidade, está sujeita à seção de Limitação de Responsabilidade do Contrato, e qualquer referência em tal seção à responsabilidade de uma parte significa a responsabilidade agregada dessa parte e de todas as suas Afiliadas nos termos do Contrato e deste APD.
            <br />
            <br />
            <span className="font-weight-bold">16.2</span>
            {' '}
            A cláusula 16.1 não se aplica se o dano tiver sido causado pela implementação incorreta do serviço encomendado realizada pelo Cliente ou por uma instrução dada pelo Cliente. Nesse caso, o Cliente será responsável por tais danos.
          </p>
          <p>
            <span className="color-blue f-5"> 17. Disposições diversas</span>
            {' '}
            <br />
            <br />
            <span className="font-weight-bold">17.1</span>
            {' '}
            O APD é parte integrante do Contrato entre o Cliente e a AZUTON DONOMINADA AZUTOMATIZE. Em caso de conflito entre as disposições obrigatórias nas Cláusulas Contratuais Padrão Europeias e este APD, as Cláusulas Contratuais Padrão Europeias prevalecem. Em caso de outros conflitos entre outros documentos (inclusive em caso de conflito entre o Contrato e este APD), o APD prevalecerá.
            <br />
            <br />
            <span className="font-weight-bold">17.2</span>
            {' '}
            Caso qualquer disposição deste APD seja ou se torne inválida ou contenha uma lacuna, as disposições restantes não serão afetadas. O Cliente e a AZUTON DONOMINADA AZUTOMATIZE comprometem-se a substituir a disposição inválida por disposições legalmente válidas que mais se aproximam do interesse da disposição inválida, respectivamente, que preenche a lacuna.
          </p>
          <p>
            <span className="color-green text-uppercase f-5">
              ANEXO 1 ao Acordo de Proteção de Dados
              <br />
              {' '}
              Medidas Técnicas e Organizacionais
            </span>
            <br />
            <br />
            A AZUTON DONOMINADA AZUTOMATIZE implementará as medidas descritas neste Anexo 1, desde que as medidas contribuam direta ou indiretamente ou possam contribuir para a proteção de Dados Pessoais no âmbito do Contrato celebrado entre as partes para o tratamento de dados.
            {' '}
            <br />
            <br />
            As medidas técnicas e organizacionais que são implementadas pela AZUTON DONOMINADA AZUTOMATIZE são baseadas no estado atual da tecnologia, os custos de implementação e a natureza, âmbito, circunstâncias e finalidades do tratamento e a probabilidade e gravidade do risco aos direitos e liberdades dos indivíduos são verdadeiras. As Medidas Técnicas e Organizacionais estão sujeitas ao progresso e desenvolvimento técnico. A este respeito, a AZUTON DONOMINADA AZUTOMATIZE está autorizada a implementar medidas alternativas adequadas. O nível de segurança deverá estar alinhado com as melhores práticas de segurança do setor e não menos do que as medidas aqui estabelecidas. Todas as principais alterações deverão ser acordadas com o Cliente e documentadas.
            <br />
            As Medidas Técnicas e Organizacionais incluídas neste Anexo 1 são medidas aplicáveis ao(s) Serviço(s) prestado(s) pela AZUTON DONOMINADA AZUTOMATIZE. Se necessário, para o Serviço, a AZUTON DONOMINADA AZUTOMATIZE poderá incluir outras medidas técnicas e organizacionais na Ordem de Serviço ou Especificação de Serviço.
            {' '}
            <br />
            <br />

            <span className="font-weight-bold"> 1. Gestão de riscos e Procedimentos para validação, revisão e avaliação</span>
            <br />
            i) A AZUTON DONOMINADA AZUTOMATIZE deverá identificar e avaliar os riscos de segurança relacionados à confidencialidade, integridade e disponibilidade e, com base nessa avaliação, implementar medidas técnicas e organizacionais adequadas para garantir um nível de segurança adequado ao risco.
            <br />
            ii) A AZUTON DONOMINADA AZUTOMATIZE deverá ter processos e rotinas documentados para lidar com os riscos em suas operações e ao tratar dados pessoais em nome do Cliente.
            <br />
            iii) A AZUTON DONOMINADA AZUTOMATIZE deverá avaliar periodicamente os riscos relacionados aos sistemas de informação e tratamento, armazenamento e transmissão de informações.
            <br />
            iv) A AZUTON DONOMINADA AZUTOMATIZE deverá identificar e avaliar os riscos de segurança relacionados à confidencialidade, integridade e disponibilidade e, com base em tal avaliação, implementar medidas técnicas e organizacionais adequadas para garantir um nível de segurança adequado ao risco dos tipos e finalidades de Dados Pessoais específicos sendo tratados pela AZUTON DONOMINADA AZUTOMATIZE, incluindo, entre outros, conforme o caso:
            <br />
            a) A pseudonimização e criptografia de dados pessoais;
            <br />
            b) A capacidade de garantir a confidencialidade, integridade, disponibilidade e resiliência contínuas dos sistemas e serviços de tratamento;
            <br />
            c) A capacidade de restaurar a disponibilidade e o acesso aos Dados do Cliente em tempo hábil no caso de um incidente físico ou técnico;
            <br />
            v) Um processo para testar regularmente, avaliar e avaliar a eficácia das medidas técnicas e organizacionais para garantir a segurança do tratamento.
            <br />
            vi) A AZUTON DONOMINADA AZUTOMATIZE deverá avaliar periodicamente os riscos relacionados aos sistemas de informação e tratamento de dados pessoais (por exemplo, ao armazenar e transmitir dados pessoais).
            <br />
            vii) A Azuton donominada Azutomatize deverá monitorar, revisar e auditar regularmente a conformidade do Suboperador com as Medidas Técnicas e Organizacionais e a Azuton donominada Azutomatize deverá, a pedido do Cliente, fornecer ao Cliente evidências sobre a conformidade do Suboperador com as Medidas Técnicas e Organizacionais.
            <br />
            viii) A Azuton donominada Azutomatize funcionará de acordo com os princípios de proteção de dados desde o projeto e por padrão e deverá fornecer documentação suficiente da implementação da proteção de dados desde o projeto e por padrão.
            <br />
            <br />

            <span className="font-weight-bold"> 2. Medidas Organizacionais</span>
            <br />
            <br />
            A organização interna do operador de dados deverá atender aos requisitos específicos de proteção de dados.
            <br />
            A) Políticas e Gerenciamento de Políticas
            <br />
            i) A AZUTON DONOMINADA AZUTOMATIZE deverá ter um sistema de gestão de segurança da informação (ISMS) definido e documentado, incluindo uma política e procedimentos de segurança da informação em vigor, que deverão ser aprovados pela administração da AZUTON DONOMINADA AZUTOMATIZE. Eles deverão ser publicados dentro da organização da AZUTON DONOMINADA AZUTOMATIZE e comunicados as pessoas relevantes da AZUTON DONOMINADA AZUTOMATIZE.
            <br />
            ii) A AZUTON DONOMINADA AZUTOMATIZE deverá rever periodicamente as políticas e procedimentos da AZUTON DONOMINADA AZUTOMATIZE relativos à proteção de dados e segurança da informação e atualizá-los, se necessário, para garantir a sua conformidade com as Medidas Técnicas e Organizacionais e o APD
            <br />
            B) Organização de Proteção de Dados e Segurança da Informação
            <br />
            i) A AZUTON DONOMINADA AZUTOMATIZE deverá indicar pelo menos um encarregado pelo tratamento de dados pessoais com as competências adequadas e que atua como o principal contacto para a proteção de dados. Se exigido por lei, a AZUTON DONOMINADA AZUTOMATIZE nomeará um encarregado de proteção de dados em nível de empresa.
            <br />
            ii) A AZUTON DONOMINADA AZUTOMATIZE deverá ter funções e responsabilidades de segurança definidas e documentadas em sua organização.
            <br />
            C) Requisitos Organizacionais
            <br />
            i) A AZUTON DONOMINADA AZUTOMATIZE deverá garantir que o pessoal da AZUTON DONOMINADA AZUTOMATIZE trate as informações de acordo com o nível de confidencialidade exigido pelo APD e que tenha o compromisso por escrito dos funcionários de manter a confidencialidade.
            <br />
            ii) A AZUTON DONOMINADA AZUTOMATIZE deverá garantir que as pessoas relevantes da Azuton donominada Azutomatize estejam cientes do uso aprovado (incluindo restrições de uso, conforme o caso) de informações, instalações e sistemas nos termos do APD.
            <br />
            iii) A AZUTON DONOMINADA AZUTOMATIZE deverá garantir que qualquer pessoal da AZUTON DONOMINADA AZUTOMATIZE que desempenhe atribuições nos termos do APD seja confiável, atenda aos critérios de segurança estabelecidos e tenha sido, e durante o prazo da atribuição, sujeito a uma triagem adequada e verificação de antecedentes (se permitido pela lei aplicável).
            <br />
            iv) A AZUTON DONOMINADA AZUTOMATIZE deverá garantir que o pessoal da AZUTON DONOMINADA AZUTOMATIZE com responsabilidades de segurança seja adequadamente treinado para realizar as tarefas relacionadas à segurança.
            <br />
            v) A AZUTON DONOMINADA AZUTOMATIZE deverá fornecer ou garantir treinamento periódico de conscientização para o pessoal relevante da AZUTON DONOMINADA AZUTOMATIZE. Esse treinamento AZUTON DONOMINADA AZUTOMATIZE deverá incluir, sem limitação:
            <br />
            a) Como lidar com a segurança da informação do Cliente (ou seja, a proteção da confidencialidade, integridade e disponibilidade da informação);
            <br />
            b) Porque a segurança da informação é necessária para proteger as informações e sistemas dos clientes;
            c) Os tipos comuns de ameaças à segurança (como roubo de identidade, malware, hacking, vazamento de informações e ameaça interna);
            <br />
            d) A importância de cumprir as políticas de segurança da informação e aplicar os padrões/procedimentos associados;
            e) Responsabilidade pessoal pela segurança da informação (como proteger as informações relacionadas à privacidade do Cliente e relatar violações de dados reais e suspeitas).
            <br />
            <br />

            <span className="font-weight-bold"> 3. Confidencialidade</span>
            <br />
            <br />
            A) Controle de Acesso (Segurança física e ambiental)
            <br />
            i) A AZUTON DONOMINADA AZUTOMATIZE deverá proteger as instalações de tratamento de informações contra ameaças e riscos externos e ambientais, incluindo falhas de energia/cabeamento e outras interrupções causadas por falhas no suporte aos serviços públicos. Isso inclui perímetro físico e proteção de acesso.
            <br />
            ii) A AZUTON DONOMINADA AZUTOMATIZE deverá proteger os bens contra roubo, manipulação e destruição.
            iii) A AZUTON DONOMINADA AZUTOMATIZE deverá especificar os indivíduos autorizados permitidos em suas instalações de tratamento e ter um processo de controle de acesso.
            <br />
            iv) Medidas adicionais para Data Centers:
            <br />
            a) Todos os Data Centers aderem a procedimentos de segurança rígidos apoiados por guardas, câmeras de vigilância, detectores de movimento, mecanismos de controle de acesso e outras medidas para evitar que os equipamentos e as instalações do data center sejam comprometidos.
            <br />
            b) Apenas representantes autorizados têm acesso aos sistemas e infraestrutura dentro das instalações do Data Center.
            c) Para proteger a funcionalidade adequada, os equipamentos de segurança física (por exemplo, sensores de movimento, câmeras etc.) passam por manutenção regularmente.
            <br />
            d) A AZUTON DONOMINADA AZUTOMATIZE e todos os provedores de Data Centers terceirizados registram os nomes e horários do pessoal autorizado que entra nas áreas privadas da AZUTON DONOMINADA AZUTOMATIZE dentro dos Data Centers.
            B) Controle de acesso (Lógico)
            <br />
            i) A AZUTON DONOMINADA AZUTOMATIZE deverá ter uma política de controle de acesso definida e documentada para instalações, sites, rede, sistema, aplicativo e acesso a informações/dados (incluindo controles de acesso físico, lógico e remoto), um processo de autorização para acesso de usuário e privilégios, procedimentos para revogar acesso direitos e um uso aceitável de privilégios de acesso para o pessoal da AZUTON DONOMINADA AZUTOMATIZE no local.
            <br />
            ii) A AZUTON DONOMINADA AZUTOMATIZE deverá ter um registro de usuário formal e documentado e um processo de cancelamento de registro implementado para permitir a atribuição de direitos de acesso.
            <br />
            iii) A AZUTON DONOMINADA AZUTOMATIZE deverá ter um processo de joiner-mover-leaver para seus funcionários.
            iv) A AZUTON DONOMINADA AZUTOMATIZE deverá atribuir todos os privilégios de acesso com base no princípio da necessidade de tomar conhecimento e no princípio do menor privilégio.
            <br />
            v) A AZUTON DONOMINADA AZUTOMATIZE deverá usar autenticação forte (multifatorial) para usuários de acesso remoto e usuários que se conectam a partir de uma rede não confiável.
            <br />
            vi) A AZUTON DONOMINADA AZUTOMATIZE deverá garantir que o pessoal da AZUTON DONOMINADA AZUTOMATIZE tenha um identificador pessoal e único (ID do usuário) e use uma técnica de autenticação adequada, que confirma e garante a identidade dos usuários.
            <br />
            C) Criptografia/Pseudonimização/Anonimização
            <br />
            i) A AZUTON DONOMINADA AZUTOMATIZE deverá garantir o uso adequado e eficaz da criptografia nas informações classificadas como confidenciais e secretas (como dados pessoais).
            <br />
            ii) A AZUTON DONOMINADA AZUTOMATIZE deverá proteger as chaves criptográficas e armazená-las de acordo com a legislação aplicável.
            <br />
            iii) A AZUTON DONOMINADA AZUTOMATIZE implementará medidas adequadas para pseudonimização (substituição de identificadores pessoais por informações não pessoais) quando adequado
            <br />
            iv) A AZUTON DONOMINADA AZUTOMATIZE implementará medidas adequadas para anonimato (desidentificar identificadores pessoais com informações não pessoais) quando adequado.
            <br />
            D) Diretrizes sobre a admissão nas instalações do Cliente e/ou instalações AZUTON DONOMINADA AZUTOMATIZE
            A autorização de acesso às instalações e propriedades (como edifícios de datacenter, edifícios de escritórios, locais técnicos) está sujeita ao seguinte:
            <br />
            i) A AZUTON DONOMINADA AZUTOMATIZE deverá seguir os regulamentos locais (como regulamentos para &quot;áreas restritas&quot;) para as instalações do Cliente ao realizar as cessões nos termos do Contrato.
            <br />
            ii) O pessoal da AZUTON DONOMINADA AZUTOMATIZE deverá portar carteira de identidade ou, no caso de visitantes, um crachá de visitante, visível o tempo todo durante o trabalho.
            <br />
            iii) Após o emprego ou conclusão da tarefa, ou quando o pessoal da AZUTON DONOMINADA AZUTOMATIZE é transferido para outras tarefas, o pessoal deverá, sem demora, informar o pessoal autorizado da mudança e devolver quaisquer chaves, cartões-chave, certificados, crachás de visitante e itens semelhantes.
            <br />
            iv) Chaves ou cartões-chave deverão ser assinados pessoalmente pelo pessoal da AZUTON DONOMINADA AZUTOMATIZE e deverão ser manuseados de acordo com as regras escritas dadas no recebimento.
            <br />
            v) A perda da chave ou do cartão-chave deverá ser comunicada sem demora ao pessoal autorizado.
            vi) Fotografar dentro ou nas instalações sem permissão é proibido.
            <br />
            vii) Bens não deverão ser removidos das instalações sem permissão.
            <br />
            viii) O pessoal da AZUTON DONOMINADA AZUTOMATIZE não deverá permitir o acesso de pessoas não autorizadas às instalações.
            <br />
            <br />

            <span className="font-weight-bold"> 4. Segurança das operações</span>
            <br />
            <br />
            i) A AZUTON DONOMINADA AZUTOMATIZE deverá ter um sistema de gerenciamento de mudanças estabelecido para fazer mudanças nos processos de negócios, instalações e sistemas de tratamento de informações. O sistema de gerenciamento de mudanças deverá incluir testes e revisões antes que as mudanças sejam implementadas, tais como procedimentos para lidar com mudanças urgentes, procedimentos de reversão para se recuperar de mudanças que falharam, logs que mostram o que foi alterado, quando e por quem.
            <br />
            ii) A AZUTON DONOMINADA AZUTOMATIZE implementará proteção contra malware para garantir que qualquer software usado para o fornecimento dos Serviços da AZUTON DONOMINADA AZUTOMATIZE ao Cliente seja protegido contra malware.
            iii) A rede da AZUTON DONOMINADA AZUTOMATIZE é protegida da rede pública por firewalls.
            <br />
            iv) A AZUTON DONOMINADA AZUTOMATIZE deverá fazer cópias de backup de informações críticas e testar cópias de backup para garantir que as informações possam ser restauradas conforme acordado com o Cliente.
            <br />
            v) A AZUTON DONOMINADA AZUTOMATIZE deverá registrar e monitorar as atividades, como criar, ler, copiar, alterar e excluir os dados tratados, bem como exceções, falhas e eventos de segurança da informação e revisá-los regularmente. Além disso, a AZUTON DONOMINADA AZUTOMATIZE deverá proteger e armazenar (por pelo menos 6 meses ou durante o(s) período(s) definido(s) pela Legislação de Proteção de Dados) informações de registro e, mediante solicitação, fornecer dados de monitoramento ao Cliente. Anomalias/incidentes/indicadores de comprometimento deverão ser reportados de acordo com os requisitos de gestão de violação de dados conforme estabelecido abaixo.
            <br />
            vi) A AZUTON DONOMINADA AZUTOMATIZE deverá gerenciar vulnerabilidades de todas as tecnologias relevantes, como sistemas operacionais, bancos de dados, aplicativos de forma proativa e em tempo hábil.
            <br />
            vii) A AZUTON DONOMINADA AZUTOMATIZE deverá estabelecer linhas de base de segurança (reforço) para todas as tecnologias relevantes, como sistemas operacionais, bancos de dados, aplicativos.
            <br />
            viii) A AZUTON DONOMINADA AZUTOMATIZE deverá garantir que o desenvolvimento seja segregado do ambiente de teste e produção.
            <br />
            <br />

            <span className="font-weight-bold">5. Integridade</span>
            <br />
            <br />
            i) A AZUTON DONOMINADA AZUTOMATIZE deverá implementar controles de segurança de rede, como nível de serviço, firewall e segregação para proteger os sistemas de informação.
            <br />
            ii) SA AZUTON DONOMINADA AZUTOMATIZE opera um sistema de detecção de phishing e SPAM com o objetivo de proteger seus clientes e a AZUTON DONOMINADA AZUTOMATIZE (e os Dados Pessoais dos quais as partes são o Controlador) contra conteúdo indesejado e a propagação de SPAM/phishing e cumprir os requisitos do operador e a legislação aplicável. O sistema recupera a(s) URL(s) do corpo da mensagem de solicitação encerrada por dispositivo móvel e, em seguida, habilita a validação da URL emitindo uma solicitação de método GET para a URL e expandindo para a URL completa como se estivesse na barra de endereço do navegador. Se necessário, devido a informações insuficientes ou suspeita de conteúdo não conforme, toda a página poderá ser carregada e analisada, incluindo o conteúdo dessa página. Este é um algoritmo de machine learning (com validação humana) projetado para aprender com a detecção de phishing e SPAM confirmados e que os dados serão usados para essa finalidade dentro do grupo AZUTON DONOMINADA AZUTOMATIZE. A AZUTON DONOMINADA AZUTOMATIZE não fornecerá nem enviará dados pessoais dos quais o Cliente é o controlador para terceiros fora do Grupo AZUTON DONOMINADA AZUTOMATIZE, exceto para os Suboperadores necessários para fornecer esta funcionalidade.
            <br />
            iii) Os Dados Pessoais tratados em nome deverão ser tratados exclusivamente de acordo com o Contrato e as instruções do controlador para o operador.
            <br />
            iv) A AZUTON DONOMINADA AZUTOMATIZE trabalhará de acordo com instruções escritas ou acordos e documentos pertencentes a esse APD.
            <br />
            <br />

            <span className="font-weight-bold">6. Gerenciamento de violação de dados</span>
            <br />
            <br />
            i) A AZUTON DONOMINADA AZUTOMATIZE deverá ter estabelecido procedimentos para gerenciamento de violação de dados.
            <br />
            ii) A AZUTON DONOMINADA AZUTOMATIZE informará o Cliente sobre qualquer violação de dados (incluindo, mas não se limitando a incidentes relacionados ao tratamento de dados pessoais) o mais rápido possível, mas o mais tardar em 72 horas após a violação de dados ter sido identificada.
            <br />
            iii) Todos os relatórios de incidentes relacionados à segurança deverão ser tratados como informações confidenciais e criptografados, usando métodos de criptografia padrão da indústria.
            <br />
            iv) O relatório de violação de dados deverá conter pelo menos as seguintes informações:
            <br />
            a) A natureza da violação de dados,
            <br />
            b) A natureza dos dados pessoais afetados,
            <br />
            c) As categorias e o número de titulares de dados em questão,
            <br />
            d) O número de registros de dados pessoais em questão
            <br />
            e) As medidas tomadas para lidar com a violação de dados,
            <br />
            f) As possíveis consequências e efeitos adversos da violação de dados, e
            <br />
            g) Qualquer outra informação que o Cliente seja obrigado a relatar ao regulador relevante ou titular dos dados.
            Na medida do legalmente possível, a AZUTON DONOMINADA AZUTOMATIZE poderá reivindicar compensação por serviços de suporte nos termos desta cláusula que não sejam atribuíveis a falhas por parte da AZUTON DONOMINADA AZUTOMATIZE.
            <br />
            <br />

            <span className="font-weight-bold">7. Gestão de Continuidade dos Negócios</span>
            <br />
            <br />
            i) A AZUTON DONOMINADA AZUTOMATIZE deverá identificar os riscos de continuidade dos negócios e tomar as medidas necessárias para controlar e mitigar esses riscos.
            <br />
            ii) A AZUTON DONOMINADA AZUTOMATIZE deverá ter processos e rotinas documentados para lidar com a continuidade dos negócios
            <br />
            iii) A AZUTON DONOMINADA AZUTOMATIZE deverá garantir que a segurança da informação seja incorporada aos planos de continuidade de negócios.
            <br />
            iv) A AZUTON DONOMINADA AZUTOMATIZE deverá avaliar periodicamente a eficiência de sua gestão de continuidade de negócios e a conformidade com os requisitos de disponibilidade (se houver).
            <br />
            <br />
            <br />
            <span className="font-weight-bold">8. Desenvolvimento e manutenção de sistema/software (quando o desenvolvimento de software ou desenvolvimento de sistema é fornecido ao Cliente pela AZUTON DONOMINADA AZUTOMATIZE)</span>
            <br />
            <br />
            i) A AZUTON DONOMINADA AZUTOMATIZE deverá implementar regras para o ciclo de vida de desenvolvimento de software e sistemas, incluindo procedimentos de mudança e revisão
            <br />
            ii) A AZUTON DONOMINADA AZUTOMATIZE deverá testar a funcionalidade de segurança durante o desenvolvimento em um ambiente controlado.
            <br />
            iii) A gestão de patch de segurança é implementada para fornecer implantação regular e periódica de atualizações de segurança relevantes.
            <br />
            iv) A AZUTON DONOMINADA AZUTOMATIZE trabalhará de acordo com os princípios de proteção de dados “by design & by default” e deverá fornecer documentação suficiente da implementação da proteção de dados.
          </p>
        </Modal.Body>
      </Modal>

      <Modal show={showModalSaaS} onHide={() => setShowModalSaaS(false)} className="d-flex align-items-center justify-content-center modals-text">
        <Modal.Header closeButton>
          <Modal.Title><span className="f-5 font-weight-bold color-black">Termos e Condições Suplementares do SaaS</span></Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <p className="f-4 font-weight-light mt-2 text-left">
            <span className="color-green">Versão 1 - Data de atualização: 31 de janeiro de 2023.</span>
          </p>
          <p>
            Estes termos e condições suplementares (&quot;Suplemento&quot;) são parte de um contrato para determinados serviços AZUTON DENOMINADA (AZUTOMATIZE) (&quot;Contrato&quot;) firmado entre AZUTON DENOMINADA (AZUTOMATIZE) e o Cliente e se aplicam exclusivamente aos serviços AZUTON DENOMINADA (AZUTOMATIZE) que fazem referência a este Suplemento, incluindo quaisquer serviços SAAS (&quot;Serviço&quot;) e não qualquer outro produto ou serviço AZUTON DENOMINADA (AZUTOMATIZE).
            <br />
            Os termos em maiúsculas são definidos no Glossário a seguir. Os termos em maiúsculas não definidos neste Suplemento terão os significados que lhes são atribuídos nos Termos e Condições Gerais dos Serviços AZUTON DENOMINADA (AZUTOMATIZE) (&quot;TGS&quot;).
          </p>
          <p className="color-blue f-5">
            TERMOS LEGAIS
          </p>
          <p>
            <span className="font-weight-bold">1 Licença</span>
            <br />
            <br />
            1.1. Além da Seção 2.2 do TGS, a AZUTON DENOMINADA (AZUTOMATIZE) concede ao Cliente uma licença pessoal, restrita, não exclusiva, intransferível e não atribuível para usar o Serviço por meio de seus Usuários do Produto e para disponibilizar o Produto para os Usuários Finais, tanto de acordo com este Contrato quanto com a Documentação Técnica aplicável durante a Prazo de vigência do Contrato.
            <br />
            <br />
            1.2. No que diz respeito às Contas de Teste e se aplicável, o Cliente recebe um direito limitado, revogável, não exclusivo e intransferível de usar o Serviço somente para testes não produtivos e/ou de avaliação e demonstração, durante um período determinado para teste e sujeito ao cumprimento contínuo do Cliente com este Contrato, sendo entendido que aos Clientes não serão cobrados as taxas durante o período de teste. O escopo do direito do Cliente de usar o Serviço será determinado pela AZUTON DENOMINADA (AZUTOMATIZE) a seu exclusivo critério e será limitado aos Recursos e Funcionalidades disponibilizados pela AZUTON DENOMINADA (AZUTOMATIZE) na Conta de Teste do Produto. A AZUTON DENOMINADA (AZUTOMATIZE) sempre terá o direito de alterar o escopo dos direitos do Cliente.
            <br />
            <br />
            <span className="font-weight-bold">2. Créditos Adicionais</span>
            <br />
            <br />
            2.1. Os detalhes sobre os Recursos ou Funcionalidades do Serviço são apresentados na Ordem de Serviço. Se o Cliente desejar fazer uso de Recursos ou Funcionalidades adicionais e disponíveis além do acordado entre o Cliente e a AZUTON DENOMINADA (AZUTOMATIZE), o Cliente pode, a qualquer momento, solicitar a compra de Add-Ons para os quais as Partes devem assinar uma nova Ordem de Serviço. Mediante solicitação, a AZUTON DENOMINADA (AZUTOMATIZE) informará ao Cliente quais crédito adicionais estão disponíveis e os custos aplicáveis.
            <br />
            <br />
            2.2. A AZUTON DENOMINADA (AZUTOMATIZE) a qualquer momento se reserva o direito de cobrar retroativamente quaisquer valores correspondentes ao uso de tais Recursos e Funcionalidades adicionais em excesso dos valores ou volume acordado na Ordem de Serviço relevante. Nesse caso, a AZUTON DENOMINADA (AZUTOMATIZE) enviará uma fatura corretiva, que deverá ser paga de acordo com as disposições pertinentes previstas no Contrato.
            <br />
            <br />
            <span className="font-weight-bold">3. Ativação e Serviços Customizados</span>
            <br />
            <br />
            3.1. A menos que os Serviços Customizados relacionados à ativação, instalação ou configuração do Produto sejam solicitados de acordo com a Seção 3.2 abaixo, o Cliente será responsável por realizar os trabalhos de ativação, instalação, configuração ou desenvolvimento de software necessários para instalar uma versão do Produto e integrar esse Produto nos sistemas do Cliente. Para evitar dúvidas, qualquer instalação ou integração deve estar de acordo com a Documentação Técnica ou quaisquer outras instruções escritas fornecidas pela AZUTON DENOMINADA (AZUTOMATIZE).
            <br />
            <br />
            3.2. O Cliente pode solicitar que a AZUTON DENOMINADA (AZUTOMATIZE) execute determinados Serviços Customizados e a AZUTON DENOMINADA (AZUTOMATIZE) pode, a seu exclusivo critério, concordar em fornecer tais Serviços Customizados. Nesse caso, as Partes firmarão uma Declaração de Trabalho detalhando o escopo dos Serviços Customizados a serem prestados e as taxas aplicáveis, que podem ser por tempo e material ou por preço fixo, conforme determinado pelas Partes. Quaisquer desses Serviços Customizados devem ser fornecidos de acordo com este Contrato, incluindo expressamente a Declaração de Trabalho aplicável.
            <br />
            <br />
            3.3. Os Serviços Customizados serão entregues &quot;como estão&quot; e serão considerados aceitos pelo Cliente no momento da entrega pela AZUTON DENOMINADA (AZUTOMATIZE).
            <br />
            <br />
            <span className="font-weight-bold">4. Dados do Cliente</span>
            <br />
            <br />
            4.1. O Cliente reconhece que o Produto consiste em certos algoritmos e inteligência artificial e que, para um funcionamento adequado e contínuo do Produto, o Cliente é responsável por alimentar o Produto com Dados do Cliente suficientes. Exceto para os Dados do Cliente inseridos pelos Usuários Finais, os Dados do Cliente só podem ser inseridos pelos Usuários do Produto e de acordo com as diretrizes fornecidas na Documentação Técnica.
            <br />
            <br />
            4.2. O Cliente será o único responsável pela exatidão e correção dos Dados do Cliente. A AZUTON DENOMINADA (AZUTOMATIZE) não se responsabiliza por danos ou responsabilidades resultantes de Dados do Cliente incorretos inseridos no Produto.
            <br />
            <br />
            4.3. Todos os Dados do Cliente permanecerão propriedade do Cliente. O Cliente concede à AZUTON DENOMINADA (AZUTOMATIZE) o direito de usar tais Dados do Cliente (incluindo qualquer outro conteúdo e informação fornecida, transmitida ou carregada através do Serviço) para o cumprimento de suas obrigações nos termos do Contrato e para melhorar o funcionamento e prestação do Serviço. Após a rescisão ou expiração do Contrato, se aplicável, a AZUTON DENOMINADA (AZUTOMATIZE) pode reter trechos de texto simples limitados dos Dados do Cliente exclusivamente por razões técnicas relacionadas ao funcionamento do Produto. Esses fragmentos de texto serão mantidos em sigilo absoluto e não serão disponibilizados a terceiros. Se tais fragmentos de texto contiverem Dados Pessoais do Cliente, a AZUTON DENOMINADA (AZUTOMATIZE) deverá anonimizar ou pseudonimizar tais fragmentos de texto o máximo possível.
            <br />
            <br />
            4.4. O Cliente garante à AZUTON DENOMINADA (AZUTOMATIZE) que os Dados do Cliente não infringirão os direitos de propriedade intelectual ou outros direitos legais de terceiros, e não deverão violar as disposições de qualquer lei, estatuto ou regulamento, em qualquer jurisdição e sob qualquer lei aplicável.
            <br />
            <br />
            4.5. O Cliente reconhece e concorda que será o único responsável pelos Dados do Cliente que são distribuídos ou acessíveis por meio do Produto. A AZUTON DENOMINADA (AZUTOMATIZE) não será, em hipótese alguma, responsável ou responsabilizada por quaisquer Dados do Cliente. Em particular, o Cliente declara e garante que nenhum Dado do Cliente distribuído por meio do Produto, de qualquer maneira, a critério exclusivo da AZUTON DENOMINADA (AZUTOMATIZE): (i) viole qualquer lei, estatuto, decreto ou regulamentação aplicável, local, estadual, nacional, regional ou internacional; (ii) seja ilegal, criminoso, enganoso, fraudulento ou qualquer outra ação que seja ilegal, prejudicial, ameaçadora, abusiva, assediadora, torturante, violenta, difamatória, vulgar, obscena, invasiva da privacidade de outros, odiosa racialmente, etnicamente ou de outra forma questionável; (iii) afete adversa ou negativamente o nome, reputação ou ágio da AZUTON DENOMINADA (AZUTOMATIZE).
            <br />
            <br />
            4.6. O Cliente reconhece e concorda que qualquer violação pelo Cliente da disposição desta Seção 4 dará direito à AZUTON DENOMINADA (AZUTOMATIZE), a sua única opção (i) de rescindir imediatamente (ou alternativamente, a critério da AZUTON DENOMINADA (AZUTOMATIZE), recusar ou suspender qualquer acesso e uso do Produto) por violação de material, sem quaisquer formalidades sendo exigidas e sem prejuízo de quaisquer outros direitos ou recursos disponíveis para AZUTON DENOMINADA (AZUTOMATIZE) nos termos deste Contrato ou da legislação aplicável; e/ou (ii) remover ou bloquear imediatamente os respectivos Dados do Cliente.
            <br />
            <br />
            <span className="font-weight-bold">5. Componentes e Hospedagem de Terceiros</span>
            <br />
            <br />
            5.1. O Cliente reconhece e concorda que, para usar o Serviço, ele precisa de hardware, redes, sistemas operacionais, linhas de transmissão de dados adequados com aplicativos e ambientes de comunicação adequados.
            <br />
            <br />
            5.2. O Cliente reconhece e concorda que, para usar o Serviço, pode ser necessário obter, às custas do Cliente, o direito de usar qualquer software de propriedade de terceiros (i) contratando da AZUTON DENOMINADA (AZUTOMATIZE) o direito de usar esse software de terceiros, desde que e na medida em que a AZUTON DENOMINADA (AZUTOMATIZE) tenha o direito de licenciar respectivo software de terceiros para o Cliente, ou (ii) licenciando ou de outra obtendo diretamente dos fornecedores de tal software de terceiros o direito de usar esse software de terceiros. Se o Cliente decidir licenciar o software de terceiros com a AZUTON DENOMINADA (AZUTOMATIZE), essa licença deverá ser executada e anexada à Ordem de Serviço. Qualquer software de terceiros licenciado ou de outra forma incluído no Serviço deve ser regido exclusivamente pelos termos do software de terceiros aplicável e (sem limitação) quaisquer garantias, indenizações e manutenção e suporte fornecidos aqui pela AZUTON DENOMINADA (AZUTOMATIZE) em relação ao Serviço não devem aplicar-se em relação a tal software de terceiros (exceto conforme expressamente acordado de outra forma por escrito pela AZUTON DENOMINADA (AZUTOMATIZE)).
            <br />
            <br />
            5.3. O Produto será hospedado pela AZUTON DENOMINADA (AZUTOMATIZE) nos datacenters dos Parceiros de Hospedagem da AZUTON DENOMINADA (AZUTOMATIZE). O Cliente declara e garante que aceita os termos e condições aplicáveis dos Parceiros de Hospedagem que serão disponibilizados pela AZUTON DENOMINADA (AZUTOMATIZE) ou pelo Parceiro de Hospedagem (conforme aplicável) ao Cliente. O Cliente reconhece e concorda que os Parceiros de Hospedagem retêm o direito de alterar unilateralmente tais termos e condições (incluindo o hiperlink para tais termos e condições).
            <br />
            <br />
            5.4. A AZUTON DENOMINADA (AZUTOMATIZE) não dá nenhuma garantia direta, indireta, explícita ou implícita, de qualquer tipo para fornecer disponibilidade ininterrupta para o Serviço, a menos que indicado em uma Ordem de Serviço.
            <br />
            <br />
            5.5. O Cliente reconhece que os serviços de hospedagem e os Produtos podem não estar disponíveis durante os períodos de manutenção planejada pela AZUTON DENOMINADA (AZUTOMATIZE) ou pelo Parceiro de Hospedagem. Se for razoavelmente viável, qualquer manutenção planejada será realizada fora dos Dias Úteis e a AZUTON DENOMINADA (AZUTOMATIZE) informará o Cliente o mais rápido possível de qualquer manutenção planejada.
            <br />
            <br />
            5.6. A AZUTON DENOMINADA (AZUTOMATIZE) e o Parceiro de Hospedagem reservam-se o direito de realizar qualquer manutenção não planejada a qualquer momento, se necessário por razões de segurança ou outras razões que requeiram manutenção imediata. A AZUTON DENOMINADA (AZUTOMATIZE) ou o Parceiro de Hospedagem não serão responsabilizados por quaisquer danos resultantes de tal indisponibilidade do Serviço.
            <br />
            <br />
            <span className="font-weight-bold">6. Serviços de Manutenção</span>
            <br />
            <br />
            6.1. A partir da Data de Vigência estabelecida na Ordem de Serviço e sujeito ao pagamento pontual de todas as taxas aplicáveis, a AZUTON DENOMINADA (AZUTOMATIZE) fornecerá Serviços de Manutenção de terceira linha em relação ao Produto no ambiente de produção do Cliente de acordo com as disposições estabelecidas abaixo.
            <br />
            <br />
            6.2. Se um Incidente for encontrado pelo Cliente, o Cliente deve primeiro revisar e avaliar o Incidente. Se tal avaliação mostrar que o Incidente não é um Incidente Fora do Escopo, um Usuário do Produto pode notificar a AZUTON DENOMINADA (AZUTOMATIZE) de tal Incidente usando um sistema de chamados baseado na web (como &quot;Jira&quot; e &quot;Slack&quot;, conforme disponibilizado pela AZUTON DENOMINADA (AZUTOMATIZE)) e o Usuário do Produto deve especificar os detalhes do Incidente (no formato solicitado pela AZUTON DENOMINADA (AZUTOMATIZE)). Após o recebimento de um chamado, a AZUTON DENOMINADA (AZUTOMATIZE) pode, a seu exclusivo critério, determinar se o Incidente é um Incidente Fora do Escopo ou não, e a classificação da AZUTON DENOMINADA (AZUTOMATIZE) será vinculativa e final. A AZUTON DENOMINADA (AZUTOMATIZE) reserva-se o direito de cobrar do Cliente quaisquer custos que sejam feitos em relação à investigação da natureza de um Incidente com base no tempo e material.
            <br />
            <br />
            6.3. Serviços de Manutenção para Incidentes Fora do Escopo não estão incluídos nas taxas estabelecidas na Ordem de Serviço, no entanto, os Usuários do Produto têm o direito de fazer qualquer pergunta sobre o Serviço e a AZUTON DENOMINADA (AZUTOMATIZE) deve responder a qualquer solicitação do Usuário do Produto de boa-fé, sem qualquer compromisso vinculativo.
            <br />
            <br />
            6.4. Para Incidentes que não são um Incidente Fora do Escopo, a AZUTON DENOMINADA (AZUTOMATIZE) deve envidar seus melhores esforços para resolver o Incidente de acordo com os objetivos de nível de serviço estabelecidos como um apêndice anexado a Ordem de Serviço. Para evitar dúvidas, a AZUTON DENOMINADA (AZUTOMATIZE) pode, a seu exclusivo critério, atribuir um nível de prioridade para cada Incidente.
            <br />
            <br />
            6.5. Para Incidentes de prioridade 1 e prioridade 2 (um &quot;Incidente Grave&quot;), o Cliente deve nomear um (1) Usuário do Produto que atuará como um único ponto de contato (SPOC) para a AZUTON DENOMINADA (AZUTOMATIZE).
            <br />
            <br />
            6.6. Incidentes causados por um dos seguintes itens não estão incluídos nos Serviços de Manutenção, no entanto, o Cliente pode solicitar que a AZUTON DENOMINADA (AZUTOMATIZE) forneça Serviços Customizados em relação a tais incidentes, pelos quais a AZUTON DENOMINADA (AZUTOMATIZE) pode cobrar taxas adicionais (não exaustivas) (i) Incidentes Fora do Escopo; (ii) abuso ou uso indevido do Produto; (iii) modificação ou adição ao Produto não realizada por ou com o consentimento da AZUTON DENOMINADA (AZUTOMATIZE); (iv) instalação incorreta de uma correção por uma parte diferente da AZUTON DENOMINADA (AZUTOMATIZE); (v) configuração incorreta não realizada por ou com o consentimento da AZUTON DENOMINADA (AZUTOMATIZE); (vi) o uso de dados ou estruturas de dados incorretos; (vii) qualquer instalação diferente de uma versão com suporte; (viii) qualquer outro ambiente que não seja o ambiente de produção do Cliente.
            <br />
            <br />
            6.7. As disposições desta Seção não se aplicam a Contas de Teste.
            <br />
            <br />
            <span className="font-weight-bold">7. Direitos de Propriedade Intelectual</span>
            <br />
            <br />
            7.1. Além da disposição na Seção 10.1 do TGS, a AZUTON DENOMINADA (AZUTOMATIZE) é e permanece a única e exclusiva proprietária de todos os Direitos de Propriedade Intelectual relacionados ao Produto e ao Serviço (incluindo quaisquer novas versões, atualizações, personalizações, aprimoramentos, modificações ou melhorias feitas ao Produto ou ao Serviço). Exceto para a licença limitada concedida de acordo com este Suplemento, nenhum outro direito em relação aos Direitos de Propriedade Intelectual da AZUTON DENOMINADA (AZUTOMATIZE) deve ser concedido ou transferido para o Cliente em conexão com este Contrato. Nada neste Contrato deve transmitir qualquer título ou direito de propriedade ou Direitos de Propriedade Intelectual no ou sobre o Produto ou Serviço para o Cliente ou qualquer terceiro. O Cliente não deve, de forma alguma, adquirir qualquer título, direito de propriedade, direitos autorais, Direitos de Propriedade Intelectual ou outros direitos de propriedade de qualquer natureza no Produto ou Serviço. O Cliente concorda em não remover, suprimir ou modificar de qualquer forma qualquer marca proprietária, incluindo qualquer marca comercial ou aviso de direitos autorais, sobre ou no Produto, ou visível durante sua operação ou na mídia. O Cliente deve incorporar ou reproduzir tais marcações proprietárias em qualquer backup permitido ou outras cópias.
            <br />
            <br />
            7.2.  O disposto na Seção 7.1 acima e na Seção 10.1 do TGS em relação aos direitos de propriedade intelectual e relacionados ao Serviço aplicam-se igualmente aos Serviços Customizados e a qualquer nova versão relacionada, atualizações, personalizações, aperfeiçoamentos, modificações ou melhorias feitas ou relacionadas aos Serviços Customizados.
            <br />
            <br />
            7.3. O Cliente concorda em não remover, suprimir ou modificar de qualquer forma qualquer marca proprietária, incluindo qualquer marca comercial ou aviso de direitos autorais, sobre ou no Serviço, ou visível durante sua operação ou na mídia. O Cliente deve incorporar ou reproduzir tais marcações proprietárias em qualquer backup permitido ou outras cópias.
            <br />
            <br />
            <span className="font-weight-bold">8. Reclamações de Terceiros</span>
            <br />
            <br />
            O Cliente deverá indenizar, defender e isentar a AZUTON DENOMINADA (AZUTOMATIZE) de e contra quaisquer danos, perdas, custos e despesas (incluindo honorários advocatícios razoáveis) sofridos ou incorridos pela AZUTON DENOMINADA (AZUTOMATIZE) (incluindo suas Afiliadas, agentes, contratados, diretores, funcionários ou representantes) decorrentes de ou relacionadas aos Dados do Cliente, incluindo (sem limitação) a violação do Cliente ou dos Usuários Finais de quaisquer direitos de propriedade intelectual de terceiros ou quaisquer outros direitos de tais terceiros.
            <br />
            <br />
            <span className="font-weight-bold">9. Rescisão</span>
            <br />
            <br />
            A cláusula na Seção 6.3 do TGS se aplica igualmente no caso de o Cliente violar os termos e condições do Parceiro de Hospedagem ou do software de terceiros aplicável.
            <br />
            <br />
            <span className="font-weight-bold">10. Isenção de Responsabilidade.</span>
            <br />
            <br />
            A isenção de responsabilidade na Seção 7.5 do TGS em relação ao Serviço se aplica igualmente aos Serviços Customizados.
            <br />
            <br />
            <span className="font-weight-bold">11. Limitação de Responsabilidade</span>
            <br />
            <br />
            Além da Seção 9.3 do TGS, a AZUTON DENOMINADA (AZUTOMATIZE) não deve ser responsabilizada de nenhuma forma, nem contratualmente nem extracontratualmente, pela descontinuação de uma liberação anterior do Produto ou por danos causados pelo uso indevido (ou fora do escopo) do Produtos.
            <br />
            <br />
            <span className="font-weight-bold">12. Sobrevivência</span>
            <br />
            <br />
            As disposições deste Suplemento que expressamente ou implicitamente se destinam a sobreviver à rescisão, sobreviverão à expiração ou rescisão do Contrato.
            <br />
            <br />
            Glossário
            Neste Suplemento, a menos que especificado de outra forma, as seguintes definições se aplicam:
            <br />
            <br />
            1.1 &quot;Add-On&quot; significa um acréscimo de um determinado Recurso ou Funcionalidade que não está incluído na Ordem de Serviço aplicável. A AZUTON DENOMINADA (AZUTOMATIZE) reserva-se o direito de, a qualquer momento, determinar qual Recurso ou Funcionalidade específico poderá oferecer separadamente. Mediante solicitação, a AZUTON DENOMINADA (AZUTOMATIZE) deve informar o Cliente sobre os Add-Ons disponíveis.
            <br />
            <br />
            1.2 &quot;Conta de Teste&quot; significa uma conta com um escopo limitado de Recursos ou Funcionalidades (conforme disponibilizado pela AZUTON DENOMINADA (AZUTOMATIZE) a seu exclusivo critério) para a qual um Cliente se inscreve gratuitamente, mas que está disponível apenas durante um período de teste e apenas para uso limitado Serviço (conforme estabelecido no Contrato).
            <br />
            <br />
            1.3 &quot;Dados do Cliente&quot; significa todos os dados de propriedade ou mantidos pelo Cliente que são inseridos ou carregados pelo Cliente, os Usuários do Produto ou os Usuários Finais ao usar o Produto e conforme processado ou armazenado pela AZUTON DENOMINADA (AZUTOMATIZE) como resultado do Cliente, o Usuário do Produto ou o Usuário Final que usa o Produto. Os Dados do Cliente também devem incluir (i) dados de saída resultantes do processamento pelo Produto dos dados inseridos pelo Cliente, o Usuário do Produto ou o Usuário Final, e (ii) metadados coletados pela AZUTON DENOMINADA (AZUTOMATIZE) relacionados ao uso dos resultados do Produto (como taxas de cliques ou número de vezes que determinados resultados foram clicados pelos Usuários Finais). Para evitar dúvidas, os Dados do Cliente não incluem dados gerados pelo Produto ou Serviço.
            <br />
            <br />
            1.4 &quot;Data de Vigência&quot; significa a data de vigência conforme estabelecido na Ordem de Serviço.
            <br />
            <br />
            1.5 &quot;Declaração de Trabalho&quot; significa um documento escrito que as partes podem celebrar de tempos em tempos, descrevendo os Serviços Customizados que a AZUTON DENOMINADA (AZUTOMATIZE) deve fornecer em conexão com o serviço.
            <br />
            <br />
            1.6 &quot;Dia Útil&quot; significa um dia normal de trabalho da AZUTON DENOMINADA (AZUTOMATIZE) das 8h30 às 17h30, de segunda a sexta-feira, exceto feriados públicos relevantes.
            <br />
            <br />
            1.7 &quot;Incidente&quot; significa mau funcionamento do Produto.
            <br />
            <br />
            1.8 &quot;Incidente Fora de Escopo&quot; significa um Incidente que não é causado nem relacionado ao Produto, como (sem limitação) Incidentes devido ao mau funcionamento de interfaces, Incidentes causados por formatos de dados não suportados e Incidentes causados por problemas em software de terceiros.
            <br />
            <br />
            1.9 &quot;Parceiro de Hospedagem&quot; significa qualquer provedor de serviços de hospedagem que a AZUTON DENOMINADA (AZUTOMATIZE) possa contratar no futuro, conforme será notificado ao Cliente de tempos em tempos.
            <br />
            <br />
            1.10 &quot;Produto&quot; significa o Produto conforme identificado na Ordem de Serviço.
            1.11 &quot;Recursos ou Funcionalidades&quot; significa os recursos ou funcionalidades disponíveis no Produto, conforme incluído na Ordem de Serviço aplicável, como (sem limitação) número de chatbots, número de bots de teste, número de documentos, número de Usuários do Produto, volumes de mensagens, comunicação canal através do qual os Produtos podem ser disponibilizados aos Usuários Finais e qualquer outra variável estabelecida na Ordem de Serviço. A AZUTON DENOMINADA (AZUTOMATIZE) reserva-se o direito de adicionar ou remover Recursos ou Funcionalidades a qualquer momento.
            <br />
            <br />
            1.12 &quot;Serviço&quot; significa o fornecimento do Produto pela AZUTON DENOMINADA (AZUTOMATIZE) e a Documentação Técnica aplicável, conforme descrito na Ordem de Serviço.
            <br />
            <br />
            1.13 &quot;Serviços Customizados&quot; significa serviços de desenvolvimento, implementação e integração (incluindo, mas não se limitando a personalizações), Serviços de Manutenção ou outros serviços em relação ao Produto a ser entregue pela AZUTON DENOMINADA (AZUTOMATIZE) ao Cliente, conforme acordado entre as Partes de tempos em tempo e estabelecido na Ordem de Serviço e/ou na Declaração de Trabalho.
            <br />
            <br />
            1.14 &quot;Serviços de Manutenção&quot; são os serviços de manutenção e suporte relacionados ao Produto fornecidos pela AZUTON DENOMINADA (AZUTOMATIZE) ao Cliente, conforme descrito na Seção 6.
            <br />
            <br />
            1.15 &quot;Tempo de Resposta&quot; significa o período a partir do momento em que a AZUTON DENOMINADA (AZUTOMATIZE) investigou um Incidente e o Relatório de Incidente fornecido pelo Cliente e termina quando a AZUTON DENOMINADA (AZUTOMATIZE) forneceu uma confirmação por escrito de que o Relatório de Incidente foi recebido e compreendido.
            <br />
            <br />
            1.16 &quot;Tempo de Resolução&quot; significa o período que começa no final do Tempo de Resposta e termina quando uma resolução para um Incidente é fornecida pela AZUTON DENOMINADA (AZUTOMATIZE) ao Cliente.
            <br />
            <br />
            1.17 &quot;Usuário Final&quot; significa qualquer indivíduo para quem o Produto será disponibilizado pelo Cliente e que será capaz de usar o Produto como um usuário final, por exemplo, o Usuário Final do Produto disponível em um canal como web, mensagem ou telefone.
            <br />
            <br />
            1.18 &quot;Usuário do Produto&quot; significa uma pessoa física na organização do Cliente (funcionários e contratados) que tem acesso ao Produto pelo Cliente com a finalidade de (i) usar o Produto, alimentando-o com conteúdo (como, sem limitação, Dados do Cliente) para usar o Produto e (ii) receber Serviços de Manutenção. A quantidade de Usuários do Produto é estritamente limitada à quantidade correspondente ao Plano de Assinatura aplicável, conforme identificado na Ordem de Serviço.
          </p>
        </Modal.Body>
      </Modal>

      <Modal show={showModalSMS} onHide={() => setShowModalSMS(false)} className="d-flex align-items-center justify-content-center modals-text">
        <Modal.Header closeButton>
          <Modal.Title><span className="f-5 font-weight-bold color-black">Termos e Condições Suplementares de Mensageria - SMS</span></Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <p className="f-4 font-weight-light mt-2 text-left">
            <span className="color-green">Versão 1 - Data de atualização: 03 de março de 2023.</span>
          </p>
          <p>
            Estes termos e condições suplementares (&quot;Suplemento&quot;) são parte de um contrato para determinados serviços AZUTON DENOMINADA (AZUTOMATIZE) (&quot;Contrato&quot;) firmado entre AZUTON DENOMINADA (AZUTOMATIZE) e o Cliente e se aplicam exclusivamente aos serviços AZUTON DENOMINADA (AZUTOMATIZE) que fazem referência a este Suplemento, incluindo serviços de Short Message Service (SMS) e/ou Multi-media Messaging Service (MMS) (&quot;Serviço&quot;) e não a qualquer outro produto ou serviço AZUTON DENOMINADA (AZUTOMATIZE).
            <br />
            <br />
            Os termos em maiúsculas são definidos no Glossário a seguir. Os termos em maiúsculas não definidos neste Suplemento terão os significados que lhes são atribuídos nos Termos e Condições Gerais dos Serviços AZUTON DENOMINADA (AZUTOMATIZE) (&quot;TCG&quot;).
          </p>
          <p>
            <span className="color-blue text-uppercase f-5">Parte A – Termos Legais</span>
            <br />
            <br />
            <span className="font-weight-bold">A1. Serviços</span>
            <br />
            <br />
            A1.1. Condições do Provedor de Serviços, leis e regulamentos.
            Não obstante a Seção 4.1 do TCG, o Cliente deverá cumprir todas as Condições do Provedor de Serviços, requisitos e condições legais e regulamentares aplicáveis e todas as diretrizes aplicáveis da indústria relacionadas aos Dados do Cliente, Serviços do Cliente, Mensagens ou seu uso do Serviço. Se um Provedor de Serviço alterar as Condições do Provedor de Serviço e/ou os padrões técnicos para a entrega do Serviço, a AZUTON DENOMINADA (AZUTOMATIZE) poderá modificar os termos e condições que regem esse Serviço mediante notificação por escrito ao Cliente.
            <br />
            <br />
            A1.2. Requisitos de participação/não-participação do Usuário Final.
            O Cliente deverá enviar Mensagens apenas para Usuários Finais que consentiram, e continuarem a consentir conscientemente ou optar por recebê-las (“opt-in”) e que foram informados de seus direitos, conjuntamente com um processo gratuito e prontamente acessível para cancelar o recebimento de Mensagens futuras, como por exemplo, através de respostas de texto do Usuário Final PARAR, SAIR, CANCELAR, RECUSAR e/ou CANCELAR ASSINATURA. O Cliente deverá interromper imediatamente o envio de Mensagens para um Usuário Final que optou por não receber tais Mensagens (“opt-out”).  A pedido da AZUTON DENOMINADA (AZUTOMATIZE), o Cliente deverá fornecer a prova de todo e qualquer pedido de opt-in(s) e/ou opt-out(s), bem como o tempo de resposta para interromper a transmissão de Mensagens após um pedido de opt-out, para a satisfação razoável da AZUTON DENOMINADA (AZUTOMATIZE).
            <br />
            <br />
            A1.3. Fornecimento dos Serviços do Cliente.
            O Cliente deverá informar aos Usuários Finais que é a fonte e o fornecedor das Mensagens e fornecer os detalhes de contato adequados aos Usuários Finais. O Cliente será o único responsável pelos atos, omissões ou violações dos Usuários Finais com relação ao uso do Serviço e das Mensagens, incluindo, sem limitação, os Dados do Cliente. O Cliente deverá informar imediatamente a AZUTON DENOMINADA (AZUTOMATIZE) se tomar conhecimento de qualquer violação dos termos deste parágrafo.
            <br />
            <br />
            A1.4. Restrições.
            <br />

            O Cliente não deverá usar o Serviço ou permitir que o Serviço seja usado:
            <br />
            a) de uma maneira que viole qualquer lei aplicável, regulamento, diretrizes da indústria ou código de prática, ou que viole, infrinja ou se aproprie indevidamente dos direitos de terceiros;
            <br />
            b) para transmitir qualquer Mensagem ou qualquer material eletrônico (incluindo vírus ou outras rotinas de programação de computador destrutivas semelhantes) que cause, ou provavelmente possa causar, detrimento ou dano à Rede AZUTON DENOMINADA (AZUTOMATIZE) ou qualquer sistema de computador ou equipamento de telecomunicações ou telefones móveis de propriedade da AZUTON DENOMINADA (AZUTOMATIZE) ou qualquer outra pessoa, ou para facilitar a transmissão ou uso de qualquer código que permita a terceiros interferir ou acessar quaisquer Dados do Cliente;
            <br />
            c) para enviar spam, &quot;lixo eletrônico&quot; ou publicidade não solicitada ou Mensagens ou material promocional, ou para enviar ou receber ou usar intencionalmente qualquer Mensagem ou material que seja obsceno, abusivo, ofensivo, enganoso, fraudulento, violento, antiético, indecente, difamatório, discriminatório, ameaçador, calunioso, ilegal ou ameaçador ou promova o abuso de álcool ou o uso de drogas ilegais; ou
            <br />
            d) para enviar quaisquer Mensagens que contenham informações médicas e/ou de emergência com risco de vida se tal Mensagem for o único canal de transmissão para o Usuário Final com relação a tal emergência.
            <br />
            <br />
            <span className="font-weight-bold">A2. Conformidade</span>
            <br />
            <br />
            A2.1. Requisitos
            <br />
            As disposições das Seções 2.5 e 4.1(c) do TCG se aplicam igualmente as solicitações, instruções e/ou aos pedidos feitos ou emitidos por qualquer Provedor de Serviços.
            <br />
            <br />
            A2.2. Responsabilidade.
            <br />
            A AZUTON DENOMINADA (AZUTOMATIZE) poderá, a seu exclusivo critério, exigir que o Cliente assuma a responsabilidade primária por qualquer solicitação ou consulta feita na Seção A2.1 acima em relação a quaisquer Serviços do Cliente, Mensagens ou Dados do Cliente ou decorrentes do uso do Cliente do Serviço ou de uma violação potencial ou real do Contrato pelo Cliente.
            <br />
            O Cliente concorda, às suas próprias custas, em aceitar total responsabilidade por qualquer solicitação ou consulta, incluindo por quaisquer despesas, penalidades, multas, sanções ou outros custos análogos que possam surgir, e em relação a tal solicitação ou consulta, fornecer todas as informações solicitadas a tal regulador ou Provedor de Serviços (com cópias para AZUTON DENOMINADA (AZUTOMATIZE)) em tempo hábil.
            <br />
            <br />
            A2.3. Efeito da Rescisão.
            <br />
            As Seções A1.2 (&quot;Requisitos de participação/não participação do Usuário Final&quot;) e A1.3 (&quot;Fornecimento de Serviços ao Cliente&quot;) acima deverão sobreviver a rescisão do Contrato
            <br />
            <br />
            A2.4. Indenização.
            <br />
            O Cliente indenizará e, por opção da AZUTON DENOMINADA (AZUTOMATIZE), defenderá a AZUTON DENOMINADA (AZUTOMATIZE), suas Afiliadas e subcontratadas contra:
            <br />
            (a) quaisquer reivindicações (incluindo, sem limitação, quaisquer reivindicações, multas, penalidades ou perdas) ou outras responsabilidades movidas contra a AZUTON DENOMINADA (AZUTOMATIZE), suas Afiliadas e subcontratadas por qualquer terceiro (incluindo, sem limitação, qualquer outro cliente da AZUTON DENOMINADA (AZUTOMATIZE), Provedor de Serviços ou autoridade governamental ou reguladora) relacionada aos Dados do Cliente, Serviços do Cliente, Mensagens ou uso do Serviço pelo Cliente (incluindo, sem limitação, qualquer reclamação relacionada à violação ou apropriação indevida de quaisquer direitos de propriedade intelectual de terceiros ou qualquer violação de leis ou regulamentos); e
            <br />
            (b) todo pagamento de indenização deferido e transitado em julgado contra a AZUTON DENOMINADA (AZUTOMATIZE), suas Afiliadas e subcontratadas com relação a essas reivindicações.
            <br />
            <br />
            A2.5. Isenção de responsabilidade.
            <br />
            O Cliente concorda que, no que diz respeito ao fornecimento do Serviço, nem a AZUTON DENOMINADA (AZUTOMATIZE) nem qualquer fornecedor da AZUTON DENOMINADA (AZUTOMATIZE) será responsável, seja por obrigação contratual, legal ou direta perante o Cliente ou qualquer Usuário Final ou qualquer outro cliente do Cliente por (i) quaisquer Mensagens excluídas ou não entregues, independentemente do motivo da exclusão ou não entrega, incluindo, sem limitação, erros de processamento de mensagens, erros de transmissão ou rede de mensagens e/ou falhas de serviço; ou (ii) a precisão das informações fornecidas por meio do Serviço.
          </p>
          <p>
            <span className="color-blue text-uppercase f-5">Parte B – Termos Comerciais</span>
            {' '}
            <br />
            <br />
            <span className="font-weight-bold">B1. Responsabilidades do Cliente</span>
            <br />
            <br />
            B1.1. Acesso e Conectividade
            <br />
            <br />
            (a) Conexão de Rede AZUTON DENOMINADA (AZUTOMATIZE).
            <br />
            O Cliente é o único responsável, as próprias custas, pelo estabelecimento e manutenção de seus equipamentos, software, instalações e sua conexão à Rede AZUTON DENOMINADA (AZUTOMATIZE).
            <br />
            <br />
            (b) AZUTON DENOMINADA (AZUTOMATIZE) Dashboard.
            <br />
            A AZUTON DENOMINADA (AZUTOMATIZE) poderá, a seu exclusivo critério, fornecer ao Cliente acesso à interface da extranet da world wide web (&quot;AZUTON DENOMINADA (AZUTOMATIZE) Dashboard&quot;) cujo acesso permitirá ao Cliente obter relatórios estatísticos detalhando a atividade do Serviço. O acesso ao AZUTON DENOMINADA (AZUTOMATIZE) Dashboard é fornecido ao Cliente apenas para fins informativos, e os dados e informações exibidos são fornecidos sem garantias de qualquer tipo quanto a integridade ou precisão dos mesmos. O Cliente concorda em não basear-se ou confiar em tais dados e informações na contabilização de valores devidos entre as Partes ou para fins de cobrança.
            <br />
            <br />
            (c) Números de Acesso.
            <br />
            O Cliente reconhece que os Números de Acesso usados para fornecer os Serviços ao Cliente serão, em todo momento, propriedade e estarão sujeitos aos termos e condições aplicáveis impostos pela entidade autorizada a administrar tais Números de Acesso. Os Números de Acesso a serem usados para fornecer o Serviço para um Serviço do Cliente poderão, a critério exclusivo da AZUTON DENOMINADA (AZUTOMATIZE), ser obtidos pela AZUTON DENOMINADA (AZUTOMATIZE) para o Cliente, se solicitado pelo Cliente mediante a Ordem de Serviço aplicável e sujeito à disponibilidade.  O Cliente não poderá utilizar os Números de Acesso, exceto em relação à prestação do Serviço realizada pela AZUTON DENOMINADA (AZUTOMATIZE).
            <br />
            <br />
            (d) Previsões de Tráfego.
            <br />
            O Cliente deverá fornecer à AZUTON DENOMINADA (AZUTOMATIZE) previsões periódicas e precisas dos volumes máximos de mensagens propostos, incluindo horários de pico, volumes mensais e trimestrais. Quando o tráfego real de Mensagens exceder cento e vinte por cento (120%) do volume previsto (&quot;Estouro&quot;), a AZUTON DENOMINADA (AZUTOMATIZE) poderá, sem qualquer responsabilidade para o Cliente, suspender imediatamente o Serviço. Sempre que o Cliente não enviar tais previsões, a AZUTON DENOMINADA (AZUTOMATIZE) poderá, a seu exclusivo critério, determinar o volume de Mensagens que constitui um Estouro.
            <br />
            <br />
            B1.2. Mensagens Comerciais.
            <br />
            O Cliente reconhece que o Serviço se destina exclusivamente a Mensagens Comerciais e não deverá ser usado para fins de mensagens entre usuários de dispositivos móveis. Para os fins desta Seção, &quot;Mensagens Comerciais&quot; significa Mensagens com o intuito a promover ou facilitar, direta ou indiretamente, os bens, serviços ou imagem de qualquer pessoa ou entidade que exerça uma atividade comercial.
            <br />
            <br />
            <span className="font-weight-bold">B2. Termos Comerciais</span>
            <br />
            <br />
            B2.1. Aumentos de Preço.
            <br />
            Salvo acordo em contrário firmado em uma Ordem de Serviço, a AZUTON DENOMINADA (AZUTOMATIZE) poderá modificar quaisquer taxas a seu exclusivo critério mediante notificação por escrito.
          </p>
          <p>
            <span className="color-green f-5">Glossário</span>
            <br />
            <br />
            1.1 &quot;Número de Acesso&quot; significa um conjunto de dígitos usados para permitir que o Cliente envie e/ou receba Mensagens como parte de um Serviço do Cliente. Os Números de Acesso deverão incluir &apos;códigos curtos&apos; e &apos;códigos longos&apos;, conforme aplicável.
            <br />
            <br />
            1.2 &quot;Serviço(s) do Cliente&quot; significa um programa ou iniciativa de marketing, publicidade, promocional ou informativa ou outro projeto conduzido pelo Cliente utilizando o Serviço.
            <br />
            <br />
            1.3 &quot;Usuário Final&quot; significa uma pessoa ou entidade que é um usuário e/ou destinatário de um Serviço do Cliente.
            <br />
            <br />
            1.4 &quot;Mensagem&quot; significa uma mensagem digital que contém Dados do Cliente a ser entregue por tecnologia Short Message Service (SMS), Multi-Media Messaging Service (MMS), Internet Protocol (IP) ou outra tecnologia, protocolos ou padrões usados para transmitir conteúdo digital ou informações por dispositivos móveis.
            <br />
            <br />
            1.5 &quot;Provedor de Serviços&quot; significa qualquer entidade, incluindo, sem limitação, operadoras de rede móvel e agregadores de mensagens móveis, que é usada em relação ao fornecimento do Serviço.
            <br />
            <br />
            1.6 &quot;Condições do Provedor de Serviços&quot; significa as taxas, termos e condições e &quot;códigos de conduta&quot; ou regra de conteúdo das mensagens e restrições impostas pelos Provedores de Serviço envolvidos na prestação do Serviço e realização dos Serviços ao Cliente como condição para permitir que a AZUTON DENOMINADA (AZUTOMATIZE) forneça o Serviço e que o Cliente realize os Serviços ao Cliente, incorporando tais Condições do Provedor de Serviço aos termos deste Contrato ou conforme indicado pela AZUTON DENOMINADA (AZUTOMATIZE) ao Cliente por escrito ou por e-mail periodicamente.
          </p>
          <p>
            <span className="color-blue text-uppercase f-5">
              Acordo de Nível de Serviço de Mensageria (SLA)
            </span>
            {' '}
            <br />
            <br />
            <span className="color-green">
              Versão 1 - Data de atualização: 03 de março de 2023.
            </span>
            {' '}
            <br />
            <br />
            Este Acordo de Nível de Serviço de Mensageria (&quot;SLA&quot;) é parte de um contrato para determinados serviços AZUTON DENOMINDA (AZUTOMATIZE) (&quot;Contrato&quot;) firmado entre a AZUTON DENOMINDA (AZUTOMATIZE) e o Cliente e se aplica somente aos serviços de mensageria e não a qualquer outro produto ou serviço AZUTON DENOMINDA (AZUTOMATIZE).
            {' '}
            <br />
            <br />
            Os termos em maiúsculas são definidos no Glossário a seguir. Os termos em maiúsculas não definidos neste SLA terão os significados a eles atribuídos nos Termos e Condições Gerais para os Serviços AZUTON DENOMINDA (AZUTOMATIZE) (&quot;TGC&quot;).
            <br />
            <br />
            <span className="font-weight-bold">I - Disposições Gerais</span>
            A AZUTON DENOMINDA (AZUTOMATIZE) disponibilizará o Serviço ao Cliente conforme especificado na Ordem de Serviço e no Contrato e de acordo com o nível de serviço descrito neste SLA.
            <br />
            <br />
            <span className="font-weight-bold">II - Serviço Disponível</span>
            A AZUTON DENOMINDA (AZUTOMATIZE) envidará todos os esforços razoáveis para que o Serviço esteja disponível 99,95% do tempo, na forma e com as exceções estabelecidas abaixo. A disponibilidade do Serviço será calculada a cada mês, como uma porcentagem com base na fração abaixo:
            <br />
            <br />
            Período de Medição – Indisponibilidade
            <br />
            Período de Medição
            <br />
            <br />
            <span className="font-weight-bold">III - Janelas de Serviço</span>
            Notificações sobre janelas de serviço programadas (exceto manutenção não planejada/de emergência) serão enviadas com, no mínimo, 10 dias úteis de antecedência. As janelas de serviço padrão são:
            Locais dos EUA: Terça e quinta 1h – 4h PST/PDT
            Outros locais: Terça e quinta-feira, 21h – 12h CET/CEST
            As janelas de serviço podem ser estendidas ou reduzidas conforme necessário e podem cair em horários ou dias diferentes, conforme necessário.
            <br />
            <br />
            <span className="font-weight-bold">IV - Latência de SMS</span>
            A latência de entrega de Conteúdo SMS varia entre os destinos e depende do status do aparelho. A AZUTON DENOMINDA (AZUTOMATIZE) irá prontamente acusar o recebimento do Conteúdo após o recebimento efetivo. A AZUTON DENOMINDA (AZUTOMATIZE) mede a latência em todo o tráfego por mês enviado para aparelhos válidos e disponíveis. O aumento da latência devido a motivos fora do controle da AZUTON DENOMINDA (AZUTOMATIZE), como interrupções ou sobrecarga do provedor de serviços, são excluídos.
          </p>
          <img src={Modal1} className="mb-5" />
          <p>
            <span className="font-weight-bold">V - Help Desk e Relatórios de Falhas</span>
            <br />
            O Help Desk está disponível 24 horas por dia, 7 dias por semana.
            <br />
            O Cliente pode entrar em contato com o Help Desk da AZUTON DENOMINDA (AZUTOMATIZE) através do Service Center (contato@azutomatize.com.br) para relatar falhas, de acordo com o Método de Relatório de Falhas estabelecido na Seção 7. Os relatórios de falha devem incluircontato@azutomatize.com.br
            <br />
            • Natureza da culpa
            <br />
            • Nível de prioridade de acordo com este SLA. A AZUTON DENOMINDA (AZUTOMATIZE) pode reclassificar a prioridade durante a investigação da falha.
            <br />
            • Números de amostra, CDRs ou outros dados, se aplicável
            <br />
            • Hora da falha
          </p>
          <img src={Modal2} className="mb-5" />
          <p>
            <span className="font-weight-bold">VII - Resposta a Falhas</span>
            <br />
            A AZUTON DENOMINDA (AZUTOMATIZE) deverá utilizar esforços comercialmente razoáveis para fornecer um mínimo de 90% dos serviços de desempenho de resposta a falhas abaixo mencionados de acordo com os seguintes tempos e metas de precisão
          </p>
          <img src={Modal3} className="mb-5" />
          <p>
            <span className="color-green f-5">Glossário</span>
            <br />
            <br />
            1.1 &quot;Disponibilidade&quot; significa a porcentagem do &quot;Período de Medição&quot; durante o qual os &quot;Serviços&quot; AZUTON DENOMINDA (AZUTOMATIZE) são disponibilizados ao Cliente.
            <br />
            <br />
            1.2 &quot;Exclusão&quot; significa indisponibilidade do Serviço por um ou mais dos seguintes motivos:
            <br />
            • Qualquer manutenção programada para a qual AZUTON DENOMINDA (AZUTOMATIZE) visar com antecedência;
            <br />
            • Falha ou congestionamento experimentado em qualquer parte de uma rede fora de onde o Serviço está hospedado (por exemplo, Provedores de Serviços ou Internet);
            <br />
            • Causas além do controle razoável de qualquer parte, conforme referido no Contrato;
            <br />
            • Suspensão dos Serviços de acordo com o Contrato;
            <br />
            • O Cliente causou problemas, incluindo (mas não se limitando a):
            <br />
            <span
              className="pl-3"
            >
              • Qualquer falha no fornecimento de informações solicitadas pela AZUTON DENOMINDA (AZUTOMATIZE);

            </span>
            <br />
            <span className="
              pl-3
            "
            >
              • Qualquer falha na interface com o Serviço de acordo com as instruções da AZUTON DENOMINDA (AZUTOMATIZE).

            </span>
            <br />
            <br />
            1.3 &quot;Indisponibilidade&quot; significa um período mínimo contínuo de 5 minutos em que o &quot;Serviço&quot; da AZUTON DENOMINDA (AZUTOMATIZE) não está respondendo adequadamente às solicitações de entrada e/ou não está estabelecendo conexões de saída por região conforme pretendido, em cada caso expresso em número de minutos.
            <br />
            <br />
            1.4 &quot;Latência do Aparelho&quot; significa o tempo desde o reconhecimento da AZUTON DENOMINDA (AZUTOMATIZE) da recepção do conteúdo, até que o conteúdo seja entregue ao aparelho relevante.
            <br />
            <br />
            1.5 &quot;Latência Interna&quot; significa o tempo desde o reconhecimento da AZUTON DENOMINDA (AZUTOMATIZE) da recepção do Conteúdo, até o processamento pela AZUTON DENOMINDA (AZUTOMATIZE) e a recepção do Conteúdo reconhecida pelo Provedor de Serviços relevante para entrega posterior ao aparelho.
            <br />
            <br />
            1.6 &quot;Latência de Recebimento de Entrega&quot; significa o tempo desde a confirmação da recepção do Conteúdo pela AZUTON DENOMINDA (AZUTOMATIZE), até o recebimento da entrega do Conteúdo pela AZUTON DENOMINDA (AZUTOMATIZE) do Provedor de Serviços relevante. Nenhuma meta de latência será fornecida para a latência de recebimento de entrega, uma vez que o Provedor de Serviços pode diminuir a prioridade de recebimentos de entrega de conteúdo.
            <br />
            <br />
            1.7 &quot;Método de Relatório de Falhas&quot; significa a maneira como as falhas devem ser relatadas para que a AZUTON DENOMINDA (AZUTOMATIZE) investigue a falha de forma adequada.
            <br />
            <br />
            1.8 &quot;Período de Medição&quot; significa um (1) mês civil a partir do primeiro dia daquele mês calendário específico (UTC). O tempo é calculado em minutos.
            <br />
            <br />
            1.9 &quot;Provedor de Serviços&quot; significa qualquer entidade, incluindo, sem limitação, operadoras de rede móvel e agregadores de mensagens móveis, que é usada em relação ao fornecimento do Serviço.
            <br />
            <br />
            1.10 &quot;Relatórios de Progresso&quot; significa atualizações recorrentes sobre a falha até a restauração ou resolução, conforme aplicável.
            <br />
            <br />
            1.11 &quot;Resolução-Alvo&quot; significa o tempo-alvo a partir do momento em que o Cliente relatou a falha até a resolução da falha.
            <br />
            <br />
            1.12 &quot;Restauração de Destino&quot; (Solução alternativa) significa o tempo estimado para encontrar uma solução temporária para a falha relatada. Uma solução temporária é uma solução que restaura substancialmente o Serviço regular, embora alguns problemas não materiais possam persistir.
            <br />
            <br />
            1.13 &quot;Serviço&quot; significa, para os fins deste SLA, os serviços baseados em SMS, MMS ou RTC API solicitados pelo Cliente em uma Ordem de Serviço.
            <br />
            <br />
            1.14 &quot;Tempo de Resposta Inicial&quot; significa o tempo alvo para responder à notificação do Cliente à AZUTON DENOMINDA (AZUTOMATIZE) sobre uma falha.
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
}
