import styled from 'styled-components';

export const Section6 = styled.div`
  text-align: center;
  padding-bottom: 85px;
  h3{
    margin-top: 65px;
    font-size: 35px;
    @media (max-width: 700px) {
      margin-top: 45px;
      font-size: 35px !important;
    }
  }
  h4{
    font-size: 35px;
    > span{
      font-size: 35px;
    }
    @media (max-width: 700px) {
      font-size: 35px !important;
      > span{
      font-size: 35px;
    }
    }
  }
  .list{
    margin-bottom: 5px !important;
    display: inline-block;
  }
  @media (max-width: 700px) {
    .image{
      width: 80% !important;
      margin: 50px auto 0 !important;
    }
  }
`;
