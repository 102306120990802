import styled from 'styled-components';

export const Header = styled.header`
  height: 115px;
  display: flex;
  align-items: end;
  a:hover{
    color: ${({ theme }) => theme.colors.orange};
    transition: 0.5s;
    .fill{
      path{
        fill: ${({ theme }) => theme.colors.orange};
        transition: 0.5s;
      }
    }
    .stroke{
      path{
        stroke: ${({ theme }) => theme.colors.orange};
        transition: 0.5s;
      }
    }
  }
  .button-menu{
    border: none;
    background: none;
    display: flex;
    flex-direction: column;
    margin-left: 100px;
    p{
      margin-bottom: 0px;
      font-size: 13px;
    }
    span{
      background: #1CC9AE;
      border-radius: 14px;
      width: 41px;
      height: 6px;
      display: inline-block;
      margin-bottom: 3px;
    }
  }
  @media (max-width: 700px) {
    height: 70px;
    .button-menu{
      margin-left: 30px;
    }
    .logo-width{
      width: 100%;
      max-width: 270px;
    }
    .text-right{
      display: flex;
      align-items: center;
      justify-content: space-between !important;
    }
  }
`;

export const LeftMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  a{
    margin-right: 25px;
    &:last-child{
      margin-right: 0;
    }
  }
`;

export const RightMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BurguerMenu = styled.div`
  /* Position and sizing of burger button */
  .bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 41px !important;
  width: 41px !important;
  right: 103px !important;
    top: 46px !important;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #1CC9AE;
  width: 5px !important;
  height: 41px !important;
  border-radius: 14px;

}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  width: 100% !important;
  max-width: 680px;
}

/* General sidebar styles */
.bm-menu {
  background: #EFEFEF;
  padding: 2.5em 70px 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
.bm-burger-button{
  display: none;
}
.bm-menu{
  a{
    color: #383838;
    padding-bottom: 10px;
    transition: 0.3s;
    &:hover{
      color: #FF9700;
      font-weight: 700;
      text-decoration: none;
      transition: 0.1s;
    }
}
}
@media (max-width: 700px) {
    .bm-menu {
      padding: 2.5em 30px 0 !important;
      font-size: 1.15em;
  }
  .bm-cross-button {
      right: 53px !important;
      top: 51px !important;
  }
}
`;
