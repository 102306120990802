// Color generation https://smart-swatch.netlify.app

export default {
  colors: {
    background: '#1E1E1E',
    white: '#fff',
    black: '#383838',
    blue: '#2F53E4',
    orange: '#FF9700',
    green: '#1CC9AE',
    gray: '#F7F7F7',
  },
  fonts: {
    1: '10px',
    2: '12px',
    3: '14px',
    4: '16px',
    5: '18px',
    6: '20px',
    7: '22px',
    8: '35px',
    9: '45px',
    10: '56px',
  },
};
