import styled from 'styled-components';

export const Section19 = styled.div`
  margin-top: 70px;
  background: #515151;
  padding: 50px 0 00px 0;
  h3{
    font-weight: 500;
    color: #23FBD9;
    strong{
      font-weight: 700;
      color: #23FBD9;
    }
  }
  p{
    margin-bottom: 0;
  }
  span{
    height: 197px;
    width: 2px;
    background: #FF9700;
    display: inline-block;
    margin: 0 40px;
  }
  @media (max-width: 700px) {
    p{
      width: 100%;
      text-align: center;
    }
      span{
        width: 100%;
        height: 2px;
        background: #FF9700;
        display: block;
        margin: 22px 30px;
      }
    }
  .box{
    background: #23FBD9;
    border-radius: 34px;
    padding: 15px 0 25px 0;
    @media (max-width: 700px) {
      padding: 15px 20px 25px 20px;

    }
  }
`;
