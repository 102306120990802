import * as S from './styles';
import ArrowUp from '../../../assets/images/footer/arrow-up.svg';

export default function Button({ href, placeholder, className = '' }) {
  return (
    <S.Button className={className} href={href}>
      {placeholder}
      {className === 'outline' && (
        <img className="ml-3" src={ArrowUp} alt="voltar para o topo" />
      )}
    </S.Button>
  );
}
