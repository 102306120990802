import {
  Col, Container, Row,
} from 'react-bootstrap';
import Button from 'components/common/Button';
import LazyLoad from 'react-lazyload';
import * as S from './styles';
import Image from '../../../assets/images/10-aztm/image.png';

export default function AZTM() {
  return (
    <S.Section10>
      <Container>
        <Row className="anchor-image">
          <Col lg={12}>
            <div className="title-background">
              <h3 className="f-8">
                Para cada uma dessas origens, utilizamos um parâmetro
                <br />
                {' '}
                Trackeamento
                {' '}
                <span className="color-orange f-8">AZTM “Azutomatize Monitor’’</span>
                .
              </h3>
            </div>
            <p className="mt-4 mt-lg-7">Então a ideia do parâmetro AZTM é exatamente &quot;grudar&quot; no seu visitante para saber quem é ele, de onde ele veio e o que ele fez quando passou pelo seu site e páginas e a temperatura que ele se encontra, de acordo com o engajamento de seus conteúdos e relacionamentos.</p>
            <p className="font-weight-bold f-7">
              Usando o parâmetro AZTM, no final do seu processo de vendas, você consegue verificar as etapas, temperaturas e comportamento daquela venda.
            </p>
            <p className="f-8 font-weight-bold">E onde visualizar essa informação?</p>
            <p>
              O Parâmetro
              {' '}
              <span className="color-orange">Trackeamento AZTM</span>
              {' '}
              de conteúdo, como o próprio nome diz ‘’Azutomatize Monitor’’ traz dashboards e a leitura se dá com o método de classificação de temperatura da sua lista, conforme suas campanhas, que pode ser um vídeo, uma imagem, um carrossel, um texto... E tudo vai depender da sua estratégia, até que seu cliente tenha consciência plena de seus produtos e serviços e quando estiver em seu momento de compra faça a aquisição, pois tem gente precisando de produtos e serviços todos os dias e você só não sabia ‘’quem , quando e onde’’.
            </p>
            <p className="font-weight-bold">
              Por fim, Azutomatize é a única que tem um método todo em um único lugar e por este motivo se
              <br />
              {' '}
              torna, grande aliada na estratégia para aumentar o
              faturamento da empresa, de forma a compensar o
              <br />
              {' '}
              CAC (custo com aquisição).
            </p>
            <p className="mb-0">
              Indispensável também no investimento da fidelização dos clientes. Tenha em
              <br />
              {' '}
              mente, que vender para um cliente já existente é muito mais fácil e barato do que
              <br />
              {' '}
              atrair um novo cliente. Além disso, consumidores leais à marca têm mais chances
              <br />
              {' '}
              de indicá-la para outras pessoas, ajudando a conquistar novos clientes para a
              <br />
              {' '}
              empresa. Sendo assim, vale a pena dedicar os seus esforços e recursos para criar
              <br />
              {' '}
              uma base de clientes fidelizados.
            </p>
            <LazyLoad offset={500} once>
              <img src={Image} alt="image2" className="image-mobile d-block d-lg-none" />
            </LazyLoad>
          </Col>
        </Row>
      </Container>

    </S.Section10>
  );
}
