import {
  Col, Container, Row,
} from 'react-bootstrap';
import Shield from '../../../assets/images/19-seguranca/shield.svg';
import Awards from '../../../assets/images/19-seguranca/award.svg';

import * as S from './styles';

export default function Seguranca() {
  return (
    <S.Section19>
      <Container>
        <Row>
          <Col lg={12}>
            <div className="f-8 d-flex align-items-center justify-content-start">
              <img src={Shield} alt="Segurança e Robustez" className="mr-3" />
              <h3 className="f-8 mb-0">
                <strong className="f-8">Segurança</strong>
                {' '}
                e
                {' '}
                <strong className="f-8">Robustez</strong>
              </h3>
            </div>
          </Col>
          <Col lg={12} className="d-flex align-items-center justify-content-center mt-6 flex-column flex-lg-row">
            <p className="color-white-light">
              <strong className="color-white">
                ALTO DESEMPENHO
                <br />
                E ALTA DISPONIBILIDADE
              </strong>
              <br />
              Com uma das melhores e maiores
              {' '}
              <br />
              infraestruturas do Brasil e do mundo para
              {' '}
              <br />
              garantirmos a maior disponibilidade para
              {' '}
              <br />
              toda sua aplicação.
            </p>
            <span />
            <p className="color-white-light">
              <strong className="color-white text-uppercase">
                Conectividade
                <br />
                direta entre 29 países
              </strong>
              <br />
              Mais de 350 pops ao redor do mundo. A
              {' '}
              <br />
              rede Azutomatize também está conectada
              <br />
              {' '}
              com Data Centers de classe mundial e
              <br />
              {' '}
              CDN impecável, entregando qualidade
              <br />
              {' '}
              direta para operações fixas e móveis.
            </p>
            <span />
            <p className="color-white-light">
              <strong className="color-white text-uppercase">
                INTERCONECTADO COM
                <br />
                MAIS DE 250 OPERADORAS

              </strong>
              <br />
              Uma conexão suave e fácil, obtendo
              <br />
              {' '}
              uma ampla gama de operadoras que
              <br />
              {' '}
              aumenta o escopo de nossos serviços
              <br />
              {' '}
              para o nível NGN real.
            </p>
          </Col>
          <Col>
            <div className="box text-center mt-6 mb-n8">
              <img src={Awards} alt="30 DIAS GARANTIA INCONDICIONAL" className="mb-2" />
              <p>
                <strong className="f-5">30 DIAS GARANTIA INCONDICIONAL</strong>
                <br />
                Garantia incondicional de 30 dias, se não funcionar para você
                {' '}
                <strong>
                  devolvemos seu dinheiro
                  <br className="d-none d-lg-block" />
                  {' '}
                  sem letras miúdas.
                </strong>
                {' '}
                Tenha seu dinheiro de volta de forma incondicional!
              </p>
            </div>
          </Col>
        </Row>
      </Container>

    </S.Section19>
  );
}
