import styled from 'styled-components';

export const Section7 = styled.div`
  padding-bottom: 45px;
  background-color: #F7F7F7;
  position: relative;
  z-index: 2;
  hr{
    border: 0px;
    height: 2px;
    background: #CFCFCF;;
    margin-top: 30px;
    }
  h3{
    font-size: 35px;
      @media (max-width: 700px) {
        font-size: 35px !important;
        margin-top: 35px;
    }
  }
  .first-section{
    p{
        margin-bottom: 30px;
    }
  }
  .indice{
    margin-right: 10px;
    font-size: 32px;
    font-weight: bold;
  }
`;
