/* eslint-disable react/jsx-props-no-spreading */
import {
  Col, Container, Row,
} from 'react-bootstrap';
import Slider from 'react-slick';
import Logo from '../../../assets/images/15-de-onde-surgiu/logo.svg';
import Image from '../../../assets/images/15-de-onde-surgiu/image.png';

import Carousel1 from '../../../assets/images/15-de-onde-surgiu/carousel-1.png';
import Carousel2 from '../../../assets/images/15-de-onde-surgiu/carousel-2.png';
import Carousel3 from '../../../assets/images/15-de-onde-surgiu/carousel-3.png';
import Carousel4 from '../../../assets/images/15-de-onde-surgiu/carousel-4.png';
import Carousel5 from '../../../assets/images/15-de-onde-surgiu/carousel-5.png';
import Carousel6 from '../../../assets/images/15-de-onde-surgiu/carousel-6.png';

import * as S from './styles';

export default function DeOndeSurgiu() {
  const settings = {
    infinite: true,
    slidesToScroll: 1,
    autoplay: true,
    speed: 10000,
    autoplaySpeed: 0,
    cssEase: 'linear',
    pauseOnHover: true,
    focusOnSelect: true,
    swipeToSlide: true,
    dots: false,
    arrows: false,
    className: 'slider variable-width',
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1650,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const videos = [
    {
      src: Carousel1,
      legend: 'Victor - COO co founder',
    },
    {
      src: Carousel2,
      legend: 'Claudio Crieva - CEO',
    },
    {
      src: Carousel3,
      legend: 'Lançamento Azutomatize',
    },
    {
      src: Carousel4,
      legend: 'Equipe de transmissão - Claudio Crieva, Anselmo Trancoso e Renato Rock',
    },
    {
      src: Carousel5,
      legend: 'Jean - Diretor Comercial',

    },
    {
      src: Carousel6,
      legend: 'Evento 2012 para transmissão streaming<br /> 50 mil pessoas!',
    },
  ];

  return (
    <S.Section15>
      <Container>
        <Row>
          <Col lg={12} className="mb-6">
            <img src={Logo} className="logo" alt="logo Azutomatize" />
          </Col>
          <Col lg={7}>
            <p className="color-white-light">
              No início de 2008 trabalhávamos, Claudio Roberto (eu) e Victor Wiik, criamos um projeto totalmente do zero em um nicho muito tradicional e de muito investimento, porém muito fechado, o mercado Telecomunicações.
              <br />
              <br />
              Montamos uma operadora, o desafio foi enorme pois só para colocar a empresa em operação precisaríamos de 2 milhões de dólares, e em 4 anos conseguimos colocar em operação. Surgiram as oportunidades e começamos a desenvolver produtos. Um desses produtos foi o de transmissão &apos;&apos;streaming&apos;&apos; (transmissões ao vivo pela internet) criado em 2012, éramos um dos pioneiros no BRASIL , antes mesmo do youtube, Instagram, e todas estas que são corriqueiras hoje.
              <br />
              <br />
              A coisa foi dando muito certo e o negócio começou a bombar...Começamos a fazer alguns eventos e tudo estava saindo perfeitamente bem, pronto agora acertamos. Passados uns 06 meses fomos convidados para transmitir ao vivo um grande evento de sertanejo, aproximadamente para umas 50 mil pessoas e ficamos muito felizes.
            </p>
          </Col>
          <Col lg={5}>
            <div className="box-image">
              <span />
              <img className="de-onde-image" src={Image} alt="De onde surgiu" />
            </div>
          </Col>
          <Col lg={12}>
            <p className="color-white-light">
              Mas por não acreditarmos de forma convicta que daria certo e se preparar de forma adequada o projeto não vingou em nossas mãos e não conseguimos surfar a onda.
              <br />
              <br />

              Passados 8 anos, em 2020, o Diretor de imagens que estava trabalhando comigo e aprendeu a fazer o trabalho, implementou no período da pandemia e foi um dos responsáveis pelas principais lives, período de grande dor para nosso país, levando um pouco de alegria para os lares.
              <br />
              <br />
              <strong className="color-white">Ele alcançou o maior recorde para a época, quase 2.5 milhões de pessoas assistindo simultaneamente.</strong>
              <br />
              <br />
              A moral da história é que precisamos ter resiliência e preparação para crescer, pois faz parte do processo. Com isso aprendemos que para crescer e escalar, você tem que se preparar e ter as ferramentas certas. Antes da pandemia aqui no Brasil em meados de Outubro entendemos que o mundo estava se transformando e o futuro da experiência do cliente era conversacional e se comunicar de qualquer lugar em qualquer canal.
              <br />
              <br />
              Por este motivo criamos o produto AZUPHONE , em outubro de 2019, aplicamos todos os requisitos para segurança, para capacidade, para escalar e automatizar, tínhamos que entregar o projeto até dezembro de 2019. Conseguimos e antes de acabar aquele ano conseguimos atender 04 clientes.
              <br />
              <br />
              Virou o ano e em 2020, como nada acontece por acaso , estávamos trabalhando duro com o produto mês a mês, quando anunciaram a pandemia e também por consequência vendemos muito o AZUPHONE e nos posicionamos no mercado!
              <br />
              <br />
              Esta explosão de vendas aconteceu porque estávamos com o produto certo na hora certa, ajudando as empresas com o home office, levando seus sistemas de telefonia e comunicação para as casas dos funcionários, junto com todo sistema, além das vendas ficamos muito felizes em ajudar as empresas, pois por este motivo muitos empregos foram preservados e adequamos as empresas para uma nova realidade tecnológica.
            </p>
            <hr />
            <p className="color-white">
              <span className="title">Hoje a ferramenta está completa e se atualizando a cada dia!</span>
              <br />
              Azutomatize, conta com o conhecimento incomparável nas telecomunicações e aplica o método LOW CAC “Interações Personalizadas que geram aquisições de clientes e retenções com baixo custo&apos;&apos;
              <br />
              <br />

              Hoje a maior alternativa às big techs, eficiente e amigável com os clientes. Comunicação personalizada e gestão em uma única Ferramenta. Falando com seu cliente onde ele se sentem mais confortáveis Ferramenta robusta e escalável, impactando de forma automática e humanizada, com a usabilidade mais simples, usando &apos;&apos;UX&apos;&apos; experiência do usuário:
              <br />
              <br />

              <span className="color-orange">•</span>
              {' '}
              Automação e atendimento em escala;
              <br />
              <span className="color-orange">•</span>
              {' '}
              Ofertas produtos;
              <br />
              <span className="color-orange">•</span>
              {' '}
              ChatBots escalando conversas;
              <br />
              <span className="color-orange">•</span>
              {' '}
              VoiceBots (torpedo de voz) com IA e interação de URA;
              <br />
              <span className="color-orange">•</span>
              {' '}
              Engajamento da Marca;
              <br />
              <span className="color-orange">•</span>
              {' '}
              Descobrir as necessidades dos clientes;
              <br />
              <span className="color-orange">•</span>
              {' '}
              Nutrição de informações para a base de clientes;
              <br />
              <span className="color-orange">•</span>
              {' '}
              Lançamento de produtos;
              <br />
              <span className="color-orange">•</span>
              {' '}
              Atendimento mais eficiente.
              <br />
              <br />
              Mais produtividade como menos esforços, com Clientes mais Felizes e parceiros da sua marca.
            </p>
          </Col>
        </Row>
      </Container>
      <Slider {...settings}>
        {videos.map((data) => (
          <div style={{ width: 400 }}>
            <img className="mb-3" src={data.src} alt={data.legend} />
            <span className="color-white" dangerouslySetInnerHTML={{ __html: data.legend }} />
          </div>
        ))}
      </Slider>
    </S.Section15>
  );
}
