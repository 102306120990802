import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components';
import Image from '../../../assets/images/12-ads/image.svg';
import BackgroundImage from '../../../assets/images/12-ads/image-bg.svg';

export const Section12 = styled.div`
  padding-top: 75px;
  padding-bottom: 70px;
  position: relative;
  hr{
    border: 0px;
    height: 2px;
    background: #EFEFEF;
    margin-top: 30px;
  }
  .box-blue{
    background: #2F53E4;
    padding: 30px;
    border-radius: 25px;
    .tags{
      display: flex;
      flex-wrap:wrap;
      span{
        background: #2642B6;
        border: 1px solid #496CFA;
        border-radius: 12px;
        padding: 8px 22px;
        margin-right: 10px;
        margin-bottom: 10px;
        color: ${({ theme }) => theme.colors.orange};
      }
    }
    &:after{
      content: "";
      width: 364px;
      height: 569px;
      position: absolute;
      right: -1%;
      bottom: 50px;
      background-image: url(${Image});
      background-repeat: no-repeat;
      background-position: center;
      z-index: 1;
      animation: shake-vertical 15s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
    }
    &:before{
      content: "";
      width: 359.08px;
      height: 569px;
      position: absolute;
      right: 50px;
      bottom: 40px;
      background-image: url(${BackgroundImage});
      background-repeat: no-repeat;
      background-position: center;
      z-index: 1;
    }
    @media (max-width: 700px) {
      &:after, &:before{
        display: none;
      }
      .image-effect{
        position: relative;
        text-align: center;
        img{
          width: 90%;
          margin: 40px auto;
          position: relative;
          z-index: 2;
          animation: shake-vertical 15s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
        }
        &:before{
          content: "";
          width: 100%;
          height: 569px;
          position: absolute;
          right: 0px;
          bottom: -50px;
          background-image: url(${BackgroundImage});
          background-repeat: no-repeat;
          background-position: center;
          z-index: 1;
          background-size: contain;
        }
      }
    }
  }
  .title{
    font-size: 30px;
    font-weight: 400;
    color: #FFFFFF;
    strong{
      font-size: 30px;
      font-weight: bold;
    }
    span{
      font-size: 30px;
    }
  }

    @keyframes shake-vertical {
      0%,
      100% {
        transform: translateY(0);
      }
      10%,
      30%,
      50%,
      70% {
        transform: translateY(-8px);
      }
      20%,
      40%,
      60% {
        transform: translateY(8px);
      }
      80% {
        transform: translateY(6.4px);
      }
      90% {
        transform: translateY(-6.4px);
      }
    }
`;
