import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components';
import backgroundImage from '../../../assets/images/10-aztm/aztm.svg';
import Image from '../../../assets/images/10-aztm/image.png';

export const Section10 = styled.div`
  padding: 95px 0;
  position: relative;
  .title-background{
    position: relative;
    h3{
      position: relative;
      z-index: 2;
    }
    &:after{
      content: "";
      width: 650.27px;
      height: 225px;
      position: absolute;
      left: 0;
      bottom: -70px;
      background-image: url(${backgroundImage});
      background-repeat: no-repeat;
      background-position: center;
      z-index: 1;
      opacity: 0.5;
    }
    @media (max-width: 700px) {
      &:after{
        display: none;
      }
    }
  }
    @media (max-width: 700px) {
      padding: 55px 0;
      .image-mobile{
        width: 100%;
        margin-bottom: -120px;
        position: relative;
        z-index: 2;
      }

    }
  .anchor-image{
    position: relative;
    &:after{
      content: "";
      width: 523px;
      height: 528px;
      position: absolute;
      right: 0px;
      bottom: -250px;
      background-image: url(${Image});
      background-repeat: no-repeat;
      background-position: center;
      z-index: 1;
    }
       @media (max-width: 700px) {
      &:after{
        display: none;
      }
    }
  }

`;
