import styled from 'styled-components';
import backgroundImage from '../../../assets/images/8-porque-azutomatize/banner-image.png';
import backgroundSVG from '../../../assets/images/8-porque-azutomatize/gray-image.svg';

export const Section8 = styled.div`
  text-align: left;
  position: relative;
  z-index: 1;
  padding-bottom: 80px;
  .subtitle{
    letter-spacing: 0.115em;
    padding-top: 45px;
  }
  &:after{
    content: "";
    width: 529px;
    height: 496.8px;
    position: absolute;
    right: 5%;
    top: -50px;
    background-image: url(${backgroundSVG});
  }
  @media (max-width: 700px) {
    &:after{
      display: none;
    }
  }
  .content{
    z-index:2;
    position: relative;
  }

  h4{
    margin: 80px 0;
    position: relative;
    &:after{
      content: "";
      position: absolute;
      width: 173px;
      height: 9px;
      left: 0;
      bottom: -25px;
      background: #1CC9AE;
      border-radius: 14px;
    }
  }

  @media (max-width: 700px) {
    .image-graf{
      display: block;
      margin: 0px auto 0;
    }
    .note-mobile{
      width: 100%;
    }
  }
`;

export const Banner = styled.div`
  background: linear-gradient(94.41deg, #383838 30.67%, #666666 95.53%);
  border-radius: 30px;
  padding: 40px 50px 55px 40px;
  position: relative;
  margin-top: 90px;
  &:after{
    content: "";
    width: 328px;
    height: 328px;
    position: absolute;
    right: 50px;
    top: -40px;
    background-image: url(${backgroundImage});
    z-index: 1;
  }
  @media (max-width: 700px) {
    &:after{
      display: none;
    }
    a{
      text-align: center;
      display: block;
      width: 100% !important;
    }
    .image-banner-mobal{
      width: 100% ;
      margin-bottom: -160px;
      margin-top: 40px;
    }
  }
`;
