import styled from 'styled-components';

export const Section18 = styled.div`
  margin-top: 75px;
  .box{
    background: #2F53E4;
    border-radius: 34px;
    padding: 37px 19px;
    .left-side{
      font-size: 25px;
      font-weight: light;
      strong{
        font-size: 25px;
      }
    }
    .first-line{
      span{
        width: 2px;
        height: 100px;
        background: #FF9700;
        display: block;
        margin: 0 30px;
      }
    }
    @media (max-width: 700px) {
      padding-top: 150px;
      position: relative;
      img{
        position: absolute;
        top: -72px;
        left: 40%;
      }
      .first-line{
      span{
        width: 100%;
        height: 2px;
        background: #FF9700;
        display: block;
        margin: 22px 30px;
      }
    }
    }
  }
`;
