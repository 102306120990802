/* eslint-disable import/no-extraneous-dependencies */
import {
  Col, Container, Row,
} from 'react-bootstrap';
import Button from 'components/common/Button';
import { Player } from '@lottiefiles/react-lottie-player';
import * as S from './styles';
import Image from '../../../assets/images/4-vantagens/image.json';

export default function Vantagens() {
  return (
    <S.Section4>
      <Container>
        <Row>
          <Col lg={5} className="d-flex align-items-center justify-content-center">
            <p className="f-9 text-left mb-0">
              <strong className="f-9">5 vantagens</strong>
              de
              <br />
              implementar o
              <br />
              método
              <strong className="f-9">Low Cac</strong>
              <br />
              na sua empresa.
            </p>
          </Col>
          <Col lg={7}>
            <Player
              className="mt-3 mt-lg-0"
              autoplay
              loop
              src={Image}
            />
            {/* <img src={Image} alt="5 Vantagens" /> */}
          </Col>
        </Row>
      </Container>

    </S.Section4>
  );
}
