import styled from 'styled-components';
import ArrowLeft from '../../../assets/images/5-solucoes/arrow-left.svg';
import ArrowRight from '../../../assets/images/5-solucoes/arrow-right.svg';

export const Section5 = styled.div`
background-color: ${({ theme }) => theme.colors.green};
padding: 60px 0;
.bar{
  font-weight: 400;
  font-size: 35px;
}
.dash{
  width: 91px;
  height: 9px;
  background-color: ${({ theme }) => theme.colors.white};
  display: block;
  border-radius: 14px;
  position: relative;
  &:after{
    content: "";
    width: 106px;
    height: 9px;
    position: absolute;
    background: #23FBD9;
    left: 87px;
    top: 0;
    border-radius: 14px;
  }
  }
  .slick-dots{
    display: none !important;
  }
  margin-top: -70px;

  .slick-prev{
    position: absolute;
    right: 240px;
    top: -70px;
    left: inherit !important;
    @media (max-width: 700px) {
      right: calc( 50% + 32px);
      top: 294px;
    }

    &:before{
      content: "";
      background-image: url(${ArrowLeft});
      width: 24px;
      height: 41px;
      display: block;
      background-repeat: no-repeat;
    }
  }
  .slick-next{
    position: absolute;
    right: 180px;
    top: -70px;
    @media (max-width: 700px) {
      right: calc( 50% - 32px);
      top: 294px;
    }
    &:before{
      content: "";
      background-image: url(${ArrowRight});
      width: 24px;
      height: 41px;
      display: block;
      background-repeat: no-repeat;
    }
  }
  .slick-list, .slick-track{
    height: 263px;
  }

  @media (max-width: 700px) {
      .logos{
        margin-top: 100px;
        max-width: 400px;
        > div {
          width: 100%;
          overflow-x: scroll;
        }
      }
      .slick-slider, .slick-track, .slick-list {
        height: 262px;
      }
  }
  `;

export const Image = styled.div`
  padding: 25px 0;
  text-align: left;
  position: relative;
  img{
    position: absolute;
    top: -35px;
    right: -100px;
  }
`;

export const LineVideos = styled.div`
  display: flex;
  justify-content: space-between;
      @media (max-width: 700px) {
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
      }
      `;

export const BoxVideo = styled.div`
  width: 24%;
`;
