import {
  Col, Container, Row,
} from 'react-bootstrap';
import LazyLoad from 'react-lazyload';
import Image from '../../../assets/images/7-como-recuperar/image.png';

import * as S from './styles';

export default function ComoRecuperar() {
  return (
    <S.Section7>
      <Container>
        <Row>
          <Col lg={6} className="d-none d-lg-flex">
            <LazyLoad offset={500} once>
              <img className="mt-n3" src={Image} alt="Como recuperar carrinhos de compras abandonados?" />
            </LazyLoad>
          </Col>
          <Col lg={6} className="text-center text-lg-right first-section align-align-items-center justify-content-center d-flex flex-column">
            <h3>Como recuperar carrinhos de compras abandonados?</h3>
            <p>
              Há importância da comunicação de consciência em
              <br />
              cada etapa do caminho do seu cliente.
            </p>
            <p>
              <strong>Exemplo de como recuperar vendas abandonadas?</strong>
              <br />
              Quais Soluções são usadas para um atendimento diferenciado na
              <br />
              {' '}
              recuperação de vendas, e quais tipos de campanhas exclusivas
              <br />
              {' '}
              podemos

              {' '}
              enviar para esses consumidores?
            </p>
            <p className="color-blue f-7">Vamos conhecer as principais:</p>
          </Col>
        </Row>
        <Row className="mt-6">
          <Col className="text-left">
            <div>
              <p className="mb-4 font-weight-bold f-7">
                <span className="indice color-green">1.</span>
                Planeje ações de recuperação
              </p>
              <p className="mb-0">
                Antes de escolher a estratégias mais adequadas para a recuperação do carrinho abandonado, é preciso fazer um planejamento e entender os motivos que levaram o cliente a desistir da compra.
                <br />
                <br />
                O que se deve ter em mente é que são consumidores que realizam uma compra, mas por algum motivo não finalizaram. Diante disso, alguns pontos precisam ser questionados antes de começar qualquer estratégia de recuperação, que pode ser:
                <br />
                <br />
                <strong>
                  <span className="color-blue">•</span>
                  {' '}
                  Quantos carrinhos em média são abandonados no período de um mês?
                </strong>
                <br />
                <br />
                <strong>
                  <span className="color-blue">•</span>
                  {' '}
                  Qual é o número de clientes que retorna após um tempo e concluem a compra?
                </strong>
                <br />
                <br />
                <strong>
                  <span className="color-blue">•</span>
                  {' '}
                  Qual é o tempo médio que eles retornam?
                </strong>
                <br />
                <br />
                São essas informações que podem definir qual a estratégia mais precisa para melhorar a experiência do cliente. Como exemplo, temos o e-mail-marketing, que pode acelerar o retorno de um cliente indeciso, induzindo-o para a conclusão da compra.
                <br />
                <br />
                Muitas vezes, uma vantagem a mais pode persuadi-lo, como a isenção da taxa de entrega por exemplo. As redes sociais também disponibilizam bons recursos para um diálogo mais próximo e prático com o cliente, entendendo os motivos do abandono e dessa forma modificando algumas práticas.
              </p>
              <hr />
            </div>

            <div className="mt-5">
              <p className="mb-4 font-weight-bold f-7">
                <span className="indice color-green">2.</span>
                Revise seus processos de check out, tornando-os mais dinâmicos:
              </p>
              <p className="mb-0">
                Revise os processos de obtenção de dados, tornando-os mais simples, sem fazer com que o cliente perca muito tempo, usando SMS para autenticação no processo, por exemplo. Na dúvida, faça testes A/B para saber as opções mais rápidas. Uma senha esquecida, por exemplo, pode custar muito tempo para o consumidor. Buscar processos de recuperação mais simples facilita bastante o acesso.
              </p>
              <hr />
            </div>

            <div className="mt-5">
              <p className="mb-4 font-weight-bold f-7">
                <span className="indice color-green">3.</span>
                Aposte na estratégia de e-mail marketing, sms, WhatsApp, Torpedo de Voz:
              </p>
              <p className="mb-0">
                Como já mencionado, a comunicação Automática e Humanizada é uma ferramenta poderosa, mas que é ainda mais eficaz em situações de carrinho abandonado. Ao entrar em contato com o consumidor será possível entender melhor o que levou à desistência. Além disso, sua marca passará a ideia de confiabilidade e comprometimento com seus clientes o que contribuirá para fortalecer a imagem da empresa.
              </p>
              <hr />
            </div>

            <div className="mt-5">
              <p className="mb-4 font-weight-bold f-7">
                <span className="indice color-green">4.</span>
                Invista em marketing, por exemplo SMS com o link do produto esquecido:
              </p>
              <p className="mb-0">
                Trata-se de uma estratégia que oferece vantagens ao consumidor que escolheu um ou mais produtos, os esqueceu ou os abandonou no carrinho durante a sua navegação pela internet. Fazer o remarketing pode ser uma excelente oportunidade de oferecer uma vantagem ao cliente e assim, recuperá-lo para essa e outras futuras compras.
              </p>
              <hr />
            </div>

            <div className="mt-5">
              <p className="mb-4 font-weight-bold f-7">
                <span className="indice color-green">5.</span>
                Amplie as formas de pagamento:
              </p>
              <p className="mb-0">
                Oferecer todas as formas possíveis de pagamento ao cliente, certamente, pode evitar um abandono. Se ele quiser fazer um pagamento à vista e seu site só oferecer a alternativa de cartões de crédito, isso será um empecilho.
              </p>
              <hr />
            </div>

            <div className="mt-5">
              <p className="mb-4 font-weight-bold f-7">
                <span className="indice color-green">6.</span>
                Ofereça vantagens:
              </p>
              <p className="mb-0">
                Uma das melhores estratégias para evitar o abandono do carrinho é a oferta de vantagens ao cliente. Diante de um benefício que realmente seja um diferencial, é muito difícil desistir da compra, mesmo que ele encontre algum obstáculo. Por isso, oferecer vantagens como frete grátis, entregas rápidas ou um bom desconto no valor à vista são aspectos valorizados e que cativam os clientes, evitando o abandono de carrinho.
                <br />
                <br />
                <strong>Crie de Imediato Condições Personalizadas e Coloque em prática e lembre-se: fale de alguma forma com seu cliente!</strong>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </S.Section7>
  );
}
