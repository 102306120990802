/* eslint-disable react/button-has-type */
/* eslint-disable import/no-extraneous-dependencies */
import { Col, Container, Row } from 'react-bootstrap';
import { slide as Menu } from 'react-burger-menu';
import { useState } from 'react';
import Logo from '../../assets/images/logo.svg';
import Twitter from '../../assets/images/icons/twitter.svg';
import Facebook from '../../assets/images/icons/facebook.svg';
import Instagram from '../../assets/images/icons/instagram.svg';
import Youtube from '../../assets/images/icons/youtube.svg';

import * as S from './styles';

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <S.BurguerMenu id="header">
        <Menu isOpen={isOpen} onClose={toggleMenu} right className="right">
          <div>
            <a target="_blank" href="https://twitter.com/AzutonA" rel="noreferrer"><img src={Twitter} alt="AzutoMatize" className="mr-2" /></a>
            <a target="_blank" href="https://www.youtube.com/@crieva-makeandgocrieva-mak8006?app=desktop" rel="noreferrer"><img src={Youtube} alt="AzutoMatize" className="mr-2 ml-1" /></a>
            <a target="_blank" href="https://www.facebook.com/azutonsolucoes/" rel="noreferrer"><img src={Facebook} alt="AzutoMatize" className="mr-2 ml-1" /></a>
            <a target="_blank" href="https://www.instagram.com/crieva_oficial/" rel="noreferrer">
              <img src={Instagram} alt="AzutoMatize" className="mr-2 ml-1" />
              {' '}
            </a>

          </div>
          <hr className="mt-6 mb-6" />

          <a href="/#Planos" onClick={toggleMenu}>Nossos Planos</a>
          <a href="#Vantagens" onClick={toggleMenu}>Vantagens</a>
          <a href="#Solucoes" onClick={toggleMenu}>Testemunhos</a>
          <a href="#QuemSeDestina" onClick={toggleMenu}>A quem se destina</a>
          <a href="#ComoRecuperar" onClick={toggleMenu}>Carrinhos abandonados</a>
          <a href="#Metodo" onClick={toggleMenu}>Nosso método</a>
          <a href="#Indicadores" onClick={toggleMenu}>Componentes da Ferramenta</a>
          <a href="#Faq" onClick={toggleMenu}>FAQ</a>
          <a href="#DeOndeSurgiu" onClick={toggleMenu}>Quem somos</a>
          <a href="#Bonus" onClick={toggleMenu}>Bônus</a>
          <a href="#QualAVantagem" onClick={toggleMenu}>O que faz e quais as vantagens</a>
          <a href="#Seguranca" onClick={toggleMenu}>Segurança e Robustez</a>
        </Menu>
      </S.BurguerMenu>
      <S.Header>
        <Container>
          <Row>
            <Col lg={6} className="text-right d-flex align-items-end justify-content-start">
              <img src={Logo} alt="AzutoMatize" className="logo-width" />
              <button className="button-menu d-flex d-lg-none" onClick={() => toggleMenu()}>
                <p>MENU</p>
                <span className="bars" />
                <span className="bars" />
                <span className="bars" />
              </button>
            </Col>
            <Col className="text-right align-items-center justify-content-end d-none d-lg-flex" lg={6}>
              <a target="_blank" href="https://twitter.com/AzutonA" rel="noreferrer">
                <img src={Twitter} alt="AzutoMatize" className="mr-2" />
              </a>
              <a target="_blank" href="https://www.youtube.com/@crieva-makeandgocrieva-mak8006?app=desktop" rel="noreferrer">
                <img src={Facebook} alt="AzutoMatize" className="mr-2 ml-1" />
              </a>
              <a target="_blank" href="https://www.instagram.com/crieva_oficial/" rel="noreferrer">
                <img src={Instagram} alt="AzutoMatize" className="mr-2 ml-1" />
              </a>
              <button className="button-menu" onClick={() => toggleMenu()}>
                <p>MENU</p>
                <span className="bars" />
                <span className="bars" />
                <span className="bars" />
              </button>
            </Col>
          </Row>
        </Container>
      </S.Header>
    </>
  );
}
