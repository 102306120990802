import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components';
import backgroundImage from '../../../assets/images/2-video/video.svg';

export const Section4 = styled.div`
  text-align: center;
  padding-bottom: 85px;
  position: relative;
  min-height: 650px;
  @media (max-width: 700px) {
    padding-bottom: 100px;
  }
  p{
    position: relative;
    @media (max-width: 700px) {
      line-height: 120%;
  }
    &:after{
      content: "";
      width: 173px;
      height: 9px;
      background: ${({ theme }) => theme.colors.green};
      border-radius: 14px;
      position: absolute;
      left: 0;
      bottom: -30px;
    }
  }

  div{
    min-height: 650px;
    @media (max-width: 700px) {
    min-height: 300px;
  }
  }
  img{
    position: absolute;
    left: 0;
    top: 50px;
  }
`;
