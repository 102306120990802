import styled from 'styled-components';

export const Button = styled.a`
  font-size: ${({ theme }) => theme.fonts[5]};
  border-radius: 12px;
  padding: 12.5px 70px;
  background-color: ${({ theme }) => theme.colors.orange};
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  border: 2px solid ${({ theme }) => theme.colors.orange};
  transition: 0.3s;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  &:hover{
    text-decoration: none;
    &:hover{
    text-decoration: none;
    color: ${({ theme }) => theme.colors.orange};
    background-color: ${({ theme }) => theme.colors.white};
    transition: 0.2s;
  }
  }
  &.outline{
    color: ${({ theme }) => theme.colors.orange};
    background: #383838 !important;
    border: 2px solid #FF9700;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover{
      color: ${({ theme }) => theme.colors.white}};
      transition: 0.4s;
      background: ${({ theme }) => theme.colors.orange}};
    }
  }
  @media (max-width: 700px) {
    padding: 12.5px 0px;
    width: 100%;
  }
`;
