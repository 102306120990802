import {
  Col, Container, Row,
} from 'react-bootstrap';
import * as S from './styles';
import Image from '../../../assets/images/1-vender-mais/image.svg';

export default function VenderMais() {
  return (
    <S.Section1>
      <Container>
        <Row>
          <Col lg={8}>
            <h1 className="f-8">
              Vender mais e todo o dia, seus clientes
              <br className="d-none d-lg-block" />
              mais felizes e fãs de sua marca.
            </h1>
            <p>
              Assista o Vídeo para descobrir os 3 canais que seus clientes mais usam e a solução que você
              <br className="d-none d-lg-block" />
              precisa para engajar mais.
            </p>
            <p>
              <span className="color-blue">
                Descubra como a comunicação personalizada e o método Low Cac, vai ajudar em cada
                <br className="d-none d-lg-block" />
                etapa da jornada do seu cliente
              </span>
              , tornando mais eficiente o processo, gerando mais leads,
              <br className="d-none d-lg-block" />
              recuperando carrinhos de compras abandonados e muito mais. Seus clientes muito mais
              <br className="d-none d-lg-block" />
              {' '}
              próximos, atendendo as necessidades mais urgentes.
            </p>
          </Col>
          <Col lg={4}>
            <S.Image>
              <img src={Image} alt="Vender mais e todo o dia" />
            </S.Image>
          </Col>
        </Row>
      </Container>

    </S.Section1>
  );
}
