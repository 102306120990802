import styled from 'styled-components';
import backgroundImage from '../../../assets/images/15-de-onde-surgiu/background.svg';

export const Section15 = styled.div`
  background: #383838;
  padding: 80px 0 235px 0;
  color: ${({ theme }) => theme.colors.white};
  p{
    font-weight: 400;
    position: relative;
    z-index: 2;
  }
  hr{
    border: 0px;
    height: 2px;
    background: #525252;
    margin-top: 40px;
    margin-bottom: 35px;
    }
    .title{
      color: #23FBD9;
      font-weight: bold;
      font-size: 25px;
    }
  .box-image{
    margin-top: -15px;
    span{
      background: #FF9700;
      width: 12.69px;
      height: 12.69px;
      border-radius: 20px;
      position: relative;
      display: block;
      margin-bottom: 15px;
      margin-left: 30px;
      &:after{
        content: "";
        background: #FF9700;
        width: 12.69px;
        height: 12.69px;
        border-radius: 20px;
        position: absolute;
        right: 20px;
        bottom: 0px;
      }
      &:before{
        content: "";
        background: #FF9700;
        width: 12.69px;
        height: 12.69px;
        border-radius: 20px;
        position: absolute;
        right: -20px;
        bottom: 0px;
      }
    }
    img.de-onde-image{
      border-radius: 28px;
      width: 100%;
      max-width: 467px;
      height: 345px;
      position: relative;
      z-index: 2;
    }
    &:after{
        content: "";
        width: 482px;
        height: 541.96px;
        position: absolute;
        right: 7px;
        bottom: -67px;
        background-image: url(${backgroundImage});
        background-repeat: no-repeat;
        background-position: center;
        z-index: 1;
      }
  }
      @media (max-width: 700px) {
       .logo{
        width: 100%;
       }
       .box-image{
        img{
          margin: 30px auto 30px;
          display: block;
          width: 298.88px !important;
          height: 220.8px !important;
        }
        span{
          width: 8.12px;
          height: 8.12px;
          margin-bottom: -20px;
          margin-left: 60px;
          &:after{
            width: 8.12px;
          height: 8.12px;
      }
      &:before{
        width: 8.12px;
          height: 8.12px;
      }
        }
      &:after{
        width:308px;
        height: 541.96px;
        right: 0px;
        left: 60px;
        bottom: -127px;
        background-size: contain ;
      }
       }
       padding: 80px 0 200px 0;

    }
`;
