import {
  Col, Container, Row,
} from 'react-bootstrap';
import Button from 'components/common/Button';
import Arrow from '../../../assets/images/18-qual-a-vantagem/arrow.svg';
import Image from '../../../assets/images/18-qual-a-vantagem/image.svg';
import Megafone from '../../../assets/images/18-qual-a-vantagem/megafone.svg';
import * as S from './styles';

export default function QualAVantagem() {
  return (
    <S.Section18>
      <Container>
        <Row>
          <Col lg={12}>
            <h3 className="f-8">
              QUAL A VANTAGEM?
            </h3>
            <p className="mt-6 mb-4 pt-2">
              Sabemos que as pessoas tentam fazer tudo certo, mas
              {' '}
              <strong>Azutomatize reduz o espaço para erro humano!</strong>
            </p>
          </Col>
          <Col lg={6}>
            <p>
              <span className="color-blue text-uppercase">A Ferramenta complementa:</span>
              <br />
              <br />
              <img src={Arrow} alt="Arrow" className="mr-2" />
              Flexibilidade de uso
              <br />
              <img src={Arrow} alt="Arrow" className="mr-2" />
              Redução de custos
              <br />
              <img src={Arrow} alt="Arrow" className="mr-2" />
              Aplicações simples
              <br />
              <img src={Arrow} alt="Arrow" className="mr-2" />
              Comunicação onde seus clientes estão
              <br />
              <img src={Arrow} alt="Arrow" className="mr-2" />
              Nutrição de leads
              <br />
              <img src={Arrow} alt="Arrow" className="mr-2" />
              Torna o trabalho mais produtivo
              <br />
              <img src={Arrow} alt="Arrow" className="mr-2" />
              Campanhas mais assertivas
              <br />
              <img src={Arrow} alt="Arrow" className="mr-2" />
              Leads mais preparados
              <br />
              <img src={Arrow} alt="Arrow" className="mr-2" />
              Proporciona um ciclo de vendas mais curto
              <br />
              <img src={Arrow} alt="Arrow" className="mr-2" />
              Funil mais eficiente e com maiores taxas de conversão.
              <br />
            </p>
            <img className="d-block d-lg-none w-100 mb-6" src={Image} alt="Arrow" />
            <p>
              <span className="color-orange f-7">Resultados</span>
              <br />
              <br />
              <span className="color-green">•</span>
              {' '}
              Escalar suas vendas
              <br />
              <span className="color-green">•</span>
              {' '}
              Reter os clientes em sua base
              <br />
              <span className="color-green">•</span>
              {' '}
              Atendimento mais rápido e Assertivo
              <br />
              <span className="color-green">•</span>
              {' '}
              Vender mais e melhor
              <br />
              <span className="color-green">•</span>
              {' '}
              Engajar novos e futuros clientes
              <br />
            </p>
            <p>
              <span className="color-orange f-7">Colheita</span>
              <br />
              <br />
              <span className="color-green">•</span>
              {' '}
              Seu cliente mais feliz
              <br />
              <span className="color-green">•</span>
              {' '}
              Mais tempo com a família
              <br />
              <span className="color-green">•</span>
              {' '}
              Mais prosperidade
              <br />
              <span className="color-green">•</span>
              {' '}
              Descobrimento de seus produtos e serviços
              <br />
              <span className="color-green">•</span>
              {' '}
              Seu cliente mais tempo com você - Fãs da sua Marca
            </p>
          </Col>
          <Col lg={6} className="d-none d-lg-flex align-items-center justify-content-center">
            <img src={Image} alt="Arrow" />
          </Col>
          <Col lg={12}>
            <div className="box mt-6">
              <div className="first-line d-flex align-items-center justify-content-between flex-column flex-lg-row mt-n4">
                <div className="d-flex w-100 align-items-center justify-content-center">
                  <img src={Megafone} alt="Megafone" className="ml-n7" />
                  <p className="color-white-light left-side mb-0 mb-lg-5">
                    Não só mande mensagens,
                    {' '}
                    <strong className="color-white">dialogue com seus clientes.</strong>
                  </p>
                </div>
                <span />
                <p className="color-white-light w-100 mb-0">
                  <strong className="color-white">É possível entender e agir de maneira personalizada e escalável junto à sua audiência</strong>
                  , nos mais diversos meios conversação, comunicação inteligente com seus clientes, nos canais que eles já usam e adoram! Mensagens, e-mail, voz e vídeo
                </p>
              </div>
              <div className="d-flex align-items-center justify-content-center mt-5 mt-lg-n4">
                <Button placeholder="FAZER INSCRIÇÃO" className="text-center" href="/#Planos" />
              </div>
            </div>
          </Col>
        </Row>
      </Container>

    </S.Section18>
  );
}
