import {
  Col, Container, Row,
} from 'react-bootstrap';
import Button from 'components/common/Button';
import BonusIcon from '../../../assets/images/16-bonus/bonus.svg';
import Zoom from '../../../assets/images/16-bonus/zoom.png';

import * as S from './styles';

export default function Bonus() {
  return (
    <S.Section16>
      <Container>
        <Row>
          <Col>
            <div className="box-1">
              <div className="d-flex align-items-center">
                <img src={BonusIcon} alt="Bonus" className="mr-4 bonus-icon" />
                <div className="d-flex flex-column justify-content-start algin-align-items-start text-left">
                  <h3 className="f-9 color-green mb-0 mb-lg-1">
                    BÔNUS
                  </h3>
                  <span className="d-none d-lg-block">
                    Suporte como você nunca viu em nenhuma outra ferramenta
                    <br className="d-none d-lg-block" />
                    de multicanal, pois é a única que possui um
                    {' '}
                    <span className="color-orange">PERSONAL TÉC</span>
                    .
                  </span>
                </div>
              </div>
              <p className="d-block d-lg-none mt-4">
                Suporte como você nunca viu em nenhuma outra ferramenta
                <br className="d-none d-lg-block" />
                de multicanal, pois é a única que possui um
                {' '}
                <span className="color-orange">PERSONAL TÉC</span>
                .
              </p>
              <p className="mt-5">
                <strong>Algumas das grandes plataformas vendem mais ou menos assim:</strong>
                <br className="d-none d-lg-block" />
                Você instala as API’s se tiver dúvida solicita suporte via e-mail ou telefone.
                <br />
                <br />
                <span className="color-blue">O que pensamos sobre?</span>
                {' '}
                Nada de errado, é a forma que eles podem atender, uma vez que a maioria delas estão no exterior, mas funciona e compra quem quer.
              </p>
            </div>

            <div className="box-2 text-center">
              <img src={Zoom} alt="Zoom" className="mb-6" />
              <p className="color-white-light">
                Na Automatize nós oferecemos acesso, 2 vezes por mês, via zoom, 100% de graça por um ano. O suporte do
                <br className="d-none d-lg-block" />
                {' '}
                curso é feito ao vivo, no zoom. Você entra lá e pode tirar suas dúvidas.
              </p>
              <hr />
              <p className="f-8 font-mobal color-green2 mb-2">Super desconto no set up.</p>
              <p className="value mb-n1">
                <span>R$</span>
                {' '}
                3.900,00
              </p>
              <p className="color-white-light mb-6">
                custo de Instalação e implementação fica de forma ”GRATUITA” para
                <br className="d-none d-lg-block" />
                {' '}
                compras diretas da página de vendas no plano anual.
              </p>
              <div className="mt-2 mt-lg-4 d-none d-lg-block ">
                <Button placeholder="FAZER INSCRIÇÃO" className="" href="/#Planos" />
              </div>
              <div className="mt-2 mt-lg-4 pb-4 d-block d-lg-none ">
                <Button placeholder="FAZER INSCRIÇÃO" className="d-block mb-4" href="/#Planos" />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </S.Section16>
  );
}
