/* eslint-disable @typescript-eslint/ban-ts-comment */
import Button from 'components/common/Button';
import {
  Col, Container, Row,
} from 'react-bootstrap';
import LazyLoad from 'react-lazyload';
import Grafico1 from '../../../assets/images/8-porque-azutomatize/graficos-1.svg';
import Grafico2 from '../../../assets/images/8-porque-azutomatize/graficos-2.svg';
import Grafico3 from '../../../assets/images/8-porque-azutomatize/graficos-3.svg';
// @ts-ignore
import Notebook from '../../../assets/images/8-porque-azutomatize/notebook.gif';
import BannerImage from '../../../assets/images/8-porque-azutomatize/banner-image.png';
import * as S from './styles';

export default function QuemSeDestina() {
  return (
    <S.Section8>
      <Container>
        <Row>
          <Col className="content">
            <span className="color-blue mb-2 d-block subtitle">CARRINHOS DE COMPRA ABANDONADOS!</span>
            <h3 className="f-8">
              Porque o Azutomatize é imprescindível
              {' '}
              <br className="d-none d-lg-block" />
              para vender mais, com alto lucro e deixar
              {' '}
              <br className="d-none d-lg-block" />
              seus clientes fãs de sua marca?
            </h3>
            <p>
              Os anúncios do google e do facebook vendem ou geram leads, no entanto uma comparação e prova
              <br className="d-none d-lg-block" />

              concreta, nos mostra que é necessário dar sequência no acompanhamento e atendimento aos clientes
              <br className="d-none d-lg-block" />

              da base e para leads frios e quentes.
              <br className="d-none d-lg-block" />

              Ainda que o facebook e o google ajude você a vender seus produtos e serviços, existe uma parcela
              <br className="d-none d-lg-block" />

              considerável de 70% de pessoas que abandonam seus carrinhos de compra.
              {' '}
              <br className="d-none d-lg-block" />

              <strong className="color-orange">
                O método Low Cac – Azutomatize, além de ajudar a vender muito mais, também colabora na
                <br className="d-none d-lg-block" />

                recuperação das compras abandonadas.
              </strong>
              <br />
              <br />
              É provado que a comunicação automatizada, humanizada e infinita, deixará seus clientes muito mais amparados.
              <br />
              Abaixo, seguem os estudos com gráficos, provando o problema mundial!
              <br />
              <br />
              <strong>Com base em médias de 500 marcas globais de destaque, aqui estão as estatísticas de abandono de carrinho e remarketing.</strong>
            </p>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col lg={4}><img src={Grafico1} alt="Gráfico 1" className="image-graf" /></Col>
          <Col lg={4}><img src={Grafico2} alt="Gráfico 2" className="image-graf mt-6 mt-lg-0" /></Col>
          <Col lg={4}><img src={Grafico3} alt="Gráfico 3" className="image-graf mt-6 mt-lg-0" /></Col>
        </Row>
        <Row>
          <Col>
            <S.Banner>
              <span className="color-white f-7">Quanto dinheiro você está deixando sobre a mesa?</span>
              <p className="color-white-light font-font-weight-light">
                Afinal o que importa é o resultado! Como aplicar um método em escala sem papo furado,
                <br />
                com tática e estratégia?
                {' '}
                <strong className="color-green">
                  O método Low Cac é 50% do caminho andado, em uma única
                  <br />
                  ferramenta! Tenha acesso hoje mesmo e ganhe tempo.
                </strong>
              </p>
              <Button placeholder="FAZER INSCRIÇÃO" href="/#Planos" />
              <LazyLoad offset={500} once>
                <img src={BannerImage} alt="Gráfico 3" className="image-banner-mobal d-block d-lg-none" />
              </LazyLoad>
            </S.Banner>
          </Col>
        </Row>
        <Row>
          <Col lg={12} className="mt-6 mt-lg-0">
            <h4 className="f-8 f-lg-9 mt-8 mt-lg-7">
              Com
              {' '}
              <strong className="f-8 f-lg-9">Azutomatize</strong>
              {' '}
              você
              {' '}
              <br className="d-none d-lg-block" />
              está em boas mãos.
            </h4>
          </Col>
          <Col lg={12} className="text-center">
            <img src={Notebook} alt="Notebook" className="mb-7 note-mobile" />
          </Col>
          <Col lg={12}>
            <p>
              Estamos entre os maiores em conectividade e engajamento de clientes. A ferramenta proporciona estratégias diferentes de tudo o que você já viu, com comunicações personalizadas e alcance global seguro, que conecta você aos seus clientes.
            </p>
            <p>
              <strong className="color-blue">•</strong>
              {' '}
              Plataforma única com APIs flexíveis para qualquer canal, inteligência integrada e infraestrutura global para oferecer suporte em escala, segurança e robustez, alto desempenho e alta disponibilidade.
              {' '}
              <strong>Com uma das melhores e maiores infraestruturas do Brasil e do mundo, garantimos a maior disponibilidade para toda sua aplicação.</strong>

            </p>
            <p>
              <strong className="color-blue">•</strong>
              {' '}
              <strong>Conectividade Direta Entre 29 PAÍSES e mais de 350 POPS ao redor do mundo.</strong>
              {' '}
              A rede Azutomatize também está conectada com data centers de classe mundial e CDN impecável, entregando qualidade direta para operações fixas e móveis.
            </p>
            <p>
              <strong className="color-blue">•</strong>
              {' '}
              <strong>INTERCONECTADO COM MAIS DE 250 OPERADORAS</strong>
              {' '}
              e com uma ampla gama de operadoras que aumenta o escopo de nossos serviços para o nível NGN real.
            </p>

          </Col>
        </Row>
      </Container>
    </S.Section8>
  );
}
