import {
  Col, Container, Row,
  Modal,
} from 'react-bootstrap';
import Button from 'components/common/Button';
import { useState } from 'react';
import LogoMini from '../../../assets/images/icons/logo-mini.svg';
import WhatsappMini from '../../../assets/images/icons/whatsapp-mini.svg';
import Torpedos from '../../../assets/images/icons/torpedo.svg';
import Mensagem from '../../../assets/images/icons/message.svg';
import Graficos from '../../../assets/images/3-planos/graficos.svg';
import Cards from '../../../assets/images/3-planos/cards.png';
import MailMarketing from '../../../assets/images/icons/mailmarketing.svg';
import CallCenter from '../../../assets/images/icons/callcenter.svg';

import * as S from './styles';

export default function Planos() {
  const [showModal, setShowModal] = useState(false);

  const [ModalValues, setModalValues] = useState(
    {
      valor1: '',
      button1Url: '',
      text1: '',
      valor2: '',
      button2Url: '',
      text2: '',
    },
  );

  function handleClick({
    valor1, button1Url, text1, valor2, button2Url, text2,
  }) {
    setModalValues({
      valor1: '',
      button1Url: '',
      text1: '',
      valor2: '',
      button2Url: '',
      text2: '',
    });
    setShowModal(true);
    setModalValues({
      valor1,
      button1Url,
      text1,
      valor2,
      button2Url,
      text2,
    });
  }

  return (
    <S.Section3>
      <Container>
        <Row>
          <Col>
            <h3 className="f-8">Planos e formas de pagamento</h3>
          </Col>
        </Row>
        <Row>
          <Col lg={8}>
            <Row>
              <Col>
                <S.Plans>
                  <div className="first-section">
                    <h5 className="color-green f-7">COLLABORATION</h5>
                    <span>
                      Webphone - Voz
                    </span>
                    <hr />
                    <span>
                      WhatsApp oficial
                    </span>
                    <hr />
                    <span>
                      <strong>ATÉ 8 USUÁRIOS</strong>
                    </span>
                    <hr />
                    <span>
                      <b className="color-blue">• </b>
                      Grupos Ilimitados
                      <br />
                      <b className="color-blue">• </b>
                      Listas ilimitadas
                    </span>
                    <hr />
                    <span>
                      Suporte (E-mail) | SLA 8 horas
                    </span>
                  </div>
                  <div className="second-section">
                    <h5 className="color-blue f-7">Entregáveis</h5>
                    <span className="d-flex align-items-start mb-2">
                      <img src={LogoMini} alt="logo" className="mr-2" />
                      <strong>Azuphone</strong>
                    </span>
                    <span>
                      <strong>2.000 min.</strong>
                      fixo/móvel
                      <br />
                      <span className="f-3">Excedentes R$ 0,12 por minuto</span>
                    </span>
                    <div className="py-1"><hr className="my-2" /></div>
                    <span className="d-flex align-items-start mb-2">
                      <img src={WhatsappMini} alt="logo" className="mr-2 mt-n1" />
                      <strong>WhatsApp</strong>
                    </span>
                    <span className="mb-4">
                      <strong>1.000 sessões</strong>
                      <br />
                      <span className="f-3">Excedentes R$ 1,20</span>
                    </span>
                    <S.Button
                      placeholder=""
                      onClick={() => handleClick({
                        valor1: '2.078,00',
                        button1Url: 'https://www.asaas.com/c/569867663700',
                        text1: 'selecione 1',
                        valor2: '1870,20',
                        button2Url: 'https://www.asaas.com/c/853227450119',
                        text2: '<span class="color-green">•</span> CONTRATO DE 12 MESES<br /> <span class="color-green">•</span> BÔNUS DE <strong>R$ 3900,00</strong> <br /> (custo de instalação e setup)',
                      })}
                      className="w-100 px-0 d-block text-center"
                    >
                      COMEÇAR AGORA
                    </S.Button>
                  </div>
                </S.Plans>
                <S.Values>
                  <p className="color-blue f-7 font-weight-light mb-2 mt-2">
                    <strong className="color-blue f-7">R$ </strong>
                    <span className="f-8"><strong className="color-blue f-8">2.078,00</strong></span>
                    /mês
                  </p>
                  <p>
                    <strong className="f-5">Plano anual: R$ 1870,20/mês</strong>
                    <br />
                    <b className="color-green">• </b>
                    CONTRATO DE 12 MESES
                    <br />
                    <b className="color-green">• </b>
                    BÔNUS DE
                    <strong> R$ 3900,00</strong>
                    <br />
                    (custo de instalação e setup)
                  </p>
                </S.Values>
              </Col>
              <Col>
                <S.Plans className="mt-6 mt-lg-0">
                  <div className="first-section">
                    <h5 className="color-green f-7">POWERFULL</h5>
                    <span>
                      Webphone - Voz
                    </span>
                    <hr />
                    <span>
                      WhatsApp oficial
                    </span>
                    <hr />
                    <span>
                      Torpedo Mkt - Voz
                    </span>
                    <hr />
                    <span>
                      <strong>ATÉ 10 USUÁRIOS</strong>
                    </span>
                    <hr />
                    <span>
                      <b className="color-blue">• </b>
                      Grupos Ilimitados
                      <br />
                      <b className="color-blue">• </b>
                      Listas ilimitadas
                    </span>
                    <hr />
                    <span>
                      Suporte (E-mail) | SLA 6 horas
                    </span>
                  </div>
                  <div className="second-section">
                    <h5 className="color-blue f-7">Entregáveis</h5>
                    <span className="d-flex align-items-start mb-2">
                      <img src={Torpedos} alt="logo" className="mr-2 mt-n1" />
                      <strong>Torpedo de voz</strong>
                    </span>
                    <span>
                      <strong>1.000 disparos </strong>
                      de 30 seg.
                      <br />
                      de duração/mês
                      <span className="f-3">Excedentes R$ 0,12 por disparo</span>
                    </span>
                    <div className="py-1"><hr className="my-2" /></div>
                    <span className="d-flex align-items-start mb-2">
                      <img src={LogoMini} alt="logo" className="mr-2" />
                      <strong>Azuphone</strong>
                    </span>
                    <span>
                      <strong>2.000 min.</strong>
                      fixo/móvel
                      <br />
                      <span className="f-3">Excedentes R$ 0,12 por minuto</span>
                    </span>
                    <div className="py-1"><hr className="my-2" /></div>
                    <span className="d-flex align-items-start mb-2">
                      <img src={WhatsappMini} alt="logo" className="mr-2 mt-n1" />
                      <strong>WhatsApp</strong>
                    </span>
                    <span className="mb-4">
                      <strong>1.200 sessões</strong>
                      <br />
                      <span className="f-3">Excedentes R$ 1,20</span>
                    </span>
                    <S.Button
                      placeholder=""
                      onClick={() => handleClick({
                        valor1: '2.980,00',
                        button1Url: 'https://www.asaas.com/c/002258248883',
                        text1: 'selecione 3',
                        valor2: '2.628,00',
                        button2Url: 'https://www.asaas.com/c/028157493706',
                        text2: '<span class="color-green">•</span> CONTRATO DE 12 MESES<br /> <span class="color-green">•</span> BÔNUS DE <strong>R$ 3900,00</strong> <br /> (custo de instalação e setup)',
                      })}
                      className="w-100 px-0 d-block text-center"
                    >
                      COMEÇAR AGORA
                    </S.Button>
                    {' '}

                  </div>
                </S.Plans>
                <S.Values>
                  <p className="color-blue f-7 font-weight-light mb-2 mt-2">
                    <strong className="color-blue f-7">R$ </strong>
                    <span className="f-8"><strong className="color-blue f-8">2.980,00</strong></span>
                    /mês
                  </p>
                  <p>
                    <strong className="f-5">Plano anual: R$ 2.628,00/mês</strong>
                    <br />
                    <b className="color-green">• </b>
                    CONTRATO DE 12 MESES
                    <br />
                    <b className="color-green">• </b>
                    BÔNUS DE
                    <strong> R$ 3900,00</strong>
                    <br />
                    (custo de instalação e setup)
                  </p>
                </S.Values>
              </Col>
            </Row>
            <S.Payment className="d-none d-lg-flex align-items-center justify-content-end">
              <img src={Graficos} alt="pagamentos" />
              <div className="text-left">
                <p className="mb-2">
                  Após a compra, nosso
                  <strong> PERSONAL TECH</strong>
                  <br />
                  vai te acompanhar durante todo o projeto,
                  <br />
                  a partir da implantação.
                </p>
                <p className="mb-2">
                  <strong className="text-transform">
                    Garantia incondicional de 30 dias,
                    <br />
                    ou seu dinheiro, sem letras miúdas.
                  </strong>
                </p>
                <p className="mb-2"><strong className="f-3">Aceitamos todos os cartões de crédito.</strong></p>
                <img src={Cards} alt="cartões aceitos" />
              </div>
            </S.Payment>
          </Col>
          <Col lg={4}>
            <S.Plans className="mt-6 mt-lg-0">
              <div className="first-section">
                <h5 className="color-green f-7">INTERPRISE</h5>
                <span>
                  Webphone - Voz
                </span>
                <hr />
                <span>
                  WhatsApp oficial
                </span>
                <hr />
                <span>
                  Torpedo Mkt - Voz
                </span>
                <hr />
                <span>
                  SMS Marketing
                </span>
                <hr />
                <span>
                  Telegram ilimitado
                  <br />
                  <strong className="f-3">BÔNUS EXCLUSIVO GRATUITO</strong>
                </span>
                <hr />
                <span>
                  Azu e-mail
                </span>
                <hr />
                <span>
                  <strong>ATÉ 15 USUÁRIOS</strong>
                </span>
                <hr />
                <span>
                  <b className="color-blue">• </b>
                  Grupos Ilimitados
                  <br />
                  <b className="color-blue">• </b>
                  Listas ilimitadas
                </span>
                <hr />
                <span>
                  Suporte (E-mail, chat e telefone)
                  <br />
                  SLA 2 horas
                </span>
              </div>
              <div className="second-section">
                <h5 className="color-blue f-7">Entregáveis</h5>
                <span className="d-flex align-items-start mb-2">
                  <img src={Torpedos} alt="logo" className="mr-2 mt-n1" />
                  <strong>Torpedo de voz</strong>
                </span>
                <span>
                  <strong>1.000 disparos </strong>
                  de 30 seg.
                  <br />
                  de duração/mês
                  <span className="f-3">Excedentes R$ 0,12 por disparo</span>
                </span>
                <div className="py-1"><hr className="my-2" /></div>
                <span className="d-flex align-items-start mb-2">
                  <img src={LogoMini} alt="logo" className="mr-2" />
                  <strong>Azuphone</strong>
                </span>
                <span>
                  <strong>2.000 min.</strong>
                  fixo/móvel
                  <br />
                  <span className="f-3">Excedentes R$ 0,12 por minuto</span>
                </span>
                <div className="py-1"><hr className="my-2" /></div>
                <span className="d-flex align-items-start mb-2">
                  <img src={WhatsappMini} alt="logo" className="mr-2 mt-n1" />
                  <strong>WhatsApp</strong>
                </span>
                <span>
                  <strong>1.400 sessões</strong>
                  <br />
                  <span className="f-3">Excedentes R$ 1,20</span>
                </span>
                <div className="py-1"><hr className="my-2" /></div>
                <span className="d-flex align-items-start mb-2">
                  <img src={Mensagem} alt="logo" className="mr-2 mt-n1" />
                  <strong>SMS</strong>
                </span>
                <span>
                  <strong>5.000 disparos</strong>
                  <br />
                  <span className="f-3">Excedentes R$ 0,09</span>
                </span>
                <div className="py-1"><hr className="my-2" /></div>
                <span className="d-flex align-items-start mb-1">
                  <img src={MailMarketing} alt="logo" className="mr-2 mt-n1" />
                  <strong>
                    E-mail Marketing
                  </strong>
                </span>
                <strong>
                  30.000 envios inclusos
                </strong>
                <span>
                  <span className="f-3">
                    Excedentes
                    {' '}
                    <a href="https://www.azutomatize.com/arquivos/plano.pdf" target="_blank" className="color-blue linkPDF" rel="noreferrer">saiba mais</a>
                  </span>
                </span>
                <div className="py-1"><hr className="my-2" /></div>
                <span className="d-flex align-items-start mb-4">
                  <img src={CallCenter} alt="logo" className="mr-2 mt-n1" />
                  <strong>Módulo Call Center</strong>
                </span>
                <S.Button
                  placeholder=""
                  onClick={() => handleClick({
                    valor1: '3.697,00',
                    button1Url: 'https://www.asaas.com/c/893013768025',
                    text1: 'selecione 5',
                    valor2: '3.412,15',
                    button2Url: 'https://www.asaas.com/c/655885381628',
                    text2: '<span class="color-green">•</span> CONTRATO DE 12 MESES<br /> <span class="color-green">•</span> BÔNUS DE <strong>R$ 3900,00</strong> <br /> (custo de instalação e setup)',
                  })}
                  className="w-100 px-0 d-block text-center"
                >
                  COMEÇAR AGORA
                </S.Button>
                {' '}

              </div>
            </S.Plans>
            <S.Values>
              <p className="color-blue f-7 font-weight-light mb-2 mt-2">
                <strong className="color-blue f-7">R$ </strong>
                <span className="f-8"><strong className="color-blue f-8">3.697,00</strong></span>
                /mês
              </p>
              <p>
                <strong className="f-5">Plano anual: R$ 3.412,15/mês</strong>
                <br />
                <b className="color-green">• </b>
                CONTRATO DE 12 MESES
                <br />
                <b className="color-green">• </b>
                BÔNUS DE
                <strong> R$ 3900,00</strong>
                <br />
                (custo de instalação e setup)
              </p>
            </S.Values>
          </Col>
        </Row>
      </Container>

      <Modal show={showModal} onHide={() => setShowModal(false)} className="d-flex align-items-center justify-content-center">
        <Modal.Header closeButton>
          <Modal.Title><span className="f-5 font-weight-bold color-black">Selecione o tipo de asssinatura</span></Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <p className="color-blue f-7 font-weight-light mb-3 mt-2 text-center">
            <strong className="color-blue f-7">R$ </strong>
            <span className="f-8"><strong className="color-blue f-8">{ModalValues.valor1}</strong></span>
            /mês
          </p>
          {' '}
          <Button placeholder=" PLANO MENSAL" href={ModalValues.button1Url} className="w-100 px-0 d-block text-center mt-2" />
          <p className="color-blue f-7 font-weight-light mt-5 mb-n1 d-block text-center">
            <strong className="color-blue f-7">R$ </strong>
            <span className="f-8"><strong className="color-blue f-8">{ModalValues.valor2}</strong></span>
            /mês
          </p>
          <hr />
          <p className="text-center mb-3" dangerouslySetInnerHTML={{ __html: ModalValues.text2 }} />
          {' '}
          <Button placeholder="PLANO ANUAL" href={ModalValues.button2Url} className="w-100 px-0 d-block text-center mt-2" />
        </Modal.Body>
        {/* <Modal.Footer>
          A
        </Modal.Footer> */}
      </Modal>
    </S.Section3>
  );
}
