/* eslint-disable react/jsx-props-no-spreading */
import {
  Col, Container, Row,
} from 'react-bootstrap';
import Slider from 'react-slick';
import * as S from './styles';
import Logo1 from '../../../assets/images/5-solucoes/logo-1.png';
import Logo2 from '../../../assets/images/5-solucoes/logo-2.png';
import Logo3 from '../../../assets/images/5-solucoes/logo-3.png';
import Logo4 from '../../../assets/images/5-solucoes/logo-4.png';
import Logo5 from '../../../assets/images/5-solucoes/logo-5.png';

export default function Solucoes() {
  const settings = {
    infinite: true,
    slidesToScroll: 1,
    cssEase: 'linear',
    pauseOnHover: false,
    focusOnSelect: false,
    swipeToSlide: true,
    dots: false,
    className: 'slider variable-width',
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1650,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        center: true,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          speed: 20000,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const videos = [
    {
      url: 'https://www.youtube.com/embed/Ix0McvukFlI',
    },
    {
      url: 'https://www.youtube.com/embed/ZIytGkNLPkU',
    },
    {
      url: 'https://www.youtube.com/embed/QJRYRYCkZQM',
    },
    {
      url: 'https://www.youtube.com/embed/ko6kfaJqG9w',
    },
  ];

  return (
    <S.Section5>
      <Container>
        <Row>
          <Col lg={12} className="d-flex justify-content-between align-items-center mb-6">
            <h3 className="f-8 color-white mb-0 d-flex">
              Soluções Azuton
              {' '}
              <span className="color-orange bar d-none d-lg-flex mx-2">|</span>
              {' '}
              Testemunhos
            </h3>
          </Col>
        </Row>
      </Container>
      <S.LineVideos className="d-none d-lg-flex">
        <S.BoxVideo className="mr-0">
          <iframe width="100%" height="257px" src="https://www.youtube.com/embed/Ix0McvukFlI" title="Depoimento Matronfer 02" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
        </S.BoxVideo>
        <S.BoxVideo className="mr-0">
          <iframe width="100%" height="257px" src="https://www.youtube.com/embed/ZIytGkNLPkU" title="Vídeo 01 Depoimento Azutomatize" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
        </S.BoxVideo>
        <S.BoxVideo className="mr-0">
          <iframe width="100%" height="257px" src="https://www.youtube.com/embed/QJRYRYCkZQM" title="AztmzVídeo 03 Funcionalidades da Ferramenta Azutomatize Ominichannel" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
        </S.BoxVideo>
        <S.BoxVideo className="mr-0">
          <iframe width="100%" height="257px" src="https://www.youtube.com/embed/ko6kfaJqG9w" title="Vídeo [12aztmz] Jornada de consciência do cliente ferramenta de marketing para gerar mais leads." frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
        </S.BoxVideo>
      </S.LineVideos>
      {/* <div className="d-none d-lg-block">
        <Slider {...settings}>
          {videos.map((data) => (
            <div style={{ width: 480 }}>
              <iframe width="458px" height="257px" src={data.url} title="UM ASSADO PARA... VINA  | #120" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
            </div>
          ))}
        </Slider>
      </div> */}
      <div className="d-block d-lg-none">
        <Slider {...settings}>
          {videos.map((data) => (
            <div style={{ width: 400 }}>
              <iframe width="380px" height="257px" src={data.url} title="UM ASSADO PARA... VINA  | #120" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
            </div>
          ))}
        </Slider>
      </div>
      <div className="logos">
        <div className="w-100 justify-content-center align-items-center flex-row d-flex mt-6">
          <img src={Logo1} alt="logo-1" className="mr-3" />
          <img src={Logo2} alt="logo-2" className="mr-3" />
          <img src={Logo3} alt="logo-3" className="mr-3" />
          <img src={Logo4} alt="logo-4" className="mr-3" />
          <img src={Logo5} alt="logo-5" className="mr-3" />
        </div>
      </div>
    </S.Section5>
  );
}
